import React, { useEffect, useState } from 'react';
import { dateHelper } from '../utils.js/utils';
import SSNDisplay from './SSNDisplay';
import axios from 'axios';
import PhoneNumberDisplay from './PhoneNumberDisplay';

const ProfileContentClient = ({
    id,
    documents,
    role,
    clientInterviewDate, 
    clientInterviewer, 
    clientDOB,
    connectedCaregiver,
    connectedCaregiverRelationship,
    connectedCaregiverId,
    clientCaregiverContact, 
    clientConservatorship,
    clientConservatorName,
    clientConservatorPhone,
    clientConservatorEmail,
    clientAddressCdlId,
    clientSSN,
    clientAddressUci,
    clientLivingSituation,
    clientAddressStreet,
    clientAddressCity,
    clientAddressState,
    clientAddressZip,
    clientTransportation,
    clientIncome,
    clientPayee,
    referralDetails,
    caseManagerDetails,
    createdOn,
    archiveDoc
}) => {
    
    const [userloggedin, setUserLoggedIn] = useState({});

    useEffect(() => {
        axios.get('/api/user/myaccount')
        .then(res => {
            if (res?.data.data[0]) {
                setUserLoggedIn(res.data.data[0]);
            }
        })
        .catch(err => {
            console.log('err dash my account', err);
        });

    }, []);


    const handleFileDownload = (docId) => {
        axios.get(`/api/user/document/${docId}`)
        .then(res => {
            let target = res.data.url;
            window.location.href = target;
        })
        .catch(err => {
            console.log(err);
        });
    }

    const handleFileArchive = (docId) => {
        archiveDoc(docId)
    }
    
    return (
        <>
        {userloggedin.role !== 'employer' ? (
        <>
        <div className='pt-5 m-0 mt-2'>
            <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Referral 
                 <span className='is-size-6 has-text-weight-normal'> ({dateHelper(referralDetails?.createdOn)})</span>
            </h2>
        </div>
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Source</p></div>
                <div className=''>
                    <p className='is-size-6 has-text-weight-normal colorDarkGrey'>{referralDetails.nameReferralLong}</p>
                </div>
            </div>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Case manager</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{caseManagerDetails?.firstName} {caseManagerDetails?.lastName}</p></div>
            </div>
        </div>
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Intake date</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{dateHelper(clientInterviewDate)}</p></div>
            </div>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Interviewer</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientInterviewer}</p></div>
            </div>
        </div>
        </>
        ) : null }
        
        {connectedCaregiver ? (
        <>
            <div className='pt-5'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Caregiver</h2>
            </div>
            {/* Row 3 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Name</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{connectedCaregiver}</p></div>
                </div>
            </div>
            {/* Row 4 - Two */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Emergency phone</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'><PhoneNumberDisplay number={clientCaregiverContact} /></p></div>
                </div>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Relationship</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{connectedCaregiverRelationship}</p></div>
                </div>
            </div> 
        </>
        ) : null}

        {clientConservatorship === 'yes' ? (
        <>
            <div className='pt-5 m-0'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Conservatorship</h2>
            </div>
            {/* Row New - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Conservatorship</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>Yes</p></div>
                </div>
            </div>
            {/* Row 3 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Conservator</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientConservatorName}</p></div>
                </div>
            </div>
            {/* Row 4 - Two */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Phone</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'><PhoneNumberDisplay number={clientConservatorPhone} /></p></div>
                </div>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Email</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientConservatorEmail}</p></div>
                </div>
            </div> 
        </>
        ) : null}

        {clientConservatorship === 'no' ? (
        <>
        {/* Row New - One */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Conservatorship</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>No</p></div>
            </div>
        </div>
        </>
        ) : null}

        {userloggedin.role === 'admin' ||  userloggedin.role === 'coach' ? (
            <>
            <div className='pt-5 m-0'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Additional</h2>
            </div>
            {/* Row 5 - Two */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Date of birth</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{dateHelper(clientDOB)}</p></div>
                </div>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>CDL/ID</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientAddressCdlId}</p></div>
                </div>
            </div>
            {/* Row 6 - Two */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>SSN</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'><SSNDisplay clientSSN={clientSSN} /></p></div>
                </div>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>UCI</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientAddressUci}</p></div>
                </div>
            </div>
            {/* Row 7 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Current living situation</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientLivingSituation} <span className='ml-4'>{clientAddressStreet} {clientAddressCity} {clientAddressState} {clientAddressZip}</span></p></div>
                </div>
            </div>
            {/* Row 8 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Mode of transportation</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientTransportation}</p></div>
                </div>
            </div>
            {/* Row 9 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Source of income</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientIncome}</p></div>
                </div>
            </div>
            {/* Row 10 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Payee</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientPayee}</p></div>
                </div>
            </div>
            </>
        ) : null}

        {userloggedin.role === 'admin' ||  userloggedin.role === 'coach' || userloggedin.role === 'provider' || userloggedin.id === id ? (
           <>
            <div className='pt-5 m-0 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Documents</h2>
                {userloggedin.role === 'admin' ||  userloggedin.role === 'coach' ? (
                <>
                <div className='pr-4 is-size-6'><a href={`/#/add-document?id=${id}`}>Upload document</a></div>
                </>
                ) : null}                
            </div>
            <div className='is-flex is-flex-direction-column is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                {documents.map((d, i) => {
                    return (
                        <div key={i} className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                            <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>{d.fileName}  <span className='has-text-weight-normal colorBlack is-size-6 ml-3'>({dateHelper(d.createdOn)})</span></p></div>
                            <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'><a onClick={() => handleFileDownload(d.id)}>Download</a> <a className="pl-2" onClick={() => handleFileArchive(d.id)}>Archive</a></p></div>
                        </div>
                    )
                })}
            </div>
            </>
        ) : null}
        </>
    );
};

export default ProfileContentClient;