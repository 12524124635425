import React, { useState, useEffect } from 'react';
import Auth from '../utils.js/Auth';
import { signin } from './Auth-Api';
import Footer from './Footer';
import NavPublic from './NavPublic';

import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'axios';

const CreateLoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordverify, setPasswordverify] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [tokenUser, setTokenUser] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

    // handle expired token
    useEffect(() => {
        axios.get(`/api/login/validToken?token=${searchParams.get('token')}`)
        .then((res) => {
            console.log(res)
            console.log('valid token')
            if (res?.data?.username) {
                setTokenUser(res.data.username);
            }
        })
        .catch((err) => {
            // invalid token
            // dont allow create password
            if (err?.response?.data?.error) {
                setMessage(err.response.data.error);
            } else {
                setMessage('Error');
            }
        });
        }, []);

//   const auth = useContext(Auth);
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    if (!username) {
        setMessage('Username required');
        setIsLoading(false);
        return;
    }
    
    if (username !== tokenUser) {
        setMessage('Invalid email');
        setIsLoading(false);
        return;
    }

    if (!password || password !== passwordverify) {
        setMessage('Passwords must match');
        setIsLoading(false);
        return;
    }

    const payload = {
        username: username,
        password: password,
        token: searchParams.get('token'),
        isCreate: true,
    }

    axios.post('/api/user/resetPassword', payload)
    .then((res) => {
        // show password created screen?
        navigate('/login?showToast=Password created. Please log in.`');
    })
    .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.error) {
            setMessage(err.response.data.error);
        } else {
            setMessage('Error');
        }
    });
  }
  return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container fullHeight fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
            <NavPublic />
            <div className="theme-dark is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 p-0">
                <div className="column is-6 m-0 p-0">
                    <div className="box ADA-dark">
                        <div className="m-4 p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="is-size-4 my-4 has-text-white">
                                    <h1 className="has-text-weight-bold">Create login</h1>
                                    <p className='is-size-6 my-3 pb-2'>To log in, first create a new password. Your password should be between 12 and 80 characters and contain at least one number and one symbol.</p>
                                </div>
                                <div className="field">
                                    <label htmlFor="name" className="label m-1 has-text-white">Verify email address</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="name" 
                                            className="input inputDark" 
                                            autoComplete="email" 
                                            type="email" 
                                            placeholder="Enter email" 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)}
                            
                                        />
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="field pt-1">
                                    <label htmlFor="pass" className="label m-1 has-text-white">Create new password</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="pass" 
                                            className="input inputDark" 
                                            autoComplete="current-password" 
                                            type="password" 
                                            placeholder="Enter new password"
                                            value={password} 
                                            // onChange={handlePasswordChange}
                                            onChange={(e) => setPassword(e.target.value)} 
                                        />
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="field pt-1">
                                    <label htmlFor="passVerify" className="label m-1 has-text-white">Retype new password</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="passVerify" 
                                            className="input inputDark" 
                                            autoComplete="current-password" 
                                            type="password" 
                                            placeholder="Confirm new password"
                                            value={passwordverify} 
                                            // onChange={handlePasswordChange}
                                            onChange={(e) => setPasswordverify(e.target.value)} 
                                        />
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p className="help file-name colorDarkRed has-text-weight-bold">All fields are required.</p>
                                    <p>{message}</p>
                                </div>
                                <div className="field is-grouped is-flex is-justify-content-flex-end is-full">
                                    <div>
                                        {/* <input  className="button button-dark" type="submit" value="Log in" /> */}
                                        <button 
                                            id="submit" 
                                            className={`button is-size-6 buttonDark ${isLoading ? 'is-loading' : ''}`}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Loading...' : 'Log in'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p className="is-size-6 is-size-7-tablet is-size-7-mobile has-text-black has-text-centered mb-6 p-0">Need help or have a question? <a href="/#/message">Contact us</a></p>
                </div>    
            </div>      
            <Footer /> 
        </div>
        
    </section>
  );    
}

export default CreateLoginForm;
