import React from 'react';

const WordDisplay = ({ words }) => {
  return (
    <>
      {words.map((word, index) => (
        <p key={index} className="cardDefaultHalf mb-4 has-text-centered">
          <a href={word.url} className='is-size-2 is-size-3-mobile has-text-white'>
            {word.page}
          </a>
        </p>
      ))}
      
    </>
  );
};

export default WordDisplay;