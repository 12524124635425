import React from 'react';
import Avatar from './Avatar';
import ContactActionsMenu from './ContactActionsMenu';
import TagRole from './TagRole';
import PhoneNumberConverter from './PhoneNumberConverter';

const ContactRowInfo = ({ 
    contactContent, 
    archive
}) => {

    const handleClick = (contactObject) => {
        const contactUrl = contactObject;

        // Check if contactUrl exists and is not empty
        if (contactUrl && typeof contactUrl === 'string' && contactUrl.trim() !== '') {
            // Navigate to the URL
            window.location.href = contactUrl;
        } else {
            console.error('Invalid or missing contactUrl');
        }
    };

    const handleEdit = () => {
        // console.log('Edit contact');
        // Implement your edit logic here
    };
    
    const handleArchive = () => {
        archive(contactContent.id, false)
    };

    const handleUnarchive = () => {
        archive(contactContent.id, true)
    };

    const handleOnClickPrevent = (e) => {
        e.stopPropagation();
    };

    const contactDomain = "/#/profile?id=";
    const contactNumber = `${contactContent.id}`;
    const contactUrl = `${contactDomain}${contactNumber}`;

    return (
        <>
        <tr onClick={() => handleClick(contactUrl)} className='rowHeight is-clickable' style={{ cursor: 'pointer' }}>
            <td className='rowHeight m-0 p-0'>
                <div className='centerStuff centerHorizontal flex-grow-0 m-0 p-0'>
                    <Avatar initials={contactContent.initials || `${contactContent.firstName[0]}${contactContent.lastName[0]}`} size={contactContent.size || 44} />
                </div>
            </td>
            <td className='rowHeight'><p className='is-size-6 centerStuff'>{contactContent.firstName} {contactContent.lastName} </p></td>
            <td className='rowHeight is-hidden-mobile'><p className='is-size-6 centerStuff'><a href={`mailto:{contactContent.email}`}>{contactContent.email}</a></p></td>
            <td className='rowHeight is-hidden-mobile'><p className='is-size-6 centerStuff'><PhoneNumberConverter number={contactContent.phone} /></p></td>
            <td className='rowHeight'><p className='is-size-6 centerStuff'><TagRole contactRole={contactContent.role} /></p></td>
            <td className='rowHeight m-0 p-0' onClick={handleOnClickPrevent}>
                <div className='centerStuff centerHorizontal'>
                    <ContactActionsMenu onEdit={handleEdit} onArchive={handleArchive} onUnarchive={handleUnarchive} id={contactContent.id} isActive={contactContent.active}/>
                </div>
            </td>
        </tr>
        </>
    );
};

export default ContactRowInfo;