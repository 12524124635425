import React from 'react';

const PhoneNumberDisplay = ({ number }) => {
  const formatPhoneNumber = (phoneNumber) => {
    // Check if phoneNumber is undefined or null
    if (phoneNumber == null) {
      return '';
    }

    // Convert to string if it's a number
    const phoneString = typeof phoneNumber === 'number' ? phoneNumber.toString() : phoneNumber;
    
    // Remove any non-digit characters
    const cleaned = phoneString.replace(/\D/g, '');
    
    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    
    // Return the original input if it doesn't match the expected format
    return phoneString;
  };

  const formattedNumber = formatPhoneNumber(number);

  return <span>{formattedNumber}</span>;
};

export default PhoneNumberDisplay;