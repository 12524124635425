import React, { useEffect, useState } from 'react';
import NavPrivate from './NavPrivate';
import Footer  from './Footer';
import ContactHelpLink from './ContactHelpLink';
import SettingsRow from './SettingsRow';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import { CheckSession } from './Auth-Api';


const Settings = ({
    id,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [activeTab, setActiveTab] = useState('employers');

    const [archive, setArchive] = useState('');

    const [employerLocations, setEmployerLocations] = useState([]);
    const [providerLocations, setProviderLocations] = useState([]);
    const [referralLocations, setReferralLocations] = useState([]);

    CheckSession();

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }
    }, []);

    useEffect(() => {
        axios.get('/api/employers/locations?page=1&limit=1000&active=true')
        .then(res => {
            // console.log('emps', res.data.data);
            setEmployerLocations(res.data.data);
        })
        .catch(err => {
            console.log(err);
            alert(`Error: ${err.response.data.error}`)
        });
        axios.get('/api/providers/locations?page=1&limit=1000&active=true')
        .then(res => {
            console.log('providers', res.data.data);
            setProviderLocations(res.data.data);
        })
        .catch(err => {
            console.log(err);
            alert(`Error: ${err.response.data.error}`)
        });
        axios.get('/api/referrals/locations?page=1&limit=1000&active=true')
        .then(res => {
            // console.log('refs', res.data.data);
            setReferralLocations(res.data.data);
        })
        .catch(err => {
            console.log(err);
            alert(`Error: ${err.response.data.error}`)
        });
    }, [archive]);

    const handleArchiveMain = (type, id) => {
        if (type === 'employers' || type === 'providers' || type === 'referrals') {
            const payload = {
                id: id,
                active: false
            }
            axios.put(`/api/${type}/locations`, payload)
            .then(res => {
                setArchive(id);
                setToastMessage('Archived');
            })
            .catch(err => {
                console.log('archive', err)
            });
        }
    }

    const tabs = [
        { id: 'employers', label: 'Employers' },
        { id: 'providers', label: 'Providers' },
        { id: 'referrers', label: 'Referrers' },
    ];

    const renderEmployersInfo = () => (
        <>
        
        <div className='contentBox mb-6'>
            <div className='is-flex is-flex-direction-rows is-justify-content-end pb-2'>
                <p className='is-hidden-mobile m-0 py-0 pr-4 pl-0 is-size-6'><a href="#/create-employer">Add employer</a> <a className="pl-2" href="#/create-location">Add location</a></p>
            </div>
            <table className='table is-hoverable mb-4 pb-4 oneHundredPercentWidth'>
                <thead>
                    <tr className='no-hover'>
                        <th><p className='is-size-7'><abbr title="Name">Employer</abbr></p></th>
                        <th><p className='is-size-7'><abbr title="Location">Location</abbr></p></th>
                        <th></th>
                    </tr>

                    <SettingsRow employerLocations={employerLocations} handleArchiveMain={handleArchiveMain}/>
                </thead>
            </table>
        </div>
        </>
    );

    const renderProvidersInfo = () => (
        <div className='contentBox mb-6'>
            <div className='is-flex is-flex-direction-rows is-justify-content-end pb-2'>
                <p className='is-hidden-mobile m-0 py-0 pr-4 pl-0 is-size-6'><a href="#/create-provider">Add provider</a> <a className="pl-2" href="#/create-location-provider">Add location</a></p>
            </div>
            <table className='table is-hoverable mb-4 pb-4 oneHundredPercentWidth'>
                <thead>
                <tr className='no-hover'>
                        <th><p className='is-size-7'><abbr title="Name">Provider</abbr></p></th>
                        <th><p className='is-size-7 is-hidden-mobile'><abbr title="Role">Location</abbr></p></th>
                        <th></th>
                    </tr>
                    <SettingsRow providerDetail={providerLocations} handleArchiveMain={handleArchiveMain}/>
                </thead>
            </table>
        </div>
    );

    const renderReferrersInfo = () => (
        <div className='contentBox mb-6'>
            <div className='is-flex is-flex-direction-rows is-justify-content-end pb-2'>
                <p className='is-hidden-mobile m-0 py-0 pr-4 pl-0 is-size-6' style={{ cursor: 'pointer' }}><a href="#/create-referral">Add referrer</a> <a className="pl-2" href="#/create-location-referral">Add location</a></p>
            </div>
            <table className='table is-hoverable mb-4 pb-4 oneHundredPercentWidth'>
                <thead>
                    <tr className='no-hover'>
                        <th><p className='is-size-7'><abbr title="Name">Referrer</abbr></p></th>
                        <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Role">Location</abbr></p></th>
                        <th></th>
                    </tr>
                    <SettingsRow referrerDetail={referralLocations} handleArchiveMain={handleArchiveMain}/>
                </thead>
            </table>
        </div>
    );

    return (
        <section className="backgroundWhiteColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate />
                        <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-two-thirds is-mobile m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-align-content-flex-start my-4">
                                            <div className='is-flex is-flex-direction-row is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="pl-4 is-size-3 has-text-weight-bold has-text-black">Settings <span className="is-size-5 has-text-weight-normal"></span></h1>
                                                <div className='m-0 p-0 is-flex is-flex-direction-row is-align-items-center'>
                                                <ContactHelpLink />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container pt-4">
                                            <div className="tabs-wrapper tabsHeight is-flex is-flex-direction-column">
                                                <div className="tabs is-size-6 is-boxed m-0 p-0">
                                                    <ul className="tabsUl">
                                                        {tabs.map((tab) => (
                                                            <li
                                                            key={tab.id}
                                                            className={activeTab === tab.id ? 'is-active' : ''}
                                                            onClick={() => setActiveTab(tab.id)}
                                                            >
                                                            <a>{tab.label}</a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="tabsContent">
                                                    {activeTab === 'employers' && renderEmployersInfo()}
                                                    {activeTab === 'providers' && renderProvidersInfo()}
                                                    {activeTab === 'referrers' && renderReferrersInfo()}
                                                </div>
                                            </div>
                                        </div> 
                                        
                                    </div>
                                </div>
                            </div>    
                        </div>   
                    </div>
                    {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default Settings;