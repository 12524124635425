import React, { useEffect, useState } from 'react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message, heading, action }) => {

// if (!isOpen) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card is-flex is-flex-direction-column is-justify-content-start">
        <header className="modal-card-head">
          <p className="modal-card-title">{heading} <span className='has-text-weight-normal'>{action}</span></p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body py-0">
          <p>{message}</p>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-size-6 adaNo buttonLight buttonHeight buttonMedium is-success" onClick={onConfirm}>Yes, Confirm.</button>
          <button className="button ml-4 is-size-6 adaNo buttonLight buttonHeight buttonMedium" onClick={onClose}>No, Cancel.</button>
        </footer>
      </div>
    </div>
  );
};

const JobPostingComponent = ({ alertMessage, alertAction, alertHeading, close, alertSubmit }) => {
  // const [isModalOpen, setIsModalOpen] = useState(true);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  const submit = () => {
    // closeModal();
    alertSubmit();
    close();
  };

  return (
    <div>
      {/* <button className="button buttonHeight buttonLight buttonMedium" onClick={openModal}>Archive Job</button> */}
      <ConfirmationModal
        // isOpen={isModalOpen}
        onClose={close}
        onConfirm={submit}
        message={alertMessage}
        heading={alertHeading}
        action={alertAction}
      />
    </div>
  );
};

export default JobPostingComponent;