import React from 'react';
import Avatar from './Avatar';
import TagRole from './TagRole';

import { dateHelper, timeHelper } from '../utils.js/utils';


const DashboardDataRecommended = ({
    DataRecommendedContent,
    candidateId
}) => {

    const handleClick = (contactObject) => {
        console.log(contactObject);
        const { contactUrl } = contactObject;

        // Check if contactUrl exists and is not empty
        if (contactUrl && typeof contactUrl === 'string' && contactUrl.trim() !== '') {
            // Navigate to the URL
            window.location.href = contactUrl;
        } else {
            console.error('Invalid or missing contactUrl');
        }
    };

    const isYes = (value) => value.toLowerCase() === 'yes';

    return (           
            <>
            <div className="is-2 my-2 oneHundredPercentWidth is-flex is-flex-direction-column is-justify-content-space-between dataBarHeight">    
                <table className='table is-hoverable'>
                    <thead>
                        <tr className='oneHundredPercentWidth no-hover'>
                            <th></th>
                            <th><p className='is-size-7'><abbr title="Name">Candidate</abbr></p></th>
                            <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Email">Position</abbr></p></th>
                            <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Phone">Added</abbr></p></th>
                        </tr>
                        <>
                        {DataRecommendedContent.map((data, index) => {
                        
                            if (candidateId === data.employerId) {

                                const contactDomain = "/#/profile?id=";
                                const contactNumber = `${data.clientId}`;
                                const contactUrl = `${contactDomain}${contactNumber}`;

                                return (
                                    <tr key={index} onClick={() => handleClick({contactUrl})} className='rowHeight is-clickable' style={{ cursor: 'pointer' }}>
                                        <td className='rowHeight m-0 p-0 '>
                                            <div className='centerStuff centerHorizontal flex-grow-0 m-0 p-0'>
                                                <Avatar initials={`${data.clientDetails.firstName[0]}${data.clientDetails.lastName[0]}`} size={44} />
                                            </div>
                                        </td>
                                        <td className='rowHeight'><p className='is-size-6 centerStuff'>{`${data.clientDetails.firstName} ${data.clientDetails.lastName}`}</p></td>
                                        <td className='rowHeight is-hidden-mobile'><p className='is-size-6 centerStuff'>Job title unavailable</p></td>
                                        <td className='rowHeight is-hidden-mobile'><p className='is-size-6 centerStuff'>{dateHelper(data.createdOn)}</p></td>
                                    </tr>
                                );
                            }
                        })}
                        </>
                    </thead>
                </table>
            </div>
            </>   
        );
  };

export default DashboardDataRecommended;

