import React from 'react';

const ContactHelpLink = () => {

return (
        <div className="breadcrumbRight" aria-label="supportlink">
            <p className='m-0 p-0 is-hidden-mobile is-size-7'>Need help? <a href="#/message" target="_blank">Contact us</a></p>
        </div>
    );
};
// m-0 py-0 pr-4 pl-0

export default ContactHelpLink;