import React from 'react';

const DashboardProviderAddress = ({
    nameEmployer,
    addressPrimaryStreet,
    addressPrimarySuite,
    addressPrimaryCity,
    addressPrimaryZip,
    googleLink,
    sectionName,
}) => {

    return (
        <div className="p-4">
            <p className='is-size-7 has-text-weight-bold colorLightGreen'>{sectionName}</p>
            <p className='is-size-4 has-text-weight-bold colorDarkGrey pt-2'>{nameEmployer}</p>
            <p className='is-size-6 colorDarkGrey py-2'><a target="_blank" href={googleLink}>{addressPrimaryStreet} {addressPrimarySuite} {addressPrimaryCity} {addressPrimaryZip}</a></p>
        </div>
    );
};

export default DashboardProviderAddress;