import React from 'react';

function NavMobile() {

    const handleClick = () => {
        window.history.back();
    };

    return (
        <>
        <nav className="is-flex is-flex-direction-row is-justify-content-space-between navbar mb-6 centerStuffJustWide theme-light" role="navigation" aria-label="main navigation">
            <h1 className='is-size-2 is-size-3-mobile cardDefaultHalf has-text-weight-bold backgroundWhiteColor'>Menu</h1>
            <a className="button is-size-6 adaNo buttonLight" onClick={handleClick}>Close</a>
        </nav>
        </>
    );
}

export default NavMobile;