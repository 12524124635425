import React, { useState } from 'react';

const SearchInput = ({ onSearch }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue) {
      onSearch(inputValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="field">
      <div className="control has-icons-left has-icons-right pr-4">
        <input
          className="input inputLight searchInput"
          type="text"
          placeholder="Search"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <span className="iconSearch icon is-small is-left" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.3588 10.4162C12.1803 9.38928 12.6715 8.08668 12.6715 6.66936C12.6715 3.35414 9.98397 0.666626 6.66875 0.666626C3.35353 0.666626 0.666016 3.35414 0.666016 6.66936C0.666016 9.98458 3.35353 12.6721 6.66875 12.6721C8.08607 12.6721 9.38867 12.1809 10.4156 11.3594L14.1941 15.1379C14.4546 15.3984 14.8769 15.3984 15.1373 15.1379C15.3978 14.8775 15.3978 14.4552 15.1373 14.1947L11.3588 10.4162ZM6.66875 11.3448C4.08655 11.3448 1.99326 9.25156 1.99326 6.66936C1.99326 4.08716 4.08655 1.99387 6.66875 1.99387C9.25095 1.99387 11.3442 4.08716 11.3442 6.66936C11.3442 9.25156 9.25095 11.3448 6.66875 11.3448Z" fill="#484848"/>
        </svg>
        </span>
        {!inputValue && (
          <span className="helperText" style={{
            position: 'absolute',
            right: '30px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#777',
            fontSize: '0.6rem'
          }}>
            Press Enter to Submit
          </span>
        )}
      </div>
    </form>
  );
};

export default SearchInput;