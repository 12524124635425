import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import ContactHelpLink from './ContactHelpLink';
import JobInfo from './JobInfo';
import JobTabs from './JobTabs';
import CopyLink from './CopyLink';
import JobMap from './JobMap';
import JobTransportation from './JobTransportation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import JobPostingToast from './ConfirmationToast';
import JobPostingModal from './ConfirmationModal';
import CopyLinkButton from './CopyLinkButton';
import JobAddress from './JobAddress';

const JobsDetail = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [candidates, setCandidates] = useState([]);
    const [job, setJob] = useState({});
    const [userloggedin, setUserLoggedIn] = useState({});

    const handleArchive = () => {
        setShowModal(true);
        setModalAction(() => archivesubmit);
        setAlertHeading('Confirm');
        setAlertAction('Archive Job');
        setAlertMessage('Are you sure you want to archive this job? This is not reversable.');
    };

    const archivesubmit = () => {
        const payload = {
            jobObj: {
                id: job.id,
                active: false,
            }
        }
        axios.put(`/api/jobs/${job.id}`, payload)
            .then(res => {
                // refresh page?
                console.log(res)
                navigate(`/jobs?showToast=Job archived`);
            })
            .catch(err => {
                console.log(err)
            });
    }

    useEffect(() => {
        const id = searchParams.get('id');

        const toast = searchParams.get('showToast');
        if (toast) {
            setToastMessage(toast);
        }

        axios.get(`/api/jobCandidates?page=1&limit=1000&sort=createdOn&active=true&jobId=${id}`)
        .then(res => {
            if (res?.data.data) {console.log(res.data.data)
                console.log("Candidates");
                console.log(res.data.data);
                setCandidates(res.data.data);
            }
        })
        .catch(err => {
            console.log('err dash candidates', err);
        });

        axios.get('/api/user/myaccount')
        .then(res => {
            if (res?.data.data[0]) {
                setUserLoggedIn(res.data.data[0]);
            }
        })
        .catch(err => {
            console.log('err dash my account', err);
        });

        axios.get(`/api/jobs/${id}?view=true`)
            .then(res => {
                console.log('job res', res.data);
                const j = res.data?.data[0];
                if (j) {
                    setJob(j);
                } else {
                    navigate('/jobs?showToast=Job not found');
                }
            })
            .catch(err => {
                console.log('err', err);
                alert(err.response.data?.error || err.response?.data);
            });
    }, []);

    return (
        <>
            <section className='theme-light fullHeight is-flex is-flex-direction-column is-justify-content-space-evenly' data-theme="light">
                <div className="backgroundLightGreyColor topShield section m-0 p-0">
                    <div className="container">
                        <div className="is-flex is-flex-direction-column">
                            <NavPrivate />
                            <div className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                                <div className="breadcrumbLeft" aria-label="breadcrumbs">
                                    <p className='m-0 p-0 is-flex is-flex-direction-row is-justify-content-start is-align-items-center'>
                                        <span className='my-0 ml-0 mr-1 p-0 is-text-weight-normal is-size-7'><a className="m-0 p-0" href="#/jobs">Job Board</a></span>
                                        <span className='my-0 mx-1 p-0 is-active is-text-weight-normal is-size-7'>/</span>
                                        <span className='my-0 mx-1  p-0 is-active is-text-weight-normal is-size-7'>Job Description</span>
                                    </p>
                                </div>
                                <ContactHelpLink />
                            </div>
                            <div className="minHeightSection m-0 p-0 is-full is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center columns">
                                <div className="oneHundredPercentWidth columns m-0 p-5">
                                    <div className='m-0 p-0 column is-three-quarters descriptionContent is-flex is-flex-direction-rows is-align-items-center'>
                                        <div className='mx-4 p-5 is-flex is-flex-wrap-wrap is-flex-direction-column is-align-content-center is-justify-content-space-between'>
                                            <JobInfo {...job} />
                                        </div>
                                    </div>
                                    <div className='m-0 p-0 column is-flex is-flex-direction-column is-flex-wrap-wrap is-justify-content-center is-align-items-end'>
                                        <div className='linkContactEditArchive is-flex is-flex-direction-rows is-justify-content-center is-align-items-end'>
                                            {userloggedin.role === 'admin' || userloggedin.role === 'coach' ? (
                                                <>
                                                    <p className='m-0 py-2'><a href={`/#/create-job?id=${job.id}`} role="button" className="noUnderline button buttonLight buttonMedium">Edit job</a></p>
                                                </>
                                            ) : null}
                                            {userloggedin.role === 'admin' ? (
                                                <>
                                                    <p className='py-2 pl-4 m-0'><a onClick={handleArchive} role="button" className="noUnderline button buttonLight buttonMedium">Archive job</a></p>
                                                </>
                                            ) : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="is-full is-flex is-flex-direction-column columns m-0 p-6 ">
                        <div className="columns m-0 p-0">
                            {/* Left column */}
                            <div className='m-0 px-4 column is-two-thirds is-flex is-flex-direction-column colorDarkGrey'>
                                
                                {userloggedin.role === 'admin' ||  userloggedin.role === 'coach' ||  job.employerDetails?.id === userloggedin.employerDetails?.id ? (
                                <>
                                    <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                                        <div className='is-flex is-flex-direction-column'>
                                            <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Recommended candidates</h2>
                                            {userloggedin.role === 'admin' ||  userloggedin.role === 'coach' ? (
                                                <p className='pl-4 is-size-6 has-text-weight-normal'><span className='has-text-weight-bold'></span>Only managers of this position will see this list.</p>
                                            ) : null }
                                        </div>
                                        {userloggedin.role === 'admin' ||  userloggedin.role === 'coach' ? (
                                            <div className='pr-4 is-size-6'><a href={`/#/add-candidate?id=${job.id}`}>Add candidate</a></div>
                                        ) : null }
                                    </div>
                                    <div className='pb-6'>
                                        {candidates.map((j, i) => {
                                            return (
                                                <div key={i} className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                                                    <div><p className='is-size-6 has-text-weight-bold colorBlack'>{j.clientDetails.firstName || ''} {j.clientDetails.lastName || ''}</p></div>
                                                    <div><p className='is-size-6 has-text-weight-normal'><a className="colorLightBlue" href={`#/profile?id=${j.clientId}`}>View profile</a></p></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                                ) : null }
                            <JobTabs {...job} />
                            </div>
                            {/* Right column */}
                            <div className='column px-4 m-0'>
                                
                                <div className='is-flex is-flex-direction-column pb-2'>
                                    <CopyLinkButton url={`https://clausencareers.org/#/job?id=${job.id}`} />
                                </div>
                                <div className='column p-2 m-1 mb-4 cardDefault boxDiv'>
                                    <JobAddress 
                                        nameEmployer={job.employerDetails?.nameEmployer}
                                        addressPrimaryStreet={job.locationDetails?.addressPrimaryStreet}
                                        addressPrimarySuite={job.locationDetails?.addressPrimarySuite}
                                        addressPrimaryCity={job.locationDetails?.addressPrimaryCity}
                                        addressPrimaryZip={job.locationDetails?.addressPrimaryZip}
                                        googleLink={job.locationDetails?.googleLink}
                                    />
                                </div>
                                <div className='column p-2 m-1 mb-4 cardDefault boxDiv halfHeight'>
                                    <JobMap lat={job.locationDetails?.locationLat} lng={job.locationDetails?.locationLong} />
                                </div>
                                <div className='column p-2 m-1 mb-4 cardDefault boxDiv'>
                                    <JobTransportation 
                                        publicTransportation={job.jobDetails}
                                        googleLink={job.locationDetails?.googleLink}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {showModal &&
                    <JobPostingModal
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => { setShowModal(false) }}
                    />
                }
                {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => { setToastMessage('') }} />}
                <div className="section is-flex is-justify-content-center m-0 p-0">
                    <div className='container p-0 m-0'>
                        <Footer />
                    </div>
                </div>
            </section>
        </>
    );
};

export default JobsDetail;