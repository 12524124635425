import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import ContactRowInfo from './ContactRowInfo';
import Pagination from './Pagination';
import FilterSortModals from './FilterSortModals';
import ContactHelpLink from './ContactHelpLink';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import JobPostingModal from './ConfirmationModal';
import { CheckSession } from './Auth-Api';

const Contacts = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [archive, setArchive] = useState('');

    const [pages, setPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState();
    const [sort, setSort] = useState('createdOn');
    const [sortOrder, setSortOrder] = useState(-1);
    const [active, setActive] = useState(true);
    const [filterQry, setFilterQry] = useState('');
    
    // const [selectedFilters, setSelectedFilters] = useState({
    //     Role: { 'Admin': true, 'Job coaches': true, 'Clients': true, 'Caregivers': true, 'Employers': true, 'Providers': true, 'Case Managers': true },
    //     Status: { 'Active': true, 'Archived': false },
    // });

    const [filters, setFilters] = useState({
        Role: ['Admin', 'Job coaches', 'Clients', 'Caregivers', 'Employers', 'Providers', 'Case Managers'],
        Status: ['Active', 'Archived'],
    });

    const defaultFilters = {
        Role: { 'Admin': true, 'Job coaches': true, 'Clients': true, 'Caregivers': true, 'Employers': true, 'Providers': true, 'Case Managers': true },
        Status: { 'Active': true, 'Archived': false },
    };

    const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
    const [resetTrigger, setResetTrigger] = useState(0);
    
    CheckSession();

    // make sure key values are in sync with Roles if filters above
    const rolesMap = {
        admin: 'Admin',
        caregiver: 'Caregivers',
        manager: 'Case Managers',
        client: 'Clients',
        employer: 'Employers',
        coach: 'Job coaches',
        provider: 'Providers'
    }

    const sortOptions = ['Most recent', 'Alpha A-Z', 'Alpha Z-A'];
    
    const handleFilterChange = (updatedFilters) => {
        
        if ((updatedFilters.Status.Active && updatedFilters.Status.Archived) || (!updatedFilters.Status.Active && !updatedFilters.Status.Archived)) {
            setActive(undefined);
        } else if (updatedFilters.Status.Active) {
            setActive(true);
        } else if (updatedFilters.Status.Archived) {
            setActive(false);
        }

        let filterStr = '';
        for (const i in rolesMap) {
            if (Object.prototype.hasOwnProperty.call(rolesMap, i)) {
                const element = rolesMap[i];
                if (updatedFilters.Role && updatedFilters.Role[element]) {
                    filterStr += `&role=${i}`;
                }
                
            }
        }

        setFilterQry(filterStr);
        setSelectedFilters(updatedFilters);
    };

    const handleClearFilters = () => {
        setSelectedFilters(defaultFilters);
        setActive(true);
        setFilterQry('');
        setSort('createdOn');
        setSortOrder('-1');
        setCurrentPage(1);
        setResetTrigger(prev => prev + 1);  // Increment to trigger reset in child component
    };

    const handleSortChange = (newSort) => {
        switch(newSort) {
            case 'Most recent':
                setSort('createdOn');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
            case 'Alpha A-Z':
                setSort('firstName');
                setSortOrder('1');
                setCurrentPage(1);
                break;
            case 'Alpha Z-A':
                setSort('firstName');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const handleArchive = (id, flag) => {
        setShowModal(true);
        setModalAction(() => () => handleArchiveSubmit(id, flag));
        setAlertHeading('Confirm');
        setAlertAction('Archive Contact');
        setAlertMessage('Are you sure you want to archive this job posting? This is not reversable.');
    }

    const handleArchiveSubmit = (id, flag) => {
        const payload = {
            id: id,
            active: flag,
        }
        axios.put(`/api/user/${id}`, payload)
        .then(res => {
            setToastMessage(flag ? 'Unarchived' : 'Archived');
            setArchive(flag + id);
        })
        .catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }
    }, []);

    useEffect(() => {
        axios.get(`/api/user?page=${currentPage}&limit=12&sort=${sort}&sortOrder=${sortOrder}&active=${active}${filterQry}`)
        .then(res => {
            if (res.data.metaData.totalPages < parseInt(res.data.metaData.pageNumber || 1)) {
                setCurrentPage(res.data.metaData.totalPages);
            } else {
                setPages(res.data.metaData.totalPages);
                setCurrentPage(parseInt(res.data.metaData.pageNumber || 1));
                setTotalUsers(res.data.metaData.totalDocuments || 0);
                setUsers(res.data.data);
            }
        })
        .catch(err => {
        });
    }, [currentPage, sort, sortOrder, active, filterQry, archive]);
    
    return (
        <section className="backgroundLightGreyColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate />
                        <div className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                            <div className="breadcrumbLeft" aria-label="breadcrumbs">
                                <p className='m-0 p-0 is-flex is-flex-direction-row is-justify-content-start is-align-items-center'>
                                    <span className='my-0 ml-0 mr-1 p-0 is-text-weight-normal is-size-7'><a className="m-0 p-0" href="#/">Home</a></span>
                                    <span className='my-0 mx-1 p-0 is-active is-text-weight-normal is-size-7'>/</span>
                                    <span className='my-0 mx-1  p-0 is-active is-text-weight-normal is-size-7'>Contacts</span>
                                </p>
                            </div>
                            <ContactHelpLink />
                        </div>
                        <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-full is-mobile m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="my-4 ml-4 p-0 is-flex is-flex-direction-column is-align-content-flex-start">
                                            <div className='is-flex is-flex-wrap-wrap is-flex-direction-row is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="is-size-3 has-text-weight-bold has-text-black">Contacts <span className="is-size-5 has-text-weight-normal">({totalUsers})</span></h1>
                                                <div className='my-4 mr-4 p-0 is-flex is-flex-direction-row is-align-items-center'>
                                                    <FilterSortModals 
                                                        filters={filters} 
                                                        initialSelectedFilters={selectedFilters}
                                                        selectedFilters={selectedFilters}
                                                        sortOptions={sortOptions}
                                                        initialSort="Most recent"
                                                        onFilterChange={handleFilterChange}
                                                        onSortChange={handleSortChange}
                                                        resetTrigger={resetTrigger}
                                                    />
                                                    
                                                    <a 
                                                        href="#" 
                                                        className="ml-3 is-size-6"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleClearFilters();
                                                        }}
                                                    >
                                                        Clear
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='contentBox mb-6'>
                                            <table className='table is-hoverable is-fullwidth mb-4 pb-4'>
                                                <thead>
                                                    <tr className='is-fullwidth no-hover'>
                                                        <th></th>
                                                        <th><p className='is-size-7'><abbr title="Name">Name</abbr></p></th>
                                                        <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Email">Email</abbr></p></th>
                                                        <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Phone">Phone</abbr></p></th>
                                                        <th><p className='is-size-7'><abbr title="Role">Role</abbr></p></th>
                                                        <th></th>
                                                    </tr>
                                                    {
                                                        users.map((user, i) => {
                                                            return <ContactRowInfo key={i} contactContent={user} archive={handleArchive}/>
                                                        })
                                                    }
                                                </thead>
                                            </table>

                                            {totalUsers > 12 && (
                                                <Pagination pages={pages} currentPage={currentPage} updatePage={handlePageChange}/>
                                            )}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    {showModal && 
                        <JobPostingModal 
                            alertId="SE001"
                            alertHeading={alertHeading}
                            alertAction={alertAction}
                            alertMessage={alertMessage}
                            alertSubmit={modalAction}
                            close={() => {setShowModal(false)}}
                        />
                    }
                    {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
                    <Footer /> 
                </div>
            </div>
        </section>
    );    
};

export default Contacts;