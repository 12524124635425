import React from 'react';
import { dateHelper, timeHelper } from '../utils.js/utils';

const SearchResultsRow = ({ 
    searchResultContent, 
    currentPage
}) => {

    const handleClick = (val) => {
        const url = val;

        // Check if contactUrl exists and is not empty
        if (val && typeof val === 'string' && val.trim() !== '') {
            window.location.href = val;
        } else {
            console.error('Invalid or missing val');
        }
    };

    const numPage = (currentPage - 1 || 0 ) * 12;

    return (
            <>
            {searchResultContent.map((result, index) => {
                let searchUrl = '';
                let type = '';
                let title = '';
                let description = '';
                if (result.firstName) {
                    searchUrl = `/#/profile?id=${result.id}`;
                    type = 'Contact';
                    title = `${result.firstName} ${result.lastName}`;
                } else if (result.skillTitle) {
                    searchUrl = `/#/course?id=${result.id}`;
                    type = 'Skill';
                    title = `${result.skillTitle}`;
                    description = `${result.skillDescription}`;
                } else if (result.jobTitle) {
                    searchUrl = `/#/job?id=${result.id}`;
                    type = 'Job';
                    title = `${result.jobTitle}`;
                    description = `${result.jobDescription}`;
                }

                return (
                    <div key={index} onClick={() => handleClick(searchUrl)} className='searchResultsItem p-5 m-4 is-clickable is-flex is-flex-direction-row is-justify-content:center' style={{ cursor: 'pointer' }}>
                        <div className='searchResultsItem pt-1'><p className='has-text-weight-bold colorLightGreen is-size-5'>{(index + 1) + numPage}.</p></div>
                        <div className='searchResultsItem'>
                            <div className='px-2'>
                                <h2 className='has-text-weight-bold is-size-4'><a>{title}</a></h2>
                            </div>
                            <div className='px-2 pt-1'><p className='has-text-weight-normal is-size-6'>{description}</p></div>
                            <div className='p-2 is-flex is-flex-direction-row'>
                                <div className='mr-4'><p className='is-size-7'><span className='has-text-weight-bold'>Category: </span>{type}</p></div>                        
                                {(() => {
                                        if (type === "Contact") {
                                            return <><div className='mr-4'><p className='is-size-7'><span className='has-text-weight-bold'>Role: </span>{result.role}</p></div></>;
                                        } else if (type === "Skill") {
                                            return <><div className='mr-4'><p className='is-size-7'><span className='has-text-weight-bold'>Provider: </span>{result.selectLocation}</p></div></>;
                                        } else if (type === "Job" ) {
                                            return <><div className='mr-4'><p className='is-size-7'><span className='has-text-weight-bold'>Employer: </span>{result.employerName}</p></div></>; 
                                        } else {
                                            return <><div></div></>;
                                        }
                                    })()}
                                <div className='is-hidden-mobile'><p className='is-size-7'><span className='has-text-weight-bold'>Updated: </span>{dateHelper(result.lastUpdatedOn)} at {timeHelper(result.lastUpdatedOn)}</p></div>
                            </div>
                            
                        </div>
                    </div>
                );
            })}
            </>
    );
};

export default SearchResultsRow;