import React from 'react';

const ProfileSupportiveEmployment = ({ 

    clientCurrentStep 

}) => {
    const stages = [
      { name: 'intake', title: 'Intake', activeStatus: 'Completed.', inactiveStatus: 'In progress.', statusDocument: 'No document added.', activeColor: 'backgroundDarkBlueColor', completedColor: 'backgroundDarkBlueColor', inactiveColor: 'backgroundLightGreyColor', },
      { name: 'orientation', title: 'Orientation', activeStatus: 'Completed.', inactiveStatus: 'In progress.', statusDocument: 'No document added.', activeColor: 'backgroundDarkYellowColor', completedColor: 'backgroundDarkYellowColor', inactiveColor: 'backgroundLightGreyColor', },
      { name: 'development', title: 'Development', activeStatus: 'Completed.', inactiveStatus: 'In progress.', statusDocument: 'No document added.', activeColor: 'backgroundDarkGreenColor', completedColor: 'backgroundDarkGreenColor', inactiveColor: 'backgroundLightGreyColor', },
      { name: 'exploration', title: 'Exploration', activeStatus: 'Completed.', inactiveStatus: 'In progress.', statusDocument: 'No document added.', activeColor: 'backgroundDarkRedColor', completedColor: 'backgroundDarkRedColor', inactiveColor: 'backgroundLightGreyColor', },
      { name: 'placement', title: 'Placement', activeStatus: 'Completed.', inactiveStatus: 'In progress.', statusDocument: 'No document added.', activeColor: 'backgroundDarkOutsidePurple', completedColor: 'backgroundDarkOutsidePurple', inactiveColor: 'backgroundLightGreyColor', }
    ];

    const calculateProgress = () => {
        const currentIndex = stages.findIndex(stage => stage.name === clientCurrentStep);
        const modifier = 20;
        if (clientCurrentStep == 'placement' ) { 
            return ((currentIndex) / stages.length) * 100 + modifier;
        } else {
            return ((currentIndex) / (stages.length-1)) * 100; 
        }
    };
    
    const getStageState = (stageName) => {
        const currentIndex = stages.findIndex(stage => stage.name === clientCurrentStep);
        const stageIndex = stages.findIndex(stage => stage.name === stageName);
        
        if (stageIndex === currentIndex) return 'active';
        if (stageIndex < currentIndex) return 'completed';
        return 'inactive';
    };

        const renderStageContent = (stage) => {
        const stageState = getStageState(stage.name);
        const isActive = stage.name === clientCurrentStep;
        let colorClass, stateText;
    
        switch (stageState) {
            case 'active':
                colorClass = stage.activeColor;
                stateText = 'In progress';
                break;
            case 'completed':
                colorClass = stage.completedColor;
                stateText = 'Completed';
                break;
            default:
                colorClass = stage.inactiveColor;
                stateText = 'Waiting';
        }

        return (
            <div className={`stage-content ${colorClass} cardHeight100 py-2 px-4 my-2 is-flex is-flex-direction-column`}>
                <h3 className='is-size-5 mt-2 has-text-weight-bold colorDarkGrey'>Stage: <span className='has-text-weight-normal'>{stage.title}</span></h3>
                <p className='is-size-5 mt-1 mb-3 has-text-weight-bold colorDarkGrey'>Status: <span className='has-text-weight-normal'>{stateText}</span></p>
                {/* <p className='is-size-7 mt-1 mb-2 has-text-weight-normal colorDarkGrey'>{stage.statusDocument}</p> */}
                
            </div>
            );
        };

        const progress = calculateProgress();

    return (
        <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
            <div className="p-4">
                <h2 className='is-size-5 ml-1 is-three-quarters has-text-weight-bold'>Employment Process</h2>
                {/* <p className='is-size-7 mr-1 has-text-weight-bold'>{progress.toFixed(0)}% completed</p> */}

                {stages.map((stage, index) => {
                    const stageState = getStageState(stage.name);
                    return (
                        <>
                        {stageState === 'active' && (
                            <div key={stage.name} className="step-item is-active">
                            {renderStageContent(stage)}
                            </div>
                        )}
                        </>
                    )
                })}
            </div>
        </div> 
    );
};

export default ProfileSupportiveEmployment;