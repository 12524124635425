import React, { useEffect, useState } from 'react';

import Footer from './Footer';
import NavPrivate from './NavPrivate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { CheckSession } from './Auth-Api';
import JobPostingModal from './ConfirmationModal';

const AddSkillForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [skills, setSkills] = useState([]);
    const [user, setUser] = useState({});

    const [clientId, setClientId] = useState('');
    const [isCompleted, setIsCompleted] = useState(null);
    const [completionYear, setCompletionYear] = useState('');
    const [skillId, setSkillId] = useState('');
    
    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i);
        
    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    CheckSession();
    const navigate = useNavigate();

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id) {
            navigate('/contacts?showToast=No Contact')
        }
        setClientId(id);

        axios.get(`/api/skills?page=1&limit=500&active=true&sort=search&sortOrder=1`)
        .then(res => {
            setSkills(res.data.data);
        })
        .catch(err => {
        });

        axios.get(`/api/user/${id}`)
        .then(res => {
            const u = res?.data?.data[0];
            if (u) {
                setUser(u);
            }
        })
        .catch(err => {
        });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowModal(true);
        setModalAction(() => submit);
        setAlertHeading('Confirm');
        setAlertAction('Add Skill');
        setAlertMessage('Are you sure you want to add this skill?');
    };

    const submit = () => {
        if (isCompleted === null) {
            alert('Fill out required fields');
            return;
        }
        if ((isCompleted && !completionYear) || !skillId) {
            alert('Fill out required fields');
            return;
        }

        const payload = {
            clientSkill: {
                isCompleted: isCompleted,
                completionYear: completionYear || currentYear,
                skillId: skillId,
                clientId: clientId,
                firstName: user.firstName,
                lastName: user.lastName,
            }
        };
        
        setIsLoading(true);
        axios.post('/api/clientSkills', payload)
        .then(res => {
            setIsLoading(false);
            navigate(`/profile?id=${clientId}`);
        })
        .catch(err => {
            setIsLoading(false);
            console.log(err);
        });
    };

    return (
        <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-10-mobile is-8-tablet is-6-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    <div className="has-text-black">
                                        <form onSubmit={handleSubmit}>
                                            <div className="is-size-4 my-4 has-text-black">
                                                <h1 className="has-text-weight-bold has-text-black">Add a skills course to {user.firstName}'s profile.</h1>
                                            </div>
                                            <div className="field is-flex is-flex-direction-column is-justify-content-center is-align-items-start m-0 p-0">
                                                <div className=''><p className='mt-2 has-text-weight-bold is-size-6'>Has this skill been completed?</p></div>
                                                <div className="control custom-radio nopad m-0 p-0">
                                                    <label className="m-0 p-0 radio is-flex is-align-items-center ">
                                                        <input 
                                                            className='nopad custom-radio'
                                                            type="radio" 
                                                            name="completion" 
                                                            value="completed"
                                                            onChange={() => setIsCompleted(true)}
                                                            onKeyDown={onKeyDownPrevent}
                                                        />
                                                        {' '}Yes, completed.
                                                    </label>
                                                    <label className="radio is-flex is-align-items-center ml-6 m-0 p-0">
                                                        <input
                                                            className='nopad custom-radio'
                                                            type="radio"
                                                            name="completion"
                                                            value="in-progress"
                                                            onChange={() => {
                                                                setIsCompleted(false)
                                                                setCompletionYear('');
                                                            }}
                                                            onKeyDown={onKeyDownPrevent}
                                                            />
                                                            {' '}No, in progress.
                                                    </label>
                                                </div>
                                            </div>
                                            {isCompleted !== null && (
                                            <div>
                                                {isCompleted && (
                                                    <div className="field">
                                                    <label htmlFor="name" className="label pt-4 m-1 has-text-black">Year completed</label>
                                                        <div className="select colorLightBlue">
                                                            <select 
                                                                className='formInput'
                                                                value={completionYear} 
                                                                onChange={(e) => setCompletionYear(e.target.value)}
                                                                onKeyDown={onKeyDownPrevent}
                                                            >
                                                                <option value="">Select...</option>
                                                                {years.map((year) => (
                                                                <option key={year} value={year.toString()}>
                                                                    {year}
                                                                </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>  
                                                )}
                                                <div className="field">
                                                    <label htmlFor="name" className="label m-1 pt-4 has-text-black">Skills training course</label>
                                                    <div className="select colorLightBlue">
                                                    <select
                                                        className='formInput'
                                                        onChange={(e) => setSkillId(e.target.value)}
                                                        onKeyDown={onKeyDownPrevent}
                                                    >
                                                        <option value="">Select...</option>
                                                        {skills.map((p, i) => {
                                                            return (
                                                                <option key={i} value={p.id}>{p.skillTitle}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                            <div className="field is-grouped is-flex is-justify-content-flex-end is-full">
                                                <div>
                                                    <button 
                                                        id="submit" 
                                                        className={`mt-5 button is-size-6 adaNo buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? 'Loading...' : 'Submit'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* This is end of new code */}
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default AddSkillForm;
