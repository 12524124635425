import React from "react";

const AboutProvider = ({ 
    providerDetails,
    locationDetails,
}) => {

    const descriptionProvider = {
        textDescription: [
            {title: 'Clausen House', description: "Founded in 1967, Clausen House is a registered charitable 501(c)(3) institution that provides housing, wellness programs, and advocacy for developmentally disabled adults in Oakland and the surrounding East Bay area. Our clients are adults challenged by autism, cerebral palsy, Down syndrome, and other intellectually and developmentally disabling conditions.", url: "https://clausenhouse.org?utm_source=clausencareers.org"},
            {title: 'Oakland Unified', description: "Oakland Unified School District is a public education school district that operates a total of 80 elementary schools (TK–5), middle schools (6–8), and high schools (9–12). There are also 28 district-authorized charter schools in Oakland, California, United States, serving a total of 48,704 students across both district-run and district-authorized charter schools (as of census day in 2020–21, with 35,489 of those students served by district-run schools). Located in one of California's most diverse cities, OUSD serves a diverse population of students. Nearly half of the students in district and charter schools speak a non-English language at home. For the school year 2016–17, 31 percent of OUSD students were English-language learners. OUSD also serves a large population of newcomer students.", url: "https://www.ousd.org/?utm_source=clausencareers.org"},
            {title: 'Merritt College', description: "Merritt College is a public community college in Oakland, California. Merritt, like the other three Peralta Community College District campuses, is accredited by the Accrediting Commission for Community and Junior Colleges. The college enrolls approximately 6,000 students. Merritt College's occupational programs include nursing and health professions, community social services/substance abuse counseling, environmental management and technology, cybersecurity, computer information systems, landscape horticulture and ecological management, paralegal studies, real estate, and restoration technology.", url: "https://www.merritt.edu/?utm_source=clausencareers.org"},
            {title: 'Laney College', description: "Laney College is a public community college in Oakland, California. Laney is the largest of the four Peralta Community College District colleges, serving northern Alameda County. Named after Joseph Clarence Laney, Laney College has led academic and vocational education for decades with renowned Journalism, Culinary Arts, Mathematics, Machine Technology, and Ethnic Studies programs. The talented faculty and dedicated staff, not to mention a new state-of-the-art technology center, ensure that Laney will remain at the forefront of information-age community education for years to come. The college offers certificates and credits for an Associate of Arts degree and prerequisites to transfer to four-year universities. Laney is accredited by the Accrediting Commission for Community and Junior Colleges.", url: "https://laney.edu/?utm_source=clausencareers.org"},
        ]
    }

    let providerIndex = -1;

    switch (providerDetails?.nameProviderLong) {
        case 'Clausen House':
        providerIndex = 0;
        break;
        case 'Oakland Unified':
        providerIndex = 1;
        break;
        case 'Merritt College':
        providerIndex = 2;
        break;
        case 'Laney College':
        providerIndex = 3;
        break;
        default:
        providerIndex = -1;
    }

    if (providerIndex === -1) {
        return <div>Provider information not found.</div>;
    }

    const providerInfo = descriptionProvider.textDescription[providerIndex];

    return (
        <>
        <div className="p-4">
            <p className='is-size-7 has-text-weight-bold colorLightGreen'>About provider</p>
            <p className='is-size-4 has-text-weight-bold colorDarkGrey pt-2'>{providerInfo.title}</p>
            <p className='is-size-6 colorDarkGrey has-text-weight-normal pt-2'>{providerInfo.description}</p>
            <p className='is-size-6 colorDarkGrey py-2'><a href={providerInfo.url} target="_blank">Learn more</a></p>
        </div>
        </>
    );
};

export default AboutProvider;



