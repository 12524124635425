import React from 'react';

const ConditionalComponent = ({ variable1, variable2, variable3, variable4 }) => {
  // This is for Profile Info
  const noVariablesExist = !variable1 && !variable2 && !variable3 && !variable4;

  return (
    <div className="container">
      {noVariablesExist && (
        <div className="is-size-5 mt-2 has-text-weight-bold">
          No profile info saved.
        </div>
      )}
    </div>
  );
};

export default ConditionalComponent;