import React from 'react';
import TagDisplay from "./TagDisplay";
import { dateHelper } from '../utils.js/utils';

const JobsCard = ({
    id,
    createdOn,
    jobTitle,
    jobTags,
    jobNote,
    employerDetails,
    locationDetails,
    onCardClick,
}) => {
    
    const handleClick = () => {
        if (typeof onCardClick === 'function') {
            onCardClick({
                jobId: id,
                name: employerDetails?.nameEmployer || '',
                lat: parseFloat(locationDetails.locationLat),
                lng: parseFloat(locationDetails.locationLong),
                address: locationDetails?.addressPrimaryStreet || '',
                city: locationDetails?.addressPrimaryCity || '',
                zip: locationDetails?.addressPrimaryZip || '',
                gmb: locationDetails?.googleLink || '',
            });
        }
    };

    const jobDomain = "/#/job?id=";
    const jobNumber = `${id}`;
    const jobUrl = `${jobDomain}${jobNumber}`;
    const locationStr = `${locationDetails.addressPrimaryCity} ${locationDetails.addressPrimaryState}, ${locationDetails.addressPrimaryZip}`;
    return (
        <>
        <div onClick={handleClick} key={id} className="p-4 mx-2 column boxDiv is-flex is-full-mobile is-flex-direction-column is-justify-content-space-between">
            <div className='boxDivMinHeight boxDiv is-flex is-flex-direction-column'>
                <div className='m-4 is-flex is-flex-direction-column'>
                    <div className='is-flex is-flex-direction-row is-justify-content-space-between'>
                        <p className='is-size-7 colorLightGreen has-text-weight-bold'>{employerDetails?.nameEmployer || ''}</p>
                        <p className='is-size-7 has-text-right columnWidth50 colorDarkGrey has-text-weight-normal'>{dateHelper(createdOn)}</p>
                    </div>
                    <h2 className='mt-3 is-size-4 has-text-bold lh-1'><a href={jobUrl} className='noUnderline'>{jobTitle}</a></h2>
                    <p className='is-size-7 mt-2'>{locationStr}</p>
                    <div className='is-size-7 mt-2 pt-4 has-text-weight-bold'><TagDisplay TagsContent={jobTags} /></div>
                </div>
            </div>
            <div className='is-flex is-flex-direction-column'>
                <div className='mt-3 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                    <p className='is-size-6 colorDarkGrey has-text-weight-bold'>{jobNote}</p>
                    <p><a href={jobUrl} className='button buttonLight buttonSmall'>Details</a></p>
                </div>
            </div>
        </div> 
        </>
    );
};

export default JobsCard;