// Privacy.js
import React, { useState } from 'react';
import Footer from './Footer';
import NavPublic from './NavPublic';

function Privacy() {
  return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container is-flex is-flex-direction-column is-justify-content-start">
            <NavPublic />
            <div className="my-6 has-text-black has-text-weight-bold is-size-6 is-size-7-tablet is-size-7-mobile containerSpacing">
                <h1 className="is-size-3 has-text-weight-bold">Privacy policy</h1>
                <p className="is-size-7 has-text-weight-normal my-2 colorDarkGrey">Updated on August 7, 2024</p>
                
                <p className='is-size-5 has-text-weight-normal my-5'>Lorem ipsum odor amet, consectetuer adipiscing elit. Potenti auctor molestie magnis eget lacus tincidunt luctus purus mollis. Pretium non sit felis, ante natoque dolor lacus. Tristique dictum et scelerisque neque aliquam nisi diam arcu. Nec himenaeos hendrerit fermentum ad ornare. Mollis maximus bibendum lacus; mauris litora urna. Placerat nulla vel sagittis aliquet ullamcorper laoreet massa elementum. Consequat quam finibus aptent feugiat phasellus ornare mattis.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Feugiat fames odio facilisi proin duis sem tortor lacus! Scelerisque suscipit ex tellus primis gravida nostra himenaeos bibendum. Per consequat rutrum sodales dapibus maecenas. Eu vitae consequat vitae erat posuere magna enim. Ut erat urna nibh lacus auctor mollis. Vestibulum platea litora eu dictum leo penatibus dignissim.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Vulputate mi velit class himenaeos penatibus nunc quisque. Metus tempor non suspendisse arcu mi ut vulputate vulputate. Est fermentum amet curae; et dignissim aenean risus nisi. Eget ante ex turpis euismod iaculis iaculis. Non dis ad nisl; class auctor consectetur? Accumsan nisl eleifend sollicitudin consectetur integer. Tristique aenean lectus nostra condimentum ipsum elementum.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Nascetur pretium nascetur metus aliquam congue pellentesque consectetur dapibus. In vivamus feugiat ut netus tempor class parturient eleifend. Neque ornare dapibus sed vulputate penatibus habitasse augue euismod. Elit maximus tristique purus metus litora aptent morbi nam. Nisl id magna rutrum congue porta ridiculus dis mi. Sollicitudin blandit scelerisque nostra laoreet rhoncus fusce justo. Suspendisse nascetur efficitur augue felis cubilia dictum. Convallis lectus sollicitudin hac tempor condimentum maximus purus proin sem.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Cras egestas hac parturient orci erat ante maecenas. Vestibulum scelerisque consectetur; praesent cursus ullamcorper iaculis natoque. Vehicula aptent egestas; himenaeos etiam donec rutrum. Netus vestibulum parturient suscipit sit nec auctor. Justo magnis vel nostra efficitur lacinia cras ornare. Ullamcorper porttitor class risus senectus congue nibh cras fames eros. Dapibus erat suspendisse sodales ullamcorper dapibus torquent sed sem.</p>
                
                <p className='is-size-5 has-text-weight-normal my-5'>Lorem ipsum odor amet, consectetuer adipiscing elit. Potenti auctor molestie magnis eget lacus tincidunt luctus purus mollis. Pretium non sit felis, ante natoque dolor lacus. Tristique dictum et scelerisque neque aliquam nisi diam arcu. Nec himenaeos hendrerit fermentum ad ornare. Mollis maximus bibendum lacus; mauris litora urna. Placerat nulla vel sagittis aliquet ullamcorper laoreet massa elementum. Consequat quam finibus aptent feugiat phasellus ornare mattis.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Feugiat fames odio facilisi proin duis sem tortor lacus! Scelerisque suscipit ex tellus primis gravida nostra himenaeos bibendum. Per consequat rutrum sodales dapibus maecenas. Eu vitae consequat vitae erat posuere magna enim. Ut erat urna nibh lacus auctor mollis. Vestibulum platea litora eu dictum leo penatibus dignissim.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Vulputate mi velit class himenaeos penatibus nunc quisque. Metus tempor non suspendisse arcu mi ut vulputate vulputate. Est fermentum amet curae; et dignissim aenean risus nisi. Eget ante ex turpis euismod iaculis iaculis. Non dis ad nisl; class auctor consectetur? Accumsan nisl eleifend sollicitudin consectetur integer. Tristique aenean lectus nostra condimentum ipsum elementum.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Nascetur pretium nascetur metus aliquam congue pellentesque consectetur dapibus. In vivamus feugiat ut netus tempor class parturient eleifend. Neque ornare dapibus sed vulputate penatibus habitasse augue euismod. Elit maximus tristique purus metus litora aptent morbi nam. Nisl id magna rutrum congue porta ridiculus dis mi. Sollicitudin blandit scelerisque nostra laoreet rhoncus fusce justo. Suspendisse nascetur efficitur augue felis cubilia dictum. Convallis lectus sollicitudin hac tempor condimentum maximus purus proin sem.</p>

                <p className='is-size-5 has-text-weight-normal my-5'>Cras egestas hac parturient orci erat ante maecenas. Vestibulum scelerisque consectetur; praesent cursus ullamcorper iaculis natoque. Vehicula aptent egestas; himenaeos etiam donec rutrum. Netus vestibulum parturient suscipit sit nec auctor. Justo magnis vel nostra efficitur lacinia cras ornare. Ullamcorper porttitor class risus senectus congue nibh cras fames eros. Dapibus erat suspendisse sodales ullamcorper dapibus torquent sed sem.</p>
            </div>
            <Footer /> 
        </div>
    </section>
  );
}

export default Privacy;