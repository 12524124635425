import React from 'react';

const searchResultsInfo = ({ 
    resultsTotal,
    searchTerm
}) => {


    return (
            <>
            <div className='pt-5 pb-1 px-5'><p className='is-size-5'><span className='has-text-weight-bold'>{resultsTotal} results</span> for "<span className='is-text-weight-normal'>{searchTerm}</span>"</p></div>
            </>
    );
};

export default searchResultsInfo;