import React, { useState } from 'react';
import PasswordDisplay from './PasswordDisplay';
import TextareaFormat from './TextareaFormat';
import ConditionalComponent from './ConditionalComponent';

const ProfileTabs = ({ 
  id,
  email, 
  employer, 
  clientAddressCity, 
  clientEducation, 
  clientDOB,
  clientLanguages,  
  password,
  bio,
  active, 
}) => {
  
  const [activeTab, setActiveTab] = useState('personal');

  const tabs = [
    { id: 'personal', label: 'Profile' },
    { id: 'login', label: 'Login' },
  ];

  const renderPersonalInfo = () => (
    <div>
      <ConditionalComponent 
      variable1={bio} 
      variable2={clientEducation} 
      variable3={clientLanguages}
      variable4={clientDOB} 
    />
      <p className='colorDarkGrey is-size-6 mb-4'>{bio}</p>
      {employer ? (
          <>
            <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Employer</p>
            <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{employer}</p>
          </>
        ) : (
          <p></p>
        )}
      {clientEducation ? (
      <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Education</p>
        <p className='colorDarkGrey has-text-weight-normal mb-2 is-size-6'><TextareaFormat text={clientEducation} /></p>
      </>
        ) : (
          <p></p>
      )}
      {clientLanguages ? (
      <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Languages</p>
        <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{clientLanguages}</p>
      </>
      ) : (
        <p></p>
      )}
      {clientDOB ? (
      <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Birthday</p>
        <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{clientDOB}</p>
      </>
      ) : (
        <p></p>
      )}
    </div>
  );

  const renderAccountInfo = () => (
    <div>
      <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Username</p>
      <p className='colorDarkGrey has-text-weight-normal mb-5 is-size-6'>{email}</p>
      <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Password</p>
      
      {password ? (
      <>
          <p className='colorLightGreen has-text-weight-bold mb-5 is-size-6'><a href={`#/update-password?id=${id}`}>Update password</a></p>
      </>
      ) : (
        <>
          <p className='is-size-6 mb-2'>No password set.</p>
          <p className='colorLightGreen has-text-weight-bold mb-5 is-size-6'><a href="#/update-password">Create password</a></p>
        </>
      )}
      
      <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Status</p>
      {active ? (
        <>
          <p className='colorDarkGrey has-text-weight-normal mb-5 is-size-6'>The account is active.</p>
        </>
        ) : (
          <p className='colorDarkGrey has-text-weight-normal mb-5 is-size-6'>The account is archived.</p>
      )}
      
    </div>
  );

  return (
  <div className="container">
      <div className="tabs-wrapper tabsHeight is-flex is-flex-direction-column">
          <div className="tabs is-size-6 is-boxed m-0 p-0">
              <ul className="tabsUl">
                  {tabs.map((tab) => (
                      <li
                      key={tab.id}
                      className={activeTab === tab.id ? 'is-active' : ''}
                      onClick={() => setActiveTab(tab.id)}
                      >
                      <a>{tab.label}</a>
                      </li>
                  ))}
              </ul>
          </div>
          <div className="tabsContent">
            {activeTab === 'personal' && renderPersonalInfo()}
            {activeTab === 'login' && renderAccountInfo()}
          </div>
      </div>
  </div>
  );
};

export default ProfileTabs;