import React from "react";
import RoleBasedProfileInfo from "./RoleBasedProfileInfo";

const ProfileInfo = ({ 
    role,
    firstName,
    lastName,
    personalStatement,
    selectSchool,
    currentJobTitle,
    selectReferral,
    selectEmployer,
    connectedClient,
    connectedCaregiver,
    connectedCaregiverRelationship,
    connectedCaregiverId,
    useRole,
    connectedClientId,
    connectedFirstName,
    connectedLastName,
}) => {

    if (role === 'manager') {
        useRole = 'Case manager';
    } else if (role === 'provider') {
        useRole = 'Transition Provider';
    } else if (role === 'coach') {
        useRole = 'Job Coach';
    } else {
        useRole = role;
    };

    return (
        <>
        <p className='m-0 p-0 is-size-6 has-text-weight-bold colorLightGreen is-capitalized'>{useRole}</p>
        <h1 className="mb-1 p-0 is-size-3 has-text-weight-bold has-text-black">{firstName} {lastName}</h1>
        {personalStatement ? (
            <p className='mb-2 p-0 is-size-5'>{personalStatement}</p>
        ) : null }
        <RoleBasedProfileInfo 
            selectSchool={selectSchool} 
            selectEmployer={selectEmployer} 
            connectedCaregiverId={connectedCaregiverId} 
            connectedCaregiver={connectedCaregiver} 
            connectedClient={connectedClient} 
            currentJobTitle={currentJobTitle} 
            selectReferral={connectedCaregiver} 
            connectedCaregiverRelationship={connectedCaregiverRelationship} 
            role={role}
            connectedClientId={connectedClientId} 
            connectedFirstName={connectedFirstName} 
            connectedLastName={connectedLastName} 
        />
        </>
    );
};

export default ProfileInfo;



