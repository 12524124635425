import React from 'react';

const BulletedListDisplay = ({ 
  bulletContent,
 }) => {

    if (!bulletContent || typeof bulletContent !== 'string') {
      return null;
    }
  
    // Split the text into lines and filter out empty lines
    const lines = bulletContent.split('\n').filter(line => line.trim() !== '');
  
    return (
      <ul className="custom-bullet-list">
        {lines.map((line, index) => (
          <li key={index} className="custom-bullet-list is-size-6 has-text-weight-normal">
            {line.trim()}
          </li>
        ))}
      </ul>
    );
  };

export default BulletedListDisplay;