import { useContext } from 'react';
import axios from 'axios';
import Auth from '../utils.js/Auth';

const signin = async (user) => {
    const result = await axios.post(`/api/login`, user);
    return result;
}

const hasSignedIn = async () => {
    const result = await axios.get(`/api/login/hasSignedIn`);
    return result;
}

const signout = async () => {
    const result = await axios.get(`/api/logout`);
    return result;
}

const CheckSession = async () => {
    const auth = useContext(Auth);
    const result = await hasSignedIn();
    if (!result?.data?.auth) {
        auth.setAuth(result.data.auth);
    }
}

export {
    signin,
    hasSignedIn,
    signout,
    CheckSession,
}