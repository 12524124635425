import React from 'react';

const TagRole = ({ 
    contactRole 
}) => {

  const getTagRoleColor = () => {
    switch (contactRole) {
    case 'coach':
        return 'backgroundLightOutsideGreenColor';
      case 'provider':
        return 'backgroundLightBlueColor';
    case 'employer':
        return 'backgroundLightGreenColor'
    case 'manager':
        return 'backgroundLightOutsidePurpleColor'
    case 'client':
      return 'backgroundLightRedColor';
    case 'caregiver':
        return 'backgroundLightOutsideBrownColor';
    default:
        return 'backgroundDarkGreyColor';
    }
  };

  return (
        <span className={`is-capitalized dashboardRole has-text-weight-bold is-size-6 ml-4 ${getTagRoleColor()}`}>{contactRole}</span>
  );
};

export default TagRole;