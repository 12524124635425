// Pagination.js
import React from 'react';

function Pagination({pages, currentPage, updatePage}) {
    const handlePageClick = (e) => {
        if (e.target.getAttribute('pagenum') != currentPage) updatePage(e.target.getAttribute('pagenum'));
    }

    const handlePageUp = (e) => {
        if (currentPage + 1 <= pages) updatePage(currentPage + 1);
    }
    
    const handlePageDown = (e) => {
        if (currentPage - 1 >= 1) updatePage(currentPage - 1);
    }

    const createPages = () => {
        let elements = Array.from({length: pages}, (_, i) => i + 1)
        return elements.map((e, i) => {
            const test = `Page ${e}`;
            const current = currentPage == e ? 'pagination-link is-current' : 'pagination-link';
            return <li key={i} onClick={handlePageClick} className={current} aria-label={test} aria-current="page" pagenum={e}>{e}</li>
        });
    }
    
    return (
        <nav className="pagination is-centered mb-6" role="navigation" aria-label="pagination">
            <div onClick={handlePageDown} className="button ml-4 is-size-6 adaNo buttonLight pagination-previous">Back</div>
            <div onClick={handlePageUp} className="button ml-4 is-size-6 adaNo buttonLight pagination-next">Next</div>
            <ul className="pagination-list">
                {createPages()}
            </ul>
        </nav>
    );
};

export default Pagination;