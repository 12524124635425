import React, { useState } from 'react';

const SSNDisplay = ({ clientSSN }) => {
  const [showSSN, setShowSSN] = useState(false);

  const formatSSN = (ssn) => {
    if (ssn.length !== 9) return 'Invalid SSN';
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
  };

  const toggleSSN = () => {
    setShowSSN(!showSSN);
  };

  return (
    <div className="is-flex is-align-items-center">
      <span className="mr-2">
        {showSSN ? formatSSN(clientSSN) : '•••-••-••••'}
      </span>
      <a className="is-small is-text is-size-7" onClick={toggleSSN}>
        {showSSN ? 'Hide' : 'Show'}
      </a>
    </div>
  );
};

export default SSNDisplay;