import React from 'react';

const TextareaFormat = ({ text }) => {
  // Check if text is truthy (not null, undefined, or empty string)
  if (!text) {
    return null; // Or return a placeholder message if preferred
  }

  return (
    <>
      {text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export default TextareaFormat;