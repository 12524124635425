import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobPostingModal from './ConfirmationModal';
import { CheckSession } from './Auth-Api';

const CreateLocationReferral = () => {
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [isEdit, setIsEdit] = useState(false);
    const [location, setLocation] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [referrals, setReferrals] = useState([]);
    const [formData, setFormData] = useState({
        addressPrimaryStreet: '',
        addressPrimarySuite: '',
        addressPrimaryCity: '',
        addressPrimaryState: '',
        addressPrimaryZip: '',
        phoneSecondary: '',
        emailSecondary: '',
        googleLink: '',
        locationLat: '',
        locationLong: '',
    });

    // Define requiredFields at the component level
    const requiredFields = ['nameReferralLong', 'addressPrimaryStreet', 'addressPrimaryCity', 'addressPrimaryState', 'addressPrimaryZip',];

    const fieldLabels = {
        nameReferralLong: 'Full name *',
        addressPrimaryStreet: 'Business address *',
        addressPrimarySuite: 'Suite/Number',
        addressPrimaryCity: 'City *',
        addressPrimaryState: 'State *',
        addressPrimaryZip: 'Zip code *',
        phoneSecondary: 'Alt number',
        emailSecondary: 'Alt email',
        googleLink: 'Google Business Profile',
        locationLat: 'Latitude',
        locationLong: 'Longitude',
    };

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }
    }, []);

    CheckSession();
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isEdit) {
            setShowModal(true);
            setModalAction(() => submit);
            setAlertHeading('Confirm');
            setAlertAction('Edit Location');
            setAlertMessage('Are you sure you want to edit this location?');
        } else {
            submit();
        }
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        const payload = {
            refObj: formData,
            refId: selectedOption
        }

        setIsLoading(true);

        if (isEdit) {
            payload.refObj.id = location.id;
            payload.refObj.referralId = selectedOption;
            axios.put('/api/referrals/locations', payload.refObj)
            .then(res => {
                setIsLoading(false);
                navigate(`/settings?showToast=Location updated`);
            })
            .catch(err => {
                setIsLoading(false);
                handleSubmissionError(err);
                // console.log(err);
                // alert(`Error: ${err.response.data.error}`)
            });
        } else {
            axios.post('/api/referrals/locations', payload)
            .then(res => {
                setIsLoading(false);
                navigate('/settings?showToast=Location Created');
            })
            .catch(err => {
                setIsLoading(false);
                handleSubmissionError(err);
                // console.log(err);
                // alert(`Error: ${err.response.data.error}`)
            });
        }
    };

    const handleSubmissionError = (err) => {
        if (err.response && err.response.data && err.response.data.error) {
            if (err.response.data.error === 'employerLocation validation failed') {
                setError('Location validation failed. Please check all required fields and try again.');
            } else {
                setError(`Error: ${err.response.data.error}`);
            }
        } else {
            setError('An unexpected error occurred. Please try again.');
        }
    };

    const validateForm = () => {
        let isValid = true;
        let errorMessage = '';
    
        requiredFields.forEach(field => {
            if (field === 'nameReferralLong' && !selectedOption) {
                isValid = false;
                errorMessage += `Referring provider is required.<br />`;
            } else if (field !== 'nameReferralLong' && !formData[field].trim()) {
                isValid = false;
                errorMessage += `${fieldLabels[field].replace(' *', '')} is required.<br />`;
            }
        });
    
        // Add zip code format validation
        if (formData.addressPrimaryZip && !/^\d{5}(-\d{4})?$/.test(formData.addressPrimaryZip)) {
            isValid = false;
            errorMessage += 'Invalid zip code format. Use XXXXX or XXXXX-XXXX.<br />';
        }
    
        setError(errorMessage.trim());
        return isValid;
    };

    useEffect(() => {
        const id = searchParams.get('id');

        if (id) {
            axios.get(`/api/referrals/locations/${id}`)
            .then(res => {
                console.log('ref', res.data)
                if (res?.data) {
                    const loc = res.data;
                    setIsEdit(true);
                    setLocation(loc);
                    setSelectedOption(loc.referralId)
                    setFormData({
                        addressPrimaryStreet: loc.addressPrimaryStreet || '',
                        addressPrimarySuite: loc.addressPrimarySuite || '',
                        addressPrimaryCity: loc.addressPrimaryCity || '',
                        addressPrimaryState: loc.addressPrimaryState || '',
                        addressPrimaryZip: loc.addressPrimaryZip || '',
                        phoneSecondary: loc.phoneSecondary || '',
                        emailSecondary: loc.emailSecondary || '',
                        googleLink: loc.googleLink || '',
                        locationLat: loc.locationLat || '',
                        locationLong: loc.locationLong || '',
                    });
                }
            })
            .catch(err => {
                // console.log('err', err);
                // alert(err.response.data?.error || err.response?.data);
            });
        }
    }, []);

    useEffect(() => {
        axios.get(`/api/referrals?page=1&limit=1000&sort=nameReferralLong&active=true`)
        .then(res => {
            setReferrals(res.data.data)
        })
        .catch(err => {
            // console.log('err', err);
            // alert('Error');
        });
    }, []);

    const getFieldOrder = [
        { type: 'divider', text: 'Required' },
        'nameReferralLong', 'addressPrimaryStreet', 'addressPrimarySuite', 'addressPrimaryCity', 'addressPrimaryState', 'addressPrimaryZip',
        { type: 'divider', text: 'Contact info' },
        'phoneSecondary', 'emailSecondary', 'googleLink','locationLat','locationLong',

    ];

    const renderField = (field) => {
        if (typeof field === 'object' && field.type === 'divider') {
            return (
            <div className='borderLine' key={field.text}>
                <h2 className="is-size-5 has-text-weight-bold has-text-black">{field.text}</h2>
            </div>
            );
        }
        
        const fieldName = field ;

        const fieldPlaceholder = {
            nameReferralLong: 'Enter your name.',
            addressPrimaryStreet: 'Business address',
            addressPrimarySuite: 'Suite/Number',
            addressPrimaryCity: 'City',
            addressPrimaryState: 'State',
            addressPrimaryZip: 'Zip code',
            phoneSecondary: 'Enter number as xxxyyyzzzz. No hyphens...',
            emailSecondary: 'Enter email address',
            googleLink: 'Start with https://...',
            locationLat: 'Latitude (horizontal) - e.g. 37.922367',
            locationLong: 'Longitude (vertical) - e.g. -122.3154715',
        };

        const commonProps = {
            id: fieldName,
            name: fieldName,
            value: formData[fieldName],
        };

        if (fieldName === 'nameReferralLong') {
            return (
              <div className="field pt-1" key={fieldName}>
                <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="select colorLightBlue">
                    <select
                        className={`input is-size-6 formInput inputLight ${error && requiredFields.includes(fieldName) && !formData[fieldName] ? 'errorInputLight' : ''}`}
                        value={selectedOption}
                        onChange={handleSelectChange}
                    >
                        <option value="">Select...</option>
                        {referrals.map((k, i) => {
                            return <option key={i} value={k.id}>{k.nameReferralLong}</option>

                        })}
                    </select>
                </div>
              </div>
            );
        };

        if (fieldName === 'addressPrimaryState') {
            const states = [
                'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 
                'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 
                'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 
                'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 
                'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 
                'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 
                'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 
                'Wisconsin', 'Wyoming'
                ];
        
            return (
                <div className="field pt-1" key={fieldName}>
                    <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                    <div className="select colorLightBlue">
                        <select
                            className='formInput'
                            id={fieldName}
                            name={fieldName}
                            value={formData[fieldName]}
                            onChange={handleInputChange}
                            onKeyDown={onKeyDownPrevent}
                        >
                            <option value="">Select...</option>
                            { states.map((state) => (
                                <option key={state} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            );
        };

    return (
        <div className="field pt-1" key={fieldName}>
            <label className={`label m-1 has-text-black ${error && requiredFields.includes(fieldName) && !formData[fieldName] ? 'errorTextLight' : ''}`} htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="control">
                    <input
                        className={`input is-size-6 formInput inputLight ${error && requiredFields.includes(fieldName) && !formData[fieldName] ? 'errorInputLight' : ''}`}
                        type="text"
                        {...commonProps}
                        placeholder= {fieldPlaceholder[fieldName]}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    />
                </div>
        </div>
        );
    };
        
    return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-12-mobile is-10-tablet is-8-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="is-size-4 my-4 has-text-black is-flex is-flex-direction-column is-justify-content-start is-align-items-center">
                                            <h1 className="has-text-weight-bold">{isEdit ? 'Edit' : 'Create'} <span className='has-text-weight-normal'>Referring provider</span></h1>
                                            {error && <div className="is-size-6 has-text-weight-bold colorLightRed" dangerouslySetInnerHTML={{__html: error}}></div>}
                                        </div>
                                        {getFieldOrder.map((field, index) => renderField(field))}
                                        {(
                                        <>
                                        <div className="field pt-4 mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                            <div>
                                                <button 
                                                    type="submit"    
                                                    id="submit" 
                                                    className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                    </form>
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
};

export default CreateLocationReferral;