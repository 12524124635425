import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { CheckSession } from './Auth-Api';

const CreateProviderForm = () => {
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        nameProviderLong: '',
        nameProviderShort: '',
        descriptionProvider: '',
        phonePrimary: '',
        emailPrimary: '',
        websiteLink: '',
        linkedInLink: '',
    });

    // Define requiredFields at the component level
    const requiredFields = ['nameProviderLong', 'phonePrimary', 'emailPrimary'];

    const fieldLabels = {
        nameProviderLong: 'Full name *',
        nameProviderShort: 'Shorter version of name',
        descriptionProvider: 'Organization description',
        phonePrimary: 'Main number *',
        emailPrimary: 'Primary email *',
        websiteLink: 'Website',
        linkedInLink: 'LinkedIn',
    };

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }
    }, []);
    
    CheckSession();
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));
    };

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const validateForm = () => {
        let isValid = true;
        let errorMessage = '';
    
        requiredFields.forEach(field => {
            if (!formData[field]) {
                isValid = false;
                errorMessage += `${fieldLabels[field].replace(' *', '')} is required.<br />`;
            }
        });
    
        // Add email format validation
        if (formData.emailPrimary && !/\S+@\S+\.\S+/.test(formData.emailPrimary)) {
            isValid = false;
            errorMessage += 'Invalid email format.<br />';
        }
    
        setError(errorMessage.trim());
        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        axios.post('/api/providers', formData)
        .then(res => {
            setIsLoading(false);
            navigate('/settings?showToast="Provider Created"');
        })
        .catch(err => {
            setIsLoading(false);
            // console.log(err);
            // alert(`Error: ${err.response.data.error}`)
        });
    };

    const getFieldOrder = [
        { type: 'divider', text: 'Required' },
        'nameProviderLong', 'phonePrimary', 'emailPrimary',
        { type: 'divider', text: 'Additional info' },
         'nameProviderShort', 'descriptionProvider', 'websiteLink', 'linkedInLink',
    ];

    const renderField = (field) => {
        if (typeof field === 'object' && field.type === 'divider') {
            return (
            <div className='borderLine' key={field.text}>
                <h2 className="is-size-5 has-text-weight-bold has-text-black">{field.text}</h2>
            </div>
            );
        }
        
        const fieldName = field ;

        const fieldPlaceholder = {
            nameProviderLong: 'Enter your name.',
            nameProviderShort: 'What is a shorter version of your name?',
            descriptionProvider: 'Provide a brief overview of the position.',
            phonePrimary: 'Enter number as xxxyyyzzzz. No hyphens...',
            emailPrimary: 'Enter email address',
            websiteLink: 'Start with https://...',
            linkedInLink: 'Start with https://...',
        };

        const commonProps = {
            id: fieldName,
            name: fieldName,
            value: formData[fieldName],
        };
    
        if (fieldName === 'descriptionProvider') {
            return (
              <div className="pt-1" key={fieldName}>
                <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <textarea
                    className='is-size-6 textarea-fixed-height colorLightBlue'
                    {...commonProps}
                    onChange={handleInputChange}
                    placeholder= {fieldPlaceholder[fieldName]}
                    >
                </textarea>
              </div>
            );
        };

    return (
        <div className="field pt-1" key={fieldName}>
            <label className={`label m-1 has-text-black ${error && requiredFields.includes(fieldName) && !formData[fieldName] ? 'errorTextLight' : ''}`} htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="control">
                    <input
                        className={`input is-size-6 formInput inputLight ${error && requiredFields.includes(fieldName) && !formData[fieldName] ? 'errorInputLight' : ''}`}
                        type="text"
                        {...commonProps}
                        placeholder= {fieldPlaceholder[fieldName]}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    />
                </div>
        </div>
        );
    };
        
    return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-12-mobile is-10-tablet is-8-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="is-size-4 my-4 has-text-black is-flex is-flex-direction-column is-justify-content-start is-align-items-center">
                                            <h1 className="has-text-weight-bold">Create <span className='has-text-weight-normal'>Transition Provider</span></h1>
                                            {error && <div className="is-size-6 has-text-weight-bold colorLightRed pt-4" dangerouslySetInnerHTML={{__html: error}}></div>}
                                        </div>

                                        {getFieldOrder.map((field, index) => renderField(field))}

                                        {(
                                        <>
                                        <div className="field pt-4 mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                            <div>
                                                <button 
                                                    type="submit"    
                                                    id="submit" 
                                                    className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                    </form>
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <Footer /> 
            </div>
        </div>
    </section>
  );    
};

export default CreateProviderForm;