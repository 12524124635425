import React, { useState, useEffect } from 'react';
// import Auth from '../utils.js/Auth';
// import { signin } from './Auth-Api';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { CheckSession } from './Auth-Api';

const UpdatePasswordForm = () => {
    const [password, setPassword] = useState('');
    const [passwordverify, setPasswordverify] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    CheckSession();

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    
    const id = searchParams.get('id');

    if (!id) {
        setMessage('Unknown user');
        setIsLoading(false);
        return;        
    }

    if (!password || password !== passwordverify) {
        setMessage('Passwords must match');
        setIsLoading(false);
        return;
    }

    const payload = {
        id: id,
        password: password
    }

    axios.post('/api/user/resetPassword', payload)
    .then((res) => {
        navigate('/contacts?showToast=Password updated');
    })
    .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.error) {
            setMessage(err.response.data.error);
        } else {
            setMessage('Error');
        }
    });
    
  }
    return (
        <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-10-mobile is-8-tablet is-6-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    {/* This is new code below */}
                                    <div className="has-text-black">
                                        <div className=""></div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="is-size-4 my-4 has-text-black">
                                                    <h1 className="has-text-weight-bold has-text-black">Update password</h1>
                                                    <p className='is-size-6 my-3 pb-2 has-text-black'>The new password should be between 12 and 80 characters and contain at least one uppercase letter, number, and symbol.</p>
                                                    <p>{message}</p>
                                                </div>

                                                <div className="field pt-1">
                                                    <label htmlFor="pass" className="label m-1 has-text-black">Create new password</label>
                                                    <div className="control has-icons-right">
                                                        <input 
                                                            id="pass" 
                                                            className="input inputDark" 
                                                            autoComplete="current-password" 
                                                            type="password" 
                                                            placeholder="Enter new password"
                                                            value={password} 
                                                            // onChange={handlePasswordChange}
                                                            onChange={(e) => setPassword(e.target.value)} 
                                                            onKeyDown={onKeyDownPrevent}
                                                        />
                                                        <span className="icon is-small is-right">
                                                            <i className="fas fa-check"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="field pt-1">
                                                    <label htmlFor="passVerify" className="label m-1 has-text-black">Retype new password</label>
                                                    <div className="control has-icons-right">
                                                        <input 
                                                            id="passVerify" 
                                                            className="input inputDark" 
                                                            autoComplete="current-password" 
                                                            type="password" 
                                                            placeholder="Confirm new password"
                                                            value={passwordverify} 
                                                            // onChange={handlePasswordChange}
                                                            onChange={(e) => setPasswordverify(e.target.value)} 
                                                            onKeyDown={onKeyDownPrevent}
                                                        />
                                                        <span className="icon is-small is-right">
                                                            <i className="fas fa-check"></i>
                                                        </span>
                                                    </div>

                                                </div>
                                                <div className="field is-grouped is-flex is-justify-content-flex-end is-full">
                                                    <div>
                                                        {/* <input  className="button button-dark" type="submit" value="Log in" /> */}
                                                        <button 
                                                            id="submit" 
                                                            className={`button is-size-6 adaNo buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? 'Loading...' : 'Update'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                    </div>
                                    {/* This is end of new code */}
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default UpdatePasswordForm;
