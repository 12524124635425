import React, { useState, useRef, useEffect } from 'react';

const ContactActionsMenu = ({ onEdit, onArchive, onUnarchive, id, isActive }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleEditClick = () => {
    onEdit();
    setIsOpen(false);
  };

  const handleArchiveClick = () => {
    onArchive();
    setIsOpen(false);
  };

  const handleUnarchiveClick = () => {
    onUnarchive();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        menuRef.current &&
        buttonRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && menuRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();
      
      let top = buttonRect.bottom + window.scrollY;
      let left = buttonRect.left + window.scrollX - (menuRect.width - buttonRect.width);

      // Adjust if the menu would go off the right edge of the screen
      if (left + menuRect.width > window.innerWidth) {
        left = window.innerWidth - menuRect.width - 10; // 10px buffer
      }

      // Adjust if the menu would go off the bottom of the screen
      if (top + menuRect.height > window.innerHeight + window.scrollY) {
        top = buttonRect.top + window.scrollY - menuRect.height;
      }

      menuRef.current.style.top = `${top}px`;
      menuRef.current.style.left = `${left}px`;
    }
  }, [isOpen]);

  return (
    <div className="contact-actions-menu">
      <a className="is-white" ref={buttonRef} onClick={toggleMenu} aria-label="more options">
        <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.833008 16C0.833008 16.6444 1.35534 17.1667 1.99967 17.1667C2.64401 17.1667 3.16634 16.6444 3.16634 16C3.16634 15.3557 2.64401 14.8334 1.99967 14.8334C1.35534 14.8334 0.833008 15.3557 0.833008 16Z" fill="black"/>
            <path d="M0.833008 9.00004C0.833008 9.64437 1.35534 10.1667 1.99967 10.1667C2.64401 10.1667 3.16634 9.64437 3.16634 9.00004C3.16634 8.35571 2.64401 7.83337 1.99967 7.83337C1.35534 7.83337 0.833008 8.35571 0.833008 9.00004Z" fill="black"/>
            <path d="M0.833008 2.00004C0.833008 2.64437 1.35534 3.16671 1.99967 3.16671C2.64401 3.16671 3.16634 2.64437 3.16634 2.00004C3.16634 1.35571 2.64401 0.833374 1.99967 0.833374C1.35534 0.833374 0.833008 1.35571 0.833008 2.00004Z" fill="black"/>
            <path d="M0.833008 16C0.833008 16.6444 1.35534 17.1667 1.99967 17.1667C2.64401 17.1667 3.16634 16.6444 3.16634 16C3.16634 15.3557 2.64401 14.8334 1.99967 14.8334C1.35534 14.8334 0.833008 15.3557 0.833008 16Z" stroke="#305AA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.833008 9.00004C0.833008 9.64437 1.35534 10.1667 1.99967 10.1667C2.64401 10.1667 3.16634 9.64437 3.16634 9.00004C3.16634 8.35571 2.64401 7.83337 1.99967 7.83337C1.35534 7.83337 0.833008 8.35571 0.833008 9.00004Z" stroke="#305AA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.833008 2.00004C0.833008 2.64437 1.35534 3.16671 1.99967 3.16671C2.64401 3.16671 3.16634 2.64437 3.16634 2.00004C3.16634 1.35571 2.64401 0.833374 1.99967 0.833374C1.35534 0.833374 0.833008 1.35571 0.833008 2.00004Z" stroke="#305AA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </a>
      
      {isOpen && (
        <div ref={menuRef} className="box contact-actions-box contact-actions-container" style={{minWidth: '200px'}}>
          <div className="is-flex is-flex-direction-column m-0 p-0">
          <a className="is-text m-0 p-0 is-size-6 is-fullwidth is-justify-content-center is-align-items-center" href={`#/profile?id=${id}`} onClick={handleEditClick}>View contact...</a>
            <a className="is-text m-0 p-0 is-size-6 is-fullwidth is-justify-content-center is-align-items-center" href={`#/create-contact?id=${id}`} onClick={handleEditClick}>Edit contact...</a>
            {isActive &&
              <a className="is-text m-0 p-0 is-size-6 is-fullwidth is-justify-content-center is-align-items-center" onClick={handleArchiveClick}>Archive contact...</a>
            }
            {!isActive &&
              <a className="is-text m-0 p-0 is-size-6 is-fullwidth is-justify-content-center is-align-items-center" onClick={handleUnarchiveClick}>Unarchive contact...</a>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactActionsMenu;