import React from 'react';
import PageViewCount from './PageViewCount';

const DashboardDataLatestJobs = ({
    DataLatestContent,
}) => {
    
    const handleClick = (contactObject) => {
        console.log(contactObject);
        const { contactUrl } = contactObject;

        // Check if contactUrl exists and is not empty
        if (contactUrl && typeof contactUrl === 'string' && contactUrl.trim() !== '') {
            // Navigate to the URL
            window.location.href = contactUrl;
        } else {
            console.error('Invalid or missing contactUrl');
        }
    };

    const isYes = (value) => value.toLowerCase() === 'yes';

    return (           
            <>
            <div className="is-2 my-2 oneHundredPercentWidth is-flex is-flex-direction-column is-justify-content-space-between dataBarHeight">    
                <table className='table is-hoverable'>
                    <thead>
                        <tr className='oneHundredPercentWidth no-hover'>
                            <th><p className='is-size-7'><abbr title="Name">Position</abbr></p></th>
                            <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Phone">Views</abbr></p></th>
                        </tr>
                        <>
                        {DataLatestContent.map((data, index) => {

                        const contactDomain = "/#/job?id=";
                        const contactNumber = `${data.id}`;
                        const contactUrl = `${contactDomain}${contactNumber}`;

                            return (
                                <tr key={index} onClick={() => handleClick({contactUrl})} className='rowHeight is-clickable' style={{ cursor: 'pointer' }}>
                                    <td className='rowHeight'><p className='is-size-6'>{data.jobTitle}</p><p className='is-size-7 has-text-weight-bold colorLightGreen'>{data?.employerDetails?.nameEmployer}</p></td>
                                    <td className='rowHeight is-hidden-mobile'><p className='is-size-6 centerStuff'><PageViewCount metric={data.views} /></p></td>
                                </tr>
                            );
                        })}
                        </>
                    </thead>
                </table>
            </div>
            </>   
        );
  };

export default DashboardDataLatestJobs;