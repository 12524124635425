// NavPublic.js
import React, { useState } from 'react';

function NavPublic() {
    const [isActive, setIsActive] = useState(false);
    return (
        <nav className="navbar theme-light" role="navigation" aria-label="main navigation">
            <div className="navbar-brand mx-0 px-0">
                <div className="navbar-item mx-0 px-0">
                    <a href="/#/login" className='logoLink'>
                        <svg width="145" height="14" viewBox="0 0 145 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M144.801 5.65044L143.446 6.03396C143.361 5.8081 143.235 5.58864 143.069 5.37558C142.907 5.15825 142.686 4.97927 142.404 4.83864C142.123 4.69802 141.763 4.62771 141.324 4.62771C140.723 4.62771 140.222 4.7662 139.822 5.04319C139.426 5.31592 139.227 5.66322 139.227 6.08509C139.227 6.46009 139.364 6.75626 139.637 6.97359C139.909 7.19092 140.335 7.37203 140.915 7.51691L142.372 7.87487C143.25 8.08794 143.904 8.41393 144.335 8.85285C144.765 9.28751 144.98 9.84788 144.98 10.534C144.98 11.0965 144.818 11.5993 144.494 12.0425C144.175 12.4857 143.727 12.8351 143.152 13.0908C142.577 13.3465 141.908 13.4743 141.145 13.4743C140.144 13.4743 139.315 13.257 138.659 12.8223C138.002 12.3877 137.587 11.7527 137.412 10.9175L138.844 10.5595C138.98 11.0879 139.238 11.4842 139.617 11.7484C140.001 12.0127 140.502 12.1448 141.119 12.1448C141.823 12.1448 142.381 11.9956 142.794 11.6973C143.212 11.3948 143.421 11.0325 143.421 10.6107C143.421 10.2698 143.301 9.98424 143.063 9.75413C142.824 9.51975 142.458 9.34504 141.963 9.22998L140.327 8.84646C139.428 8.63339 138.767 8.30313 138.345 7.85569C137.928 7.40399 137.719 6.83936 137.719 6.1618C137.719 5.60782 137.874 5.11776 138.186 4.69163C138.501 4.26549 138.929 3.93097 139.47 3.68808C140.016 3.44518 140.634 3.32373 141.324 3.32373C142.296 3.32373 143.058 3.5368 143.612 3.96293C144.171 4.38907 144.567 4.95157 144.801 5.65044Z" fill="#484848"/>
                            <path d="M130.88 13.2697V3.45151H132.337V4.93446H132.439C132.618 4.44867 132.942 4.05449 133.411 3.75193C133.88 3.44937 134.408 3.2981 134.996 3.2981C135.107 3.2981 135.245 3.30023 135.412 3.30449C135.578 3.30875 135.704 3.31514 135.789 3.32366V4.85776C135.738 4.84497 135.62 4.8258 135.437 4.80023C135.258 4.7704 135.069 4.75548 134.868 4.75548C134.391 4.75548 133.965 4.85563 133.59 5.05591C133.219 5.25193 132.925 5.52466 132.708 5.87409C132.495 6.21926 132.388 6.61344 132.388 7.05662V13.2697H130.88Z" fill="#484848"/>
                            <path d="M124.25 13.4743C123.304 13.4743 122.487 13.2655 121.801 12.8479C121.12 12.426 120.593 11.8379 120.223 11.0837C119.856 10.3252 119.673 9.44305 119.673 8.43737C119.673 7.43169 119.856 6.54532 120.223 5.77828C120.593 5.00697 121.109 4.40612 121.769 3.97572C122.434 3.54106 123.21 3.32373 124.096 3.32373C124.608 3.32373 125.112 3.40896 125.611 3.57941C126.11 3.74987 126.563 4.02686 126.973 4.41038C127.382 4.78964 127.708 5.29248 127.951 5.9189C128.193 6.54532 128.315 7.31663 128.315 8.23282V8.87203H120.747V7.56805H126.781C126.781 7.01407 126.67 6.51975 126.448 6.08509C126.231 5.65043 125.92 5.3074 125.515 5.05597C125.115 4.80455 124.642 4.67884 124.096 4.67884C123.495 4.67884 122.975 4.82799 122.536 5.12629C122.102 5.42032 121.767 5.80384 121.533 6.27686C121.299 6.74987 121.181 7.25697 121.181 7.79816V8.66748C121.181 9.40896 121.309 10.0375 121.565 10.5531C121.825 11.0645 122.185 11.4544 122.645 11.7229C123.105 11.9871 123.64 12.1192 124.25 12.1192C124.646 12.1192 125.004 12.0638 125.323 11.953C125.647 11.8379 125.926 11.6675 126.161 11.4416C126.395 11.2115 126.576 10.926 126.704 10.5851L128.161 10.9942C128.008 11.4885 127.75 11.9232 127.388 12.2982C127.026 12.6689 126.578 12.9587 126.046 13.1675C125.513 13.372 124.914 13.4743 124.25 13.4743Z" fill="#484848"/>
                            <path d="M113.503 13.4743C112.557 13.4743 111.741 13.2655 111.055 12.8479C110.373 12.426 109.847 11.8379 109.476 11.0837C109.11 10.3252 108.926 9.44305 108.926 8.43737C108.926 7.43169 109.11 6.54532 109.476 5.77828C109.847 5.00697 110.362 4.40612 111.023 3.97572C111.688 3.54106 112.463 3.32373 113.35 3.32373C113.861 3.32373 114.366 3.40896 114.864 3.57941C115.363 3.74987 115.817 4.02686 116.226 4.41038C116.635 4.78964 116.961 5.29248 117.204 5.9189C117.447 6.54532 117.568 7.31663 117.568 8.23282V8.87203H110V7.56805H116.034C116.034 7.01407 115.923 6.51975 115.702 6.08509C115.485 5.65043 115.173 5.3074 114.769 5.05597C114.368 4.80455 113.895 4.67884 113.35 4.67884C112.749 4.67884 112.229 4.82799 111.79 5.12629C111.355 5.42032 111.021 5.80384 110.786 6.27686C110.552 6.74987 110.435 7.25697 110.435 7.79816V8.66748C110.435 9.40896 110.563 10.0375 110.818 10.5531C111.078 11.0645 111.438 11.4544 111.899 11.7229C112.359 11.9871 112.894 12.1192 113.503 12.1192C113.899 12.1192 114.257 12.0638 114.577 11.953C114.901 11.8379 115.18 11.6675 115.414 11.4416C115.649 11.2115 115.83 10.926 115.958 10.5851L117.415 10.9942C117.261 11.4885 117.004 11.9232 116.641 12.2982C116.279 12.6689 115.832 12.9587 115.299 13.1675C114.766 13.372 114.168 13.4743 113.503 13.4743Z" fill="#484848"/>
                            <path d="M102.718 13.2697V3.45151H104.175V4.93446H104.278C104.457 4.44867 104.781 4.05449 105.249 3.75193C105.718 3.44937 106.246 3.2981 106.834 3.2981C106.945 3.2981 107.084 3.30023 107.25 3.30449C107.416 3.30875 107.542 3.31514 107.627 3.32366V4.85776C107.576 4.84497 107.459 4.8258 107.276 4.80023C107.097 4.7704 106.907 4.75548 106.707 4.75548C106.229 4.75548 105.803 4.85563 105.428 5.05591C105.058 5.25193 104.763 5.52466 104.546 5.87409C104.333 6.21926 104.227 6.61344 104.227 7.05662V13.2697H102.718Z" fill="#484848"/>
                            <path d="M95.1946 13.4999C94.5725 13.4999 94.0079 13.3827 93.5008 13.1483C92.9937 12.9097 92.591 12.5666 92.2927 12.1192C91.9944 11.6675 91.8452 11.122 91.8452 10.4828C91.8452 9.92032 91.956 9.46436 92.1776 9.11492C92.3992 8.76123 92.6954 8.48424 93.0661 8.28396C93.4368 8.08367 93.8459 7.93453 94.2934 7.83651C94.7451 7.73424 95.1989 7.65328 95.6549 7.59362C96.2515 7.51691 96.7351 7.45938 97.1059 7.42103C97.4809 7.37842 97.7536 7.3081 97.924 7.21009C98.0988 7.11208 98.1861 6.94163 98.1861 6.69873V6.64759C98.1861 6.01691 98.0135 5.52686 97.6684 5.17742C97.3275 4.82799 96.8097 4.65328 96.1151 4.65328C95.3949 4.65328 94.8303 4.81095 94.4212 5.12629C94.0121 5.44163 93.7245 5.77828 93.5583 6.13623L92.1265 5.62487C92.3821 5.02828 92.7231 4.56379 93.1492 4.2314C93.5796 3.89475 94.0483 3.66038 94.5554 3.52828C95.0668 3.39191 95.5696 3.32373 96.064 3.32373C96.3793 3.32373 96.7415 3.36208 97.1506 3.43879C97.564 3.51123 97.9624 3.66251 98.3459 3.89262C98.7337 4.12274 99.0554 4.47004 99.3111 4.93453C99.5668 5.39901 99.6946 6.02117 99.6946 6.801V13.2698H98.1861V11.9402H98.1094C98.0071 12.1533 97.8367 12.3813 97.5981 12.6242C97.3594 12.8671 97.0419 13.0737 96.6456 13.2442C96.2493 13.4146 95.7657 13.4999 95.1946 13.4999ZM95.4248 12.1448C96.0213 12.1448 96.5242 12.0276 96.9333 11.7932C97.3466 11.5588 97.6577 11.2563 97.8665 10.8855C98.0796 10.5148 98.1861 10.1249 98.1861 9.71578V8.33509C98.1222 8.4118 97.9816 8.48211 97.7642 8.54603C97.5512 8.60569 97.304 8.65896 97.0228 8.70583C96.7458 8.74845 96.4752 8.7868 96.211 8.82089C95.951 8.85072 95.7401 8.87629 95.5782 8.89759C95.1861 8.94873 94.8196 9.03183 94.4787 9.14688C94.1421 9.25768 93.8694 9.426 93.6606 9.65186C93.456 9.87345 93.3537 10.176 93.3537 10.5595C93.3537 11.0837 93.5476 11.48 93.9354 11.7484C94.3275 12.0127 94.8239 12.1448 95.4248 12.1448Z" fill="#484848"/>
                            <path d="M89.6783 4.26989H88.093C87.9993 3.81392 87.8352 3.41335 87.6009 3.06818C87.3707 2.72301 87.0895 2.43324 86.7571 2.19886C86.429 1.96023 86.0646 1.78125 85.6641 1.66193C85.2635 1.54261 84.8459 1.48295 84.4112 1.48295C83.6186 1.48295 82.9006 1.68324 82.2571 2.08381C81.6179 2.48438 81.1087 3.07457 80.7294 3.8544C80.3544 4.63423 80.1669 5.59091 80.1669 6.72443C80.1669 7.85795 80.3544 8.81463 80.7294 9.59446C81.1087 10.3743 81.6179 10.9645 82.2571 11.3651C82.9006 11.7656 83.6186 11.9659 84.4112 11.9659C84.8459 11.9659 85.2635 11.9063 85.6641 11.7869C86.0646 11.6676 86.429 11.4908 86.7571 11.2564C87.0895 11.0178 87.3707 10.7259 87.6009 10.3807C87.8352 10.0313 87.9993 9.63068 88.093 9.17898H89.6783C89.559 9.84801 89.3416 10.4467 89.0263 10.9751C88.7109 11.5036 88.3189 11.9531 87.8501 12.3239C87.3814 12.6903 86.8551 12.9695 86.2713 13.1612C85.6918 13.353 85.0717 13.4489 84.4112 13.4489C83.2947 13.4489 82.3019 13.1761 81.4325 12.6307C80.5632 12.0852 79.8793 11.3097 79.3807 10.304C78.8821 9.2983 78.6328 8.10511 78.6328 6.72443C78.6328 5.34375 78.8821 4.15057 79.3807 3.14489C79.8793 2.13921 80.5632 1.36364 81.4325 0.818182C82.3019 0.272727 83.2947 0 84.4112 0C85.0717 0 85.6918 0.095881 86.2713 0.287642C86.8551 0.479404 87.3814 0.760654 87.8501 1.13139C88.3189 1.49787 88.7109 1.94531 89.0263 2.47372C89.3416 2.99787 89.559 3.59659 89.6783 4.26989Z" fill="#484848"/>
                            <path d="M65.4654 7.59362V13.2698H62.7424V3.45157H65.3376V5.18382H65.4527C65.67 4.61279 66.0343 4.16109 66.5457 3.8287C67.0571 3.49205 67.6771 3.32373 68.4058 3.32373C69.0876 3.32373 69.6821 3.47288 70.1892 3.77117C70.6963 4.06947 71.0904 4.49561 71.3717 5.04958C71.6529 5.5993 71.7936 6.25555 71.7936 7.01833V13.2698H69.0706V7.50413C69.0748 6.90328 68.9214 6.43453 68.6103 6.09788C68.2993 5.75697 67.871 5.58651 67.3255 5.58651C66.959 5.58651 66.6352 5.66535 66.3539 5.82302C66.077 5.98069 65.8596 6.2108 65.7019 6.51336C65.5485 6.81166 65.4697 7.17174 65.4654 7.59362Z" fill="#484848"/>
                            <path d="M56.1891 13.4615C55.1792 13.4615 54.3098 13.257 53.5811 12.8479C52.8567 12.4345 52.2985 11.8507 51.9064 11.0965C51.5144 10.3379 51.3184 9.44092 51.3184 8.40541C51.3184 7.39546 51.5144 6.5091 51.9064 5.74632C52.2985 4.98353 52.8503 4.38907 53.562 3.96293C54.2779 3.5368 55.1174 3.32373 56.0804 3.32373C56.7282 3.32373 57.3311 3.42813 57.8894 3.63694C58.4519 3.84149 58.9419 4.15044 59.3596 4.56379C59.7814 4.97714 60.1096 5.49703 60.3439 6.12345C60.5783 6.74561 60.6955 7.4743 60.6955 8.30953V9.05739H52.405V7.36989H58.1323C58.1323 6.97785 58.0471 6.63055 57.8766 6.32799C57.7061 6.02544 57.4696 5.78893 57.1671 5.61847C56.8688 5.44376 56.5215 5.3564 56.1252 5.3564C55.7118 5.3564 55.3453 5.45228 55.0257 5.64404C54.7104 5.83154 54.4632 6.08509 54.2843 6.4047C54.1053 6.72004 54.0137 7.0716 54.0094 7.45938V9.06379C54.0094 9.54958 54.0989 9.96933 54.2779 10.323C54.4611 10.6767 54.7189 10.9494 55.0513 11.1412C55.3837 11.333 55.7779 11.4288 56.2338 11.4288C56.5364 11.4288 56.8134 11.3862 57.0648 11.301C57.3162 11.2158 57.5314 11.0879 57.7104 10.9175C57.8894 10.747 58.0257 10.5382 58.1195 10.2911L60.638 10.4573C60.5101 11.0624 60.248 11.5908 59.8517 12.0425C59.4597 12.4899 58.9526 12.8394 58.3304 13.0908C57.7125 13.3379 56.9988 13.4615 56.1891 13.4615Z" fill="#484848"/>
                            <path d="M49.5063 6.25129L47.0134 6.4047C46.9708 6.19163 46.8792 5.99987 46.7385 5.82941C46.5979 5.6547 46.4125 5.5162 46.1824 5.41393C45.9566 5.3074 45.686 5.25413 45.3706 5.25413C44.9488 5.25413 44.5929 5.34362 44.3032 5.52259C44.0134 5.69731 43.8685 5.93168 43.8685 6.22572C43.8685 6.46009 43.9622 6.65825 44.1497 6.82018C44.3372 6.98211 44.659 7.11208 45.1149 7.21009L46.8919 7.56805C47.8465 7.76407 48.5581 8.07941 49.0269 8.51407C49.4956 8.94873 49.73 9.51975 49.73 10.2271C49.73 10.8706 49.5404 11.4352 49.1611 11.921C48.7861 12.4068 48.2705 12.7861 47.6142 13.0588C46.9622 13.3273 46.2101 13.4615 45.3578 13.4615C44.0581 13.4615 43.0226 13.1909 42.2513 12.6497C41.4843 12.1043 41.0347 11.3628 40.9026 10.4253L43.5809 10.2847C43.6618 10.681 43.8578 10.9835 44.1689 11.1923C44.48 11.3969 44.8784 11.4992 45.3642 11.4992C45.8415 11.4992 46.225 11.4075 46.5148 11.2243C46.8088 11.0368 46.958 10.796 46.9622 10.502C46.958 10.2548 46.8536 10.0524 46.649 9.89475C46.4445 9.73282 46.1292 9.60924 45.703 9.52401L44.0027 9.18524C43.0439 8.99347 42.3301 8.66109 41.8614 8.18808C41.3969 7.71507 41.1647 7.11208 41.1647 6.37913C41.1647 5.74845 41.3351 5.20512 41.676 4.74916C42.0212 4.29319 42.5049 3.94163 43.127 3.69447C43.7534 3.44731 44.4864 3.32373 45.3259 3.32373C46.5659 3.32373 47.5418 3.5858 48.2534 4.10995C48.9693 4.6341 49.387 5.34788 49.5063 6.25129Z" fill="#484848"/>
                            <path d="M36.166 9.08944V3.45166H38.8891V13.2698H36.2747V11.4865H36.1724C35.9508 12.0617 35.5822 12.5241 35.0666 12.8735C34.5552 13.223 33.931 13.3977 33.1937 13.3977C32.5375 13.3977 31.9601 13.2485 31.4615 12.9502C30.9629 12.6519 30.573 12.2279 30.2917 11.6782C30.0148 11.1285 29.8741 10.4701 29.8699 9.70308V3.45166H32.5929V9.21729C32.5971 9.79683 32.7527 10.2549 33.0595 10.5916C33.3663 10.9282 33.7775 11.0965 34.2932 11.0965C34.6213 11.0965 34.9281 11.022 35.2136 10.8728C35.4991 10.7194 35.7292 10.4936 35.904 10.1953C36.0829 9.89697 36.1703 9.52836 36.166 9.08944Z" fill="#484848"/>
                            <path d="M21.9582 13.4551C21.3318 13.4551 20.7735 13.3465 20.2835 13.1291C19.7934 12.9075 19.4056 12.5815 19.1201 12.1511C18.8389 11.7165 18.6982 11.1753 18.6982 10.5276C18.6982 9.98211 18.7984 9.52401 18.9987 9.15328C19.199 8.78254 19.4717 8.48424 19.8168 8.25839C20.162 8.03254 20.5541 7.86208 20.993 7.74703C21.4362 7.63197 21.9007 7.551 22.3865 7.50413C22.9575 7.44447 23.4177 7.38907 23.7671 7.33794C24.1166 7.28254 24.3701 7.20157 24.5278 7.09504C24.6855 6.9885 24.7643 6.83083 24.7643 6.62203V6.58367C24.7643 6.17884 24.6365 5.86563 24.3808 5.64404C24.1293 5.42245 23.7714 5.31166 23.3069 5.31166C22.8168 5.31166 22.4269 5.42032 22.1372 5.63765C21.8474 5.85072 21.6556 6.11918 21.5619 6.44305L19.0434 6.2385C19.1713 5.64191 19.4227 5.12629 19.7977 4.69163C20.1727 4.25271 20.6563 3.91606 21.2487 3.68168C21.8453 3.44305 22.5356 3.32373 23.3197 3.32373C23.8651 3.32373 24.3872 3.38765 24.8857 3.51549C25.3886 3.64333 25.8339 3.84149 26.2217 4.10995C26.6137 4.37842 26.9227 4.72359 27.1485 5.14546C27.3744 5.56308 27.4873 6.06379 27.4873 6.64759V13.2698H24.9049V11.9082H24.8282C24.6705 12.2151 24.4596 12.4857 24.1954 12.72C23.9312 12.9502 23.6137 13.1313 23.243 13.2634C22.8722 13.3912 22.444 13.4551 21.9582 13.4551ZM22.738 11.5759C23.1386 11.5759 23.4923 11.497 23.7991 11.3394C24.1059 11.1774 24.3467 10.9601 24.5214 10.6874C24.6961 10.4146 24.7835 10.1057 24.7835 9.76052V8.71862C24.6982 8.77401 24.5811 8.82515 24.4319 8.87203C24.287 8.91464 24.123 8.95512 23.9397 8.99347C23.7565 9.02757 23.5732 9.05953 23.39 9.08936C23.2068 9.11492 23.0406 9.13836 22.8914 9.15967C22.5718 9.20654 22.2927 9.28112 22.0541 9.38339C21.8154 9.48566 21.6301 9.62416 21.498 9.79887C21.3659 9.96933 21.2998 10.1824 21.2998 10.4381C21.2998 10.8088 21.434 11.0922 21.7025 11.2882C21.9752 11.48 22.3204 11.5759 22.738 11.5759Z" fill="#484848"/>
                            <path d="M16.7071 0.178955V13.2699H13.9841V0.178955H16.7071Z" fill="#484848"/>
                            <path d="M11.7805 4.76207H8.98082C8.92969 4.39986 8.82528 4.07812 8.66761 3.79688C8.50994 3.51136 8.30753 3.26847 8.06037 3.06818C7.81321 2.8679 7.5277 2.71449 7.20384 2.60795C6.88423 2.50142 6.53693 2.44815 6.16193 2.44815C5.48438 2.44815 4.89418 2.61648 4.39134 2.95312C3.88849 3.28551 3.49858 3.77131 3.22159 4.41051C2.9446 5.04545 2.80611 5.81676 2.80611 6.72443C2.80611 7.65767 2.9446 8.44176 3.22159 9.0767C3.50284 9.71165 3.89489 10.1911 4.39773 10.5149C4.90057 10.8388 5.48224 11.0007 6.14276 11.0007C6.51349 11.0007 6.85653 10.9517 7.17188 10.8537C7.49148 10.7557 7.77486 10.6129 8.02202 10.4254C8.26918 10.2337 8.47372 10.0014 8.63565 9.72869C8.80185 9.45597 8.9169 9.14489 8.98082 8.79545L11.7805 8.80824C11.7081 9.40909 11.527 9.98864 11.2372 10.5469C10.9517 11.1009 10.5661 11.5973 10.0803 12.0362C9.59872 12.4709 9.02344 12.8161 8.3544 13.0717C7.68963 13.3232 6.9375 13.4489 6.09801 13.4489C4.9304 13.4489 3.88636 13.1847 2.96591 12.6563C2.04972 12.1278 1.32528 11.3629 0.792614 10.3615C0.264205 9.36009 0 8.14773 0 6.72443C0 5.29688 0.268466 4.08239 0.805398 3.08097C1.34233 2.07955 2.07102 1.31676 2.99148 0.792614C3.91193 0.264205 4.94744 0 6.09801 0C6.85653 0 7.55966 0.106534 8.20739 0.319602C8.85938 0.532671 9.43679 0.84375 9.93963 1.25284C10.4425 1.65767 10.8516 2.15412 11.1669 2.74219C11.4865 3.33026 11.6911 4.00355 11.7805 4.76207Z" fill="#484848"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div className='navbar-menu m-0 p-0 is-active'>
                <div className="navbar-end m-0 p-0">
                    <div className="navbar-item">
                        <a className="button is-size-6 adaNo buttonLight" href="#/">Home</a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavPublic;