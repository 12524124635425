import React, { useEffect, useState } from 'react';
import TextareaFormat from './TextareaFormat';
import axios from 'axios';

const ProfileBioInfo = ({ 
    id,
    skills,
    certifications,
    role,
    bio,
    email, 
    nameEmployer, 
    employer, 
    employerDetails,
    clientAddressCity, 
    clientEducation, 
    clientDOB,
    clientLanguages,  
    password,
    active,
    connectedClientId,
    connectFirstName,
    connectLastName,

}) => {

    const [userloggedin, setUserLoggedIn] = useState({});

    useEffect(() => {
        axios.get('/api/user/myaccount')
        .then(res => {
            if (res?.data.data[0]) {
                setUserLoggedIn(res.data.data[0]);
            }
        })
        .catch(err => {
            console.log('err dash my account', err);
        });

    }, []);

  return (
    <>
    <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Bio</p>
    {bio ? (
    <>
        <p className='colorDarkGrey has-text-weight-normal is-size-6 mb-4'>{bio}</p>
    </>
    ) : (
        <>
        <p className='colorDarkGrey has-text-weight-normal is-size-6 mb-4'>Houston, we have a missing bio here. Do you copy?</p>
        </> 
    )}

    {nameEmployer ? (
    <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Employer</p>
        <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{employerDetails?.nameEmployer || ''}</p>
    </>
    ) : null}

    {clientEducation ? (
    <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Education</p>
        <p className='colorDarkGrey has-text-weight-normal mb-2 is-size-6'><TextareaFormat text={clientEducation} /></p>
    </>
    ) : null}

    {clientLanguages ? (
    <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Languages</p>
        <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{clientLanguages}</p>
    </>
    ) : null}
    
    {role === 'client' ? (
        <>
        {userloggedin.role === 'admin' || userloggedin.role === 'coach' || userloggedin.role === 'provider'  ? (
            <>
            <div className='pt-5 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Certifications</h2>
                {userloggedin.role === 'admin' || userloggedin.role === 'coach'  ? (
                <>
                    <div className='pr-4 is-size-6'><a href={`/#/add-certification?id=${id}`}>Add certifications</a></div>
                </>
                ) : null }
            </div>
            {/* This is where you check if there are certifications */}
            <div className=''>
                {certifications?.length ? (
                    certifications.map((c, i) => {
                        return (
                            <>
                            <div className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                                <div><p className='is-size-6 has-text-weight-bold colorBlack'>{c.programName}</p></div>
                                <div><p className='is-size-6 has-text-weight-normal colorBlack'>{c.year}</p></div>
                            </div>
                            </>
                        )
                    })
                ) : (
                    <>
                    <div className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                        <div><p className='is-size-6 has-text-weight-bold colorBlack'>No certifications have been added.</p></div>
                        <div><p className='is-size-6 has-text-weight-normal colorBlack'>---</p></div>
                    </div>
                    </>
                )}
            </div>
            {/* This is the end of where we check for certifications */}
            </>
        ) : (
            <>
            {/* <div className='pt-5 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Certifications</h2>
            </div>
            <div className=''>
                <div className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                    <div><p className='is-size-6 has-text-weight-bold colorBlack'>No certifications have been added yet.</p></div>
                    <div><p className='is-size-6 has-text-weight-normal colorBlack'>---</p></div>
                </div>
            </div> */}
            </>
        )}
        </>
    ) : null}

    {role === 'client' ? (
        <>
        {userloggedin.role === 'admin' || userloggedin.role === 'coach' || userloggedin.role === 'provider' || userloggedin.id === userloggedin.id ? (
            <>
            <div className='pt-5 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Skills courses</h2>
                {userloggedin.role === 'admin' || userloggedin.role === 'coach'  ? (
                <>
                <div className='pr-4 is-size-6'><a href={`/#/add-skill?id=${id}`}>Add skills</a></div>
                </>
                ) : null }
            </div>
            {/* This is where you check if there are skills */}
            <div className=''>
                {skills?.length ? (
                    skills.map((s, i) => {
                        return (
                            <div className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                                <div><p className='is-size-6 has-text-weight-bold colorBlack'>{s.skillDetails.skillTitle}</p></div>
                                <div><p className='is-size-6 has-text-weight-normal colorBlack'>{s.completionYear}</p></div>
                            </div>
                        )
                    })
                ) : (
                    <div className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                        <div><p className='is-size-6 has-text-weight-bold colorBlack'>No skills have been added.</p></div>
                        <div><p className='is-size-6 has-text-weight-normal colorBlack'>---</p></div>
                    </div>
                )}
            </div>
            {/* This is the end of where we check for skills */}
            </>
            ) : (
            <>
            {/* <div className='pt-5 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Skills courses</h2>
            </div>
            <div className=''>
                <div className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                    <div><p className='is-size-6 has-text-weight-bold colorBlack'>No skills have been added yet.</p></div>
                    <div><p className='is-size-6 has-text-weight-normal colorBlack'>---</p></div>
                </div>
            </div> */}
            </>
        )}
        </>
    ) : null}
    
    {/* This role refers to access. Like what user is logged in. */}
    {userloggedin.role === 'admin' ? (
        <>
        <div className='pt-5'>
            <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Login</h2>
        </div>
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Status</p></div>
                <div className=''>
                    {active ? (
                        <>
                        <p className='colorDarkGrey has-text-weight-normal is-size-6'>The account is active.</p>
                        </>
                        ) : (
                        <p className='colorDarkGrey has-text-weight-normal is-size-6'>The account is archived.</p>
                    )}
                </div>
            </div>
        </div>
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Username</p></div>
                <div className=''>
                    <p className='colorDarkGrey is-size-6'>{email}</p>
                </div>
            </div>
        </div>
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Password</p></div>
                <div className=''>
                    {password ? (
                        <>
                        <p className='colorDarkGrey has-text-weight-normal is-size-6'>Password created.<span className='colorLightGreen has-text-weight-bold ml-2 mb-5 is-size-7'><a href={`#/update-password?id=${id}`}>Update password</a></span></p>
                        </>
                    ) : (
                        <>
                        <p className='colorDarkGrey is-size-6'>No password has been created.<span className='colorLightGreen has-text-weight-bold ml-2 mb-5 is-size-7'><a href={`#/update-password?id=${id}`}>Create new password</a></span></p>
                        </>
                    )}
                </div>
            </div>
        </div>
        </>
        ) : null}
    
    </>
  );
};

export default ProfileBioInfo;