import React from 'react';

const Avatar = ({ imageUrl, initials, size }) => {
  const styles = {
    avatar: {
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: '50%',
      border: '2px solid #ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor: '#305AA5',
      fontFamily: 'Inter',
      fontSize: `${size / 2.5}px`,
      fontWeight: 'bold',
      color: '#ffffff',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  };

  return (
    <div style={styles.avatar}>
      {imageUrl ? (
        <img src={imageUrl} alt={initials} style={styles.img} />
      ) : (
        <span>{initials}</span>
      )}
    </div>
  );
};

export default Avatar;