import React, { useState, useEffect } from 'react';

const ConfirmationToast = ({ message, type = 'success', duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!isVisible) return null;

  const toastClasses = `notification is-light ${
    type === 'success' ? 'is-success' : 
    type === 'error' ? 'is-danger' : 
    type === 'warning' ? 'is-warning' : 'is-info'
  }`;

  return (
    <div className="toast-container " style={{
      position: 'fixed',
      bottom: '1rem',
      left: '1rem',
      margin: '2rem 1rem',
      zIndex: 1000,
    }}>
      <div className={toastClasses} style={{ minWidth: '324px'}}>
        <button className="delete" onClick={() => {
          setIsVisible(false);
          onClose();
        }}></button>
        {message}
      </div>
    </div>
  );
};

// Example usage in a JobManagementComponent
const JobManagementComponent = ({ toastMessage, close }) => {
  const [toast, setToast] = useState({toastMessage: toastMessage, type:'success'});

  const showToast = (message, type) => {
    setToast({ message, type });
  };

  const handleClose = () => {
    setToast(null);
    close();
  }
  // const handleCreateJob = () => {
  //   // Logic to create a job
  //   showToast({toastMessage}, "success");
  // };

  // const handleEditJob = () => {
  //   // Logic to edit a job
  //   showToast({toastMessage}, "success");
  // };

  // const handleArchiveJob = () => {
  //   // Logic to archive a job
  //   showToast({toastMessage}, "success");
  // };

  return (
    <div>
      {/* <button className="button is-primary" onClick={handleCreateJob}>Create Job</button>
      <button className="button is-info" onClick={handleEditJob}>Edit Job</button>
      <button className="button is-danger" onClick={handleArchiveJob}>Archive Job</button> */}
      
      {toast && (
        <ConfirmationToast
          message={toastMessage}
          type={toast.type}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default JobManagementComponent;