import React, { useEffect, useState } from 'react';
import './App.css';

import { HashRouter as Router } from 'react-router-dom';

import Routers from './routes/Routes';

import Auth from './utils.js/Auth';

import { hasSignedIn } from './components/Auth-Api';

const App = () => {
  const [auth, setAuth] = useState();

  const readSession = async () => {
    const result = await hasSignedIn();
    if (result.data.auth) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }

  useEffect(() => {
    readSession();
  }, []);
  
  return (
    <div className="App">
      {auth === undefined &&
        <div></div>
      }
      {(auth === false || auth === true) &&
        <Auth.Provider value={{auth, setAuth}}>
          <Router>
            <Routers/>
          </Router>
        </Auth.Provider>
      }
    </div>
  );
};

export default App;