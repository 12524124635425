import React, { useState } from 'react';
import BulletedListDisplay from './BulletedListDisplay';
import GoogleMapJobs from './GoogleMapJobs';
import { dateHelper } from '../utils.js/utils';

const SkillsTabs = ({ 
  providerDetails,
  locationDetails,
  selectSchool,
  skillDescription,
  skillNote,
  skillCategory,
  skillLocation,
  skillOutcomes,
  skillNumber,
  skillDetails,
  skillLength,
  skillCosts,
  skillCredits,
  createdOn,
}) => {

  const isInPerson = (location) => {
    const inPersonVariations = ['In person', 'in-person', 'inperson', 'in person', 'In-person', 'Hybrid'];
    return inPersonVariations.some(variation => 
      location.toLowerCase().includes(variation)
    );
  };
  
  const [activeTab, setActiveTab] = useState('about');

  const tabs = [
    { id: 'about', label: 'About' },
    { id: 'details', label: 'Details' },
  ];

  const renderAboutInfo = () => (
    <div>
      <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6 textarea-output'>{skillDescription}</p>
      
      {skillOutcomes && (
        <>
          <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Outcomes</p>
          <div className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'><BulletedListDisplay bulletContent={skillOutcomes} /></div>
        </>
      )}
      
      {skillNote && (
        <>
          {/* <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Course note</p> */}
          {/* Would like this to be a link when it is an online course */}
          {/* <p className='colorDarkGrey is-size-6 mb-4'>{skillNote}</p> */}
        </>
      )}

    </div>
  );

  const renderDetailsInfo = () => (
    <div>
      {skillDetails && (
        <>
          <p className='colorDarkGrey is-size-6 mb-4'>{skillDetails}</p>
        </>
      )}

      <p className='colorLightGreen has-text-weight-bold mb-0 pb-2 pt-1 is-size-6'>Information</p>
      
      {skillCategory && (
        <>
          <p className='colorDarkGrey is-size-6 mb-2'><span className="has-text-weight-bold">Category: </span> {skillCategory}</p>
        </>
      )}

      {skillNumber && (
        <>
          <p className='colorDarkGrey is-size-6 mb-2'><span className="has-text-weight-bold">Course number: </span> {skillNumber}</p>
        </>
      )}

      {skillLength && (
        <>
          <p className='colorDarkGrey is-size-6 mb-2'><span className="has-text-weight-bold">Length: </span> {skillLength}</p>
        </>
      )}

      {skillCredits && (
        <>
          <p className='colorDarkGrey is-size-6 mb-2'><span className="has-text-weight-bold">Credits: </span> {skillCredits}</p>
        </>
      )}

      {skillCosts && (
        <>
          <p className='colorDarkGrey is-size-6 mb-2'><span className="has-text-weight-bold">Costs: </span> {skillCosts}</p>
        </>
      )}

      {skillLocation !== "online" ? (
        <>
          <p className='colorLightGreen has-text-weight-bold mb-0 pb-2 pt-5 is-size-6'>Location</p>
          <p className="has-text-weight-bold is-size-6"><span className='is-capitalized'>{skillLocation}</span> at {providerDetails?.nameProviderLong}</p>
          <p className="has-text-weight-normal is-size-6">{locationDetails?.addressPrimaryStreet}, {locationDetails?.addressPrimaryCity} {locationDetails?.addressPrimaryState} {locationDetails?.addressPrimaryZip} </p> 
          <div className='is-size-6 mb-4 colorDarkGrey is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
          {locationDetails?.googleLink && 
            <div className="has-text-weight-bold"><a href={locationDetails.googleLink} target='_blank'>Directions</a> <span className='is-size-7 has-text-weight-normal'>(Opens Google Maps)</span></div>
          }
          </div>
        </>
      ) : (
        <>
        <div>
          <p className='colorLightGreen has-text-weight-bold mb-0 pb-2 pt-5 is-size-6'>Location</p>
          <p className="has-text-weight-bold is-size-6"><span className='is-capitalized'>{skillLocation}</span> <span className='is-size-7 has-text-weight-normal'>(Link to be sent by Instructor or Job Coach)</span></p> 
        </div>
        </>
      )}
      <p className='colorLightGreen has-text-weight-bold mb-0 pb-2 pt-5 is-size-6'>System</p>
      <p className='colorDarkGrey has-text-weight-normal mb-2 is-size-6'><span className="has-text-weight-bold">Posted date: </span> {dateHelper(createdOn)}</p>
    </div>
  );

  return (
    <div className="container">
        <div className="tabs-wrapper tabsHeight is-flex is-flex-direction-column">
            <div className="tabs is-size-6 is-boxed m-0 p-0">
                <ul className="tabsUl">
                    {tabs.map((tab) => (
                        <li
                        key={tab.id}
                        className={activeTab === tab.id ? 'is-active' : ''}
                        onClick={() => setActiveTab(tab.id)}
                        >
                        <a>{tab.label}</a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="tabsContent">
                {activeTab === 'about' && renderAboutInfo()}
                {activeTab === 'details' && renderDetailsInfo()}
            </div>
        </div>
    </div>
  );
};

export default SkillsTabs;