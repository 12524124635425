import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import NavPublic from './NavPublic';

import axios from 'axios';

const ForgetPasswordForm = () => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    console.log(e);

    if (!username) {
        setMessage('enter a username')
        return;
    }

    axios.post('/api/login/requestResetPassword', {email: username})
    .then((res, err) => {
        // show message send confirmation?
        navigate('/');
    })
    .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.error) {
            setMessage(err.response.data.error);
        } else {
            setMessage('Error');
        }
    });
  }

  return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
            <NavPublic />
            <div className="theme-dark is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 p-0">
                <div className="column is-6 m-0 p-0">
                    <div className="box ADA-dark">
                        <div className="m-4 p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="is-size-4 my-4 has-text-white">
                                    <h1 className="has-text-weight-bold">Forget password?</h1>
                                    <p className='is-size-6 my-3 pb-2'>Please create a new password. Enter your email below and check your Inbox. Click the link in the email to verify your address and reset your password.</p>
                                </div>
                                <div className="field">
                                    <label htmlFor="name" className="label m-1 has-text-white">Email address</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="name" 
                                            className="input inputDark" 
                                            autoComplete="email" 
                                            type="email" 
                                            placeholder="Enter your email address" 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)}
                            
                                        />
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p className="help file-name colorDarkRed has-text-weight-bold">The field is required.</p>
                                    <p>{message}</p>
                                </div>
                                <div className="field is-grouped is-flex is-justify-content-flex-end is-full">
                                    <div>
                                        <button 
                                            id="submit" 
                                            className={`button is-size-6 buttonDark ${isLoading ? 'is-loading' : ''}`}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p className="is-size-6 is-size-7-tablet is-size-7-mobile has-text-black has-text-centered mb-6 p-0">Need help or have a question? <a href="/#/message">Contact us</a></p>
                </div>    
            </div>      
            <Footer /> 
        </div>
        
    </section>
  );    
}

export default ForgetPasswordForm;
