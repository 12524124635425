import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const ChartComponent = ({ value }) => {
    // Data for the bar chart
    const barData = {
      labels: [''],
      datasets: [
        {
          label: '',
          data: [value], // Correct usage of the value prop
          backgroundColor: '#0C8048',
        },
      ],
    };

    return (
        <div className="px-0 py-2 m-0" style={{ width: 'auto', height: '360px' }}>
          <Bar
            data={barData}
            options={{
              indexAxis: 'y',
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  beginAtZero: true,
                  ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 5
                  }
                },
                y: {
                  ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                  }
                }
              },
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `Views: ${context.parsed.x}`;
                    }
                  }
                }
              }
            }}
          />
        </div>
      );
    };
    
    export default ChartComponent;