import React from 'react';
import SettingsActionMenu from './SettingsActionMenu.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SettingsRow = ({ 
    employerLocations,
    providerDetail,
    referrerDetail,
    handleArchiveMain
}) => {


    const handleEdit = () => {
        // console.log('Edit contact');
        // Implement your edit logic here
    };

    const handleArchive = (type, id) => {
        handleArchiveMain(type, id);
    };

    const handleOnClickPrevent = (e) => {
        e.stopPropagation();
    };

    const navigate = useNavigate();

    return (
            <>
            {employerLocations && employerLocations.map((data, index) => {
                return (
                    <tr key={index} className='rowHeight is-clickable' style={{ cursor: 'pointer' }}>
                        <td className='rowHeight'><p className='is-size-6 centerStuff'>{data.employerDetails.nameEmployer}</p></td>
                        <td className='rowHeight'><p className='is-size-6 centerStuff'>{data.addressPrimaryStreet} {data.addressPrimaryCity} {data.addressPrimaryZip}</p></td>
                        <td className='rowHeight m-0 p-0' onClick={handleOnClickPrevent}>
                            <div className='centerStuff centerHorizontal'>
                                <SettingsActionMenu onEdit={handleEdit} onArchive={handleArchive} archiveType="employers" id={data.id} edit={`#/create-location?id=${data.id}`}/>
                            </div>
                        </td>
                    </tr>
                );
            })}
            {referrerDetail && referrerDetail.map((data, index) => {
                return (
                    <tr key={index} className='rowHeight is-clickable' style={{ cursor: 'pointer' }}>
                        <td className='rowHeight'><p className='is-size-6 centerStuff'>{data.referralDetails.nameReferralLong}</p></td>
                        <td className='rowHeight'><p className='is-size-6 centerStuff'>{data.addressPrimaryStreet} {data.addressPrimaryCity} {data.addressPrimaryZip}</p></td>
                        <td className='rowHeight m-0 p-0' onClick={handleOnClickPrevent}>
                            <div className='centerStuff centerHorizontal'>
                                <SettingsActionMenu onEdit={handleEdit} onArchive={handleArchive} archiveType="referrals" id={data.id} edit={`#/create-location-referral?id=${data.id}`}/>
                            </div>
                        </td>
                    </tr>
                );
            })}
            {providerDetail && providerDetail.map((data, index) => {
                return (
                    <tr key={index} className='rowHeight is-clickable' style={{ cursor: 'pointer' }}>
                        <td className='rowHeight'><p className='is-size-6 centerStuff'>{data.providerDetails.nameProviderLong}</p></td>
                        <td className='rowHeight'><p className='is-size-6 centerStuff'>{data.addressPrimaryStreet} {data.addressPrimaryCity} {data.addressPrimaryZip}</p></td>
                        <td className='rowHeight m-0 p-0' onClick={handleOnClickPrevent}>
                            <div className='centerStuff centerHorizontal'>
                                <SettingsActionMenu onEdit={handleEdit} onArchive={handleArchive} archiveType="providers" id={data.id} edit={`#/create-location-provider?id=${data.id}`}/>
                            </div>
                        </td>
                    </tr>
                );
            })}
            </>
    );
};

export default SettingsRow;