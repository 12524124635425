import React from 'react';

const DashboardDataBar = ({
    aggs,
    total
}) => {
    const isYes = (value) => value.toLowerCase() === 'yes';

    return (
        <div className='boxDiv'>
            <div className='is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-space-between is-align-items-center'>
            
            {aggs.map((data, index) => {
                const getColorClass = () => {
                    switch (data.calculationChange) {
                        case 'positive':
                            return 'colorLightGreen';
                        case 'negative':
                            return 'colorLightRed';
                        default:
                            return 'colorDarkGrey'; 
                    }
                };

                const getDividerClass = () => {
                    switch (data.divider) {
                        case 'yes':
                            return 'borderVertical';
                        case 'no':
                            return 'borderVerticalNo';
                        default:
                            return 'borderVerticalNo';
                    }
                };

                let title = 'Title';
                let calculation = '';
                let percent = '0%';
                
                if (isFinite(Math.round((data.count / total) * 100))) {
                    percent = `${Math.round((data.count / total) * 100)}%`;
                }

                let comparedData = '';
                switch (data.stage) {
                    case 'Total':
                        title = 'Total clients';
                        calculation = data.count;
                        comparedData = data.count;
                        break;
                    case 'intake':
                        title = 'Intake';
                        calculation = percent;
                        comparedData = `${data.count} of ${total}`;
                        break;
                    case 'orientation':
                        title = 'Orientation';
                        calculation = percent;
                        comparedData = `${data.count} of ${total}`;
                        break;
                    case 'development':
                        title = 'Development';
                        calculation = percent;
                        comparedData = `${data.count} of ${total}`;
                        break;
                    case 'exploration':
                        title = 'Exploration';
                        calculation = percent;
                        comparedData = `${data.count} of ${total}`;
                        break;
                    case 'placement':
                        title = 'Placement';
                        calculation = percent;
                        comparedData = `${data.count} of ${total}`;
                        break;
                }

                return (
                    <div key={index} className={`column is-2-desktop is-6-tablet is-12-mobile is-flex is-flex-direction-column is-justify-content-space-around boxDiv ${getDividerClass()}`}>
                        <p className='pt-1 my-1 has-text-centered is-size-6 has-text-weight-bold colorDarkGrey'>{title}</p>
                        <p className={`my-1 has-text-centered is-size-2 has-text-weight-bold ${getColorClass()}`}>{calculation}</p>
                        <p className='pb-1 my-1 has-text-centered is-size-7 has-text-weight-normal colorDarkGrey'>{comparedData}</p>
                    </div>
                );
            })}
            </div>
        </div>
    );
  };

export default DashboardDataBar;