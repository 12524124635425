import React from 'react';
import { useNavigate } from 'react-router-dom';

const RoleBasedProfileInfo = ({ 
    role,
    currentJobTitle,
    selectSchool,
    selectReferral,
    selectEmployer,
    connectedCaregiver,
    connectedCaregiverRelationship,
    connectedCaregiverId,
    connectedClient,
    connectedClientId,
    connectedFirstName,
    connectedLastName,
 }) => {

  const navigate = useNavigate();

  const handleMyAccountClick = (id) => (e) => {
    e.preventDefault();
    const newUrl = `/profile?id=${id}`;
    navigate(newUrl);
    window.location.reload();
  };
  
  const renderContent = () => {
    switch (role) {
      case 'admin':
      case 'coach':
      case 'provider':
        // return currentJobTitle && currentJobTitle.trim() !== '' ? (
        return (
          <>
          <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>
          {currentJobTitle ? (
            <>
              {currentJobTitle}
              {selectSchool && ` at ${selectSchool}`}
            </>
          ) : (
            selectSchool && `Works at ${selectSchool}`
          )}
        </p>
          </>
        );
        case 'employer':
        return (
          <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>
          {currentJobTitle ? (
            <>
              {currentJobTitle}
              {selectEmployer && ` at ${selectEmployer}`}
            </>
          ) : (
            selectEmployer && `Works at ${selectEmployer}`
          )}
        </p>
        );
      case 'caregiver':
        return (
          <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>
            {connectedClientId ? 'Caregiver for' : ''} <a onClick={handleMyAccountClick(connectedClientId)} href="#">{connectedFirstName} {connectedLastName}</a>
          </p>
        );
      case 'client':
        const profileDomain = "/#/profile?id=";
        const profileNumber = `${connectedCaregiverId}`;
        const profileUrl = `${profileDomain}${profileNumber}`;
        
        return (
          <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>
            {connectedCaregiver ? 'Caregiver is' : ''} <a onClick={handleMyAccountClick(connectedCaregiverId)} href="#">{connectedCaregiver}</a> ({connectedCaregiverRelationship}) {selectSchool ? 'at ' : ''} {selectSchool}
          </p>
        );
      default:
        return (
          <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>
            {currentJobTitle} {selectReferral ? 'at ' : ''} {selectReferral}
          </p>
        );
    }
  };

  return (
    <div className="container">
      {renderContent()}
    </div>
  );
};

export default RoleBasedProfileInfo;