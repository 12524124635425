import React from "react";

const PageViewCount = ({
    metric
}) => {

    return (
        <p className="is-size-5 has-text-weight-bold">{metric}</p>
    );
};

export default PageViewCount;