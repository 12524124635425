import React, { useState } from 'react';

const CopyLinkButton = () => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = async () => {
    try {
      // Get the current URL
      const currentUrl = window.location.href;
      
      // Use the Clipboard API to copy the URL
      await navigator.clipboard.writeText(currentUrl);
      
      // Set success state to true
      setCopySuccess(true);
      
      // Reset success state after 2 seconds
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className='is-flex is-flex-direction-rows'>
        <div className='m-0 p-0'>
            <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.4922 10.5833H13.7505C16.2672 10.5833 18.3339 8.52496 18.3339 5.99996C18.3339 3.48329 16.2755 1.41663 13.7505 1.41663H12.4922" stroke="#305AA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.50033 1.41663H6.25033C3.72533 1.41663 1.66699 3.47496 1.66699 5.99996C1.66699 8.51663 3.72533 10.5833 6.25033 10.5833H7.50033" stroke="#305AA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.66699 6H13.3337" stroke="#305AA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
        <div className='m-0 p-0'>
            <p className='is-size-6 p-0 m-0 pl-2'>
                <a 
                onClick={copyToClipboard} 
                className={`${copySuccess ? 'is-success' : 'is-primary'}`}
                >
                {copySuccess ? 'Copied!' : 'Copy page link'}</a>
            </p>
        </div>
    </div>
  );
};

export default CopyLinkButton;