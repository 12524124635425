// Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="footer m-0 p-0" data-theme="light">
      <div className="my-5 container">
        <div className="columns mx-0">
            <div className="column">
              <p className="content is-size-7 color-black has-text-centered-mobile has-text-left">© 2024 Clausen Careers. All Rights Reserved.</p>
            </div>
            <div className="column has-text-centered-mobile has-text-right">
                <p className="content is-size-7 color-black"><span><a href="/#/message" target="publicview" className="ml-2">Send message</a></span></p>
            </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;