import React from 'react';
import PhoneNumberDisplay from './PhoneNumberDisplay';

const ProfileContactInfo = ({
    phone,
    phoneSecondary,
    email,
    emailPrimary,
    emailSecondary,
    instagramLink,
    facebookLink,
    twitterLink,
    websiteLink,
    linkedInLink
}) => {

    return (
        <div className='contentBox mt-3'>
            <h2 className='is-size-5 has-text-weight-bold'>Contact details</h2>
            <p className='colorLightGreen has-text-weight-bold pt-2 is-size-6'>Email address</p>
            <p className='colorDarkGrey has-text-weight-normal is-size-6'><span><a href={`mailto:{email}`}>{email}</a></span></p>
            
            {emailPrimary ? (
            <>
            <p className='colorDarkGrey has-text-weight-normal is-size-6'><span><a href={`mailto:{emailPrimary}`}>{emailPrimary}</a></span></p>
            </>
            ) : null }

            {emailSecondary ? (
            <>
            <p className='colorDarkGrey has-text-weight-normal is-size-6'><span><a href={`mailto:{emailSecondary}`}>{emailSecondary}</a></span></p>
            </>
            ) : null }

            {phone ? (
            <>
            <p className='colorLightGreen has-text-weight-bold pt-4 is-size-6'>Phone numbers</p>
            <p className='colorDarkGrey has-text-weight-normal is-size-6'><span className="is-size-6"><PhoneNumberDisplay number={phone} /></span></p>
            </>
            ) : null }

            {phoneSecondary ? (
            <>
            <p className='colorDarkGrey has-text-weight-normal is-size-6'><span className="is-size-6"><PhoneNumberDisplay number={phoneSecondary} /></span></p>
            </>
            ) : null }

        </div>
    );
};

export default ProfileContactInfo;