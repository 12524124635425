import React, { useEffect, useState } from 'react';
import NavPrivate from './NavPrivate';
import Footer  from './Footer';
import ContactHelpLink from './ContactHelpLink';
import FilterSortModals from './FilterSortModals';
import DashboardDataBar from './DashboardDataBar';
import DashboardDataRecent from './DashboardDataRecent';
import DashboardDataLatestJobs from './DashboardDataLatestJobs';
import DashboardDataTopSkills from './DashboardDataTopSkills';
import DashboardDataActivity from './DashboardDataActivity';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import { CheckSession } from './Auth-Api.js';
import DashboardUserRole from './DashboardUserRole';
import DashboardProviderAddress from './DashboardProviderAddress';
import ProviderSkillsTrainings from './ProviderSkillsTrainings';
import DashboardDataRecommended from './DashboardDataRecommended';
import EmployerJobPositions from './EmployerJobPositions';
import DashboardChartTotalViews from './DashboardChartTotalViews';
import DashboardChartHireRate from './DashboardChartHireRate';
import ProfileSupportEmploymentShort from './ProfileSupportEmploymentShort';
import PhoneNumberDisplay from './PhoneNumberDisplay';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');
    const [user, setUser] = useState({});
    const [dashboardDataRecentProfiles, setDashboardDataRecentProfiles] = useState([]);
    const [dashboardDataRecommended, setDashboardDataRecommended] = useState([]);
    const [dashboardActiveJobs, setDashboardActiveJobs] = useState([]);
    const [dashboardDataTopSkills, setDashboardDataTopSkills] = useState([]);
    const [dashboardActivityStream, setDashboardActivityStream] = useState([]);
    const [dashboardDataBar, setDashboardDataBar] = useState([]);
    const [dashboardDataTotal, setDashboardDataTotal] = useState([]);
    const [providerLocations, setProviderLocations] = useState([]);
    const [dashboardDataYourProviderSkills,setDashboardDataYourProviderSkills] = useState([]);
    const [dashboardDataYourJobs,setDashboardDataYourJobs] = useState([]);
    const [dashboardDataYourJobsFilled,setDashboardDataYourJobsFilled] = useState([]);
    const totalViews = dashboardDataYourJobs.reduce((sum, job) => sum + (job.views || 0), 0);
    const hireRate = (dashboardDataYourJobsFilled.length / (dashboardDataYourJobsFilled.length + dashboardDataYourJobs.length))*100 ;

    const navigate = useNavigate();

    const handleMyAccountClick = (id) => (e) => {
        e.preventDefault();
        const newUrl = `/profile?id=${id}`;
        navigate(newUrl);
        window.location.reload();
    };

    const [sortDays, setSortDays] = useState(90);
    CheckSession();

    const filters = {
        Employer: ['Safeway',],
        Status: ['Active', 'Archived'],
    };

    const initialSelectedFilters = {
        Employer: { 'Safeway': true},
        Status: { 'Active': true },
    };

    const sortOptions = ['Last 90 days', 'Last 180 days', 'Last 270 days', 'Year to date'];

    const handleFilterChange = (updatedFilters) => {
        
    };

    const handleSortChange = (newSort) => {
        switch(newSort) {
            case 'Last 90 days':
                setSortDays(90);
                break;
            case 'Last 180 days':
                setSortDays(180);
                break;
            case 'Last 270 days':
                setSortDays(270);
                break;
            case 'Year to date':
                let now = new Date();
                let start = new Date(now.getFullYear(), 0, 0);
                let diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
                let oneDay = 1000 * 60 * 60 * 24;
                let day = Math.floor(diff / oneDay);
                setSortDays(day);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }

        axios.get(`/api/user?page=1&limit=5&active=true&sort=lastUpdatedOn&sortOrder=-1`)
        .then(res => {
            setDashboardDataRecentProfiles(res.data.data);
        })
        .catch(err => {
            
        });

        axios.get(`/api/jobs?page=1&limit=5&active=true&sort=lastUpdatedOn&sortOrder=-1`)
        .then(res => {
            setDashboardActiveJobs(res.data.data);
        })
        .catch(err => {
            
        });

        axios.get(`/api/jobs?page=1&limit=100&active=true&sort=lastUpdatedOn&sortOrder=-1`)
        .then(res => {
            setDashboardDataYourJobs(res.data.data);
        })
        .catch(err => {
        
        });

        axios.get(`/api/jobs?page=1&limit=100&active=false&sort=lastUpdatedOn&sortOrder=-1`)
        .then(res => {
            setDashboardDataYourJobsFilled(res.data.data);
        })
        .catch(err => {
            
        });

        axios.get(`/api/skills?page=1&limit=5&active=true&sort=views&sortOrder=-1`)
        .then(res => {
            setDashboardDataTopSkills(res.data.data);
        })
        .catch(err => {
            
        });

        axios.get(`/api/activityStreams?page=1&limit=12&sort=createdOn&sortOrder=-1`)
        .then(res => {
            
            setDashboardActivityStream(res.data.data);
        })
        .catch(err => {
            
        });

        axios.get('/api/user/myaccount')
        .then(res => {
            if (res?.data.data[0]) {
                
                setUser(res.data.data[0]);
            }
        })
        .catch(err => {
            
        });

        axios.get('/api/providers/locations?page=1&limit=1000&active=true')
        // axios.get(`/api/providers/locations/${user.schoolDetails.id}`)
        .then(res => {
            
            setProviderLocations(res.data.data);
        })
        .catch(err => {
            
        });

        // Looking for the list of skills by the provider that is linked to the person (transition provider) logged in
        axios.get(`/api/skills?page=1&limit=10&active=true&providerId=${user.school}&sort=views&sortOrder=-1`)
        .then(res => {
            
            setDashboardDataYourProviderSkills(res.data.data);
            
        })
        .catch(err => {
            
        });
        // &jobId=${id}
        axios.get(`/api/jobCandidates?page=1&limit=1000&sort=createdOn&active=true`)
        .then(res => {
            if (res?.data.data) {
                // console.log(res.data.data)
                setDashboardDataRecommended(res.data.data);
            }
        })
        .catch(err => {
            
        });

    }, []);

    useEffect(() => {
        axios.get(`/api/user/clientcounts/aggs?days=${sortDays}`)
        .then(res => {
            setDashboardDataBar(res.data.aggs);
            setDashboardDataTotal(res.data.total);
        })
        .catch(err => {
            
        });
    }, [sortDays]);

    return (
        <section className="backgroundLightGreyColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate />
                        <div className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                            <div className="breadcrumbLeft" aria-label="breadcrumbs">
                                <p className='m-0 p-0 is-flex is-flex-direction-row is-justify-content-start is-align-items-center'>
                                    <span className='my-0 ml-0 mr-1 p-0 is-text-weight-normal is-size-7'><a className="m-0 p-0" href="#/">Home</a></span>
                                    <span className='my-0 mx-1 p-0 is-active is-text-weight-normal is-size-7'>/</span>
                                    <span className='my-0 mx-1  p-0 is-active is-text-weight-normal is-size-7'>---</span>
                                </p>
                            </div>
                            <ContactHelpLink />
                        </div>
                        <div className="theme-light is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-12-mobile is-10-tablet is-10-desktop m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-align-content-flex-start my-4">
                                            <div className='pb-3 is-flex is-flex-direction-row is-flex-wrap-wrap is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="is-size-3 has-text-weight-bold has-text-black">👋 Hi, {user.firstName} <DashboardUserRole contactRole={user.role} /><span className='ml-4 is-capitalized is-size-6 '></span></h1>
                                                <div className='is-flex is-flex-direction-row is-align-items-center'>
                                                    <FilterSortModals 
                                                        filters={filters} 
                                                        initialSelectedFilters={initialSelectedFilters}
                                                        sortOptions={sortOptions}
                                                        initialSort="Last 90 days"
                                                        onFilterChange={handleFilterChange}
                                                        onSortChange={handleSortChange}
                                                        hideShow="filters"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {(user.role === 'admin' || user.role === 'coach') && (
                                            <DashboardDataBar aggs={dashboardDataBar} total={dashboardDataTotal} />
                                        )}
                                        <div className='pb-6 is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-space-between'>
                                            <div className='column boxDiv is-flex is-two-thirds-desktop is-full-tablet is-full-mobile is-flex-direction-rows is-flex-wrap-wrap'>
                                                {/* Left side */}
                                                <div className='column is-flex is-full-mobile is-flex-direction-column'>
                                                    <div className='columns is-flex is-flex-direction-row'>
                                                        {(user.role === 'admin' || user.role === 'coach' || user.role === 'provider') && (    
                                                            <div className='column'>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Updated contacts</p>
                                                                <DashboardDataRecent DataRecentProfilesContent={dashboardDataRecentProfiles} />
                                                            </div>
                                                        )}

                                                        {(user.role === 'employer') && (
                                                            <>
                                                            <div className='p-2 m-1 mb-4 cardDefault boxDiv oneHundredPercentWidth'>
                                                                <div className='p-4'>
                                                                    <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Recommended candidates <span className='has-text-weight-normal'>({dashboardDataRecommended.length})</span></p>
                                                                    <DashboardDataRecommended DataRecommendedContent={dashboardDataRecommended} candidateId={user.employerDetails?.id} />   
                                                                </div>
                                                            </div> 
                                                            </>
                                                        )}

                                                        {(user.role === 'client' || user.role === 'caregiver') && (
                                                            <>
                                                            <div className='column'>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Active jobs</p>
                                                                <DashboardDataLatestJobs DataLatestContent={dashboardActiveJobs} />                                                                    
                                                            </div>

                                                            <div className='column'>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Top courses</p>
                                                                <DashboardDataTopSkills DataSkillsContent={dashboardDataTopSkills} />
                                                            </div>
                                                            
                                                            </>
                                                        )}


                                                    </div>
                                                    {(user.role === 'admin' || user.role === 'coach' || user.role === 'provider') && (
                                                        <div className='columns is-flex is-flex-direction-row'>
                                                            <div className='column'>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Active jobs</p>
                                                                <DashboardDataLatestJobs DataLatestContent={dashboardActiveJobs} />
                                                            </div>
                                                            <div className='column'>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Top courses</p>
                                                                <DashboardDataTopSkills DataSkillsContent={dashboardDataTopSkills} />
                                                            </div>
                                                        </div>
                                                    )}

                                                    {(user.role === 'employer') && (
                                                        <>
                                                        <div className='columns is-flex is-flex-direction-row'>
                                                            <div className='column boxDiv'>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Total views: <span className="has-text-weight-bold colorLightGreen">{totalViews}</span></p>
                                                                <DashboardChartTotalViews value={totalViews} />
                                                            </div>
                                                            <div className='column boxDiv'>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Hire rate: <span className="has-text-weight-bold colorLightGreen">{Number(hireRate.toFixed(1))}%</span></p>
                                                                <DashboardChartHireRate value={hireRate} />
                                                            </div>
                                                        </div>

                                                        {/* <p className='is-size-5 pl-2 pt-1 pb-2 has-text-weight-bold colorDarkGrey'>Open positions <span className='has-text-weight-normal'>({dashboardDataYourJobs.length})</span></p>
                                                        <EmployerJobPositions DataYourJobsContent={dashboardDataYourJobs} employerId={user.employerDetails?.id} /> */}
                                                        </>
                                                    )}
                                                    </div>
                                                </div>
                                                
                                                {/* Right side */}
                                                <div className='column boxDiv is-flex is-flex-direction-column borderAdd'>
                                                {(user.role === 'employer') && (    
                                                    <>
                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <DashboardProviderAddress 
                                                            nameEmployer={user.employerDetails?.nameEmployer}
                                                            addressPrimaryStreet={providerLocations.addressPrimaryStreet}
                                                            addressPrimarySuite={providerLocations.addressPrimarySuite}
                                                            addressPrimaryCity={providerLocations.addressPrimaryCity}
                                                            addressPrimaryZip={providerLocations.addressPrimaryZip}
                                                            googleLink={providerLocations.googleLink}
                                                            sectionName='Your company'
                                                        />
                                                    </div>

                                                    {/* <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <div className="p-4">
                                                            <p className='is-size-4 has-text-weight-bold colorDarkGrey'>Total views: <span className="has-text-weight-bold colorLightGreen">{totalViews}</span></p>
                                                            <DashboardChartTotalViews value={totalViews} />
                                                        </div>
                                                    </div>

                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <div className="p-4">
                                                            <p className='is-size-5 has-text-weight-bold colorDarkGrey'>Hire rate</p>
                                                            <DashboardChartHireRate />
                                                        </div>
                                                    </div> */}
                                                    
                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <p className='is-size-5 pl-2 pt-1 pb-2 has-text-weight-bold colorDarkGrey'>Open positions <span className='has-text-weight-normal'>({dashboardDataYourJobs.length})</span></p>
                                                        <EmployerJobPositions DataYourJobsContent={dashboardDataYourJobs} employerId={user.employerDetails?.id} />
                                                    </div>
                                                    
                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        {/* Support promo */}
                                                        <div className="px-4 pt-4 pb-2 is-flex is-flex-direction-column is-justify-content-center is-align-items-end">
                                                            <p className='is-size-5 pl-2 pt-1 pb-0 has-text-weight-bold colorLightGreen'>Have a new position you want included on Clausen Careers?</p>
                                                        </div>
                                                        <div className="px-4 pt-0 pb-4 is-flex is-flex-direction-column is-justify-content-center is-align-items-end">
                                                            <p className='is-size-7 has-text-weight-bold colorDarkGrey'><a href="#" target="_blank">Send a message</a></p>
                                                        </div>
                                                    </div>
                                                    </>
                                                )}

                                                {(user.role === 'provider') && (    
                                                    <>
                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <DashboardProviderAddress 
                                                            nameEmployer={user.schoolDetails?.nameProviderLong}
                                                            addressPrimaryStreet={providerLocations.addressPrimaryStreet}
                                                            addressPrimarySuite={providerLocations.addressPrimarySuite}
                                                            addressPrimaryCity={providerLocations.addressPrimaryCity}
                                                            addressPrimaryZip={providerLocations.addressPrimaryZip}
                                                            googleLink={providerLocations.googleLink}
                                                            sectionName='Your provider'
                                                        />
                                                    </div>
                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        {/* Your Skills Trainings () */}
                                                        <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Your published courses</p>
                                                        <ProviderSkillsTrainings DataYourProviderContent={dashboardDataYourProviderSkills} providerId={user.school} />
                                                        
                                                    </div>
                                                    </>
                                                )}

                                                {(user.role === 'admin' || user.role === 'coach') && (
                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Activity stream</p>
                                                        <DashboardDataActivity DataActivityContent={dashboardActivityStream} />
                                                    </div>
                                                )}

                                                {(user.role === 'client' || user.role === 'caregiver') && (
                                                    <>
                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <div className="p-4">
                                                            <p className='is-size-6 has-text-weight-bold colorLightGreen'>Your Job Coach</p>
                                                            <p className='is-size-5 colorDarkGrey has-text-weight-bold pt-2 pb-0'>Veronica Mercado Santana</p>
                                                            <p className='colorDarkGrey has-text-weight-normal pb-2 is-size-6'><span className="is-size-6"><a href="mailto:vsantana@clausenhouse.org">vsantana@clausenhouse.org</a></span></p>
                                                            <p className='colorDarkGrey has-text-weight-normal pb-2 is-size-6'><span className="is-size-6"><PhoneNumberDisplay number={5103654561} /></span></p>
                                                            {/* <p className='is-size-6 colorDarkGrey py-2'><a href="/#/profile?id=98c32470-b2d9-42c6-88dc-cc0864182c8e">Learn more</a></p> */}
                                                        </div>
                                                    </div>

                                                    <div className='p-2 m-1 mb-4 cardDefault boxDiv'>
                                                        <div className="p-4">
                                                            <p className='is-size-6 has-text-weight-bold colorLightGreen'>Messages & Activity</p>
                                                            <p className='is-size-4 has-text-weight-bold colorDarkGrey pt-2'>Update your profile.</p>
                                                            <p className='is-size-6 colorDarkGrey py-2'><a onClick={handleMyAccountClick(user.id)} href="#" >View your profile</a></p>
                                                        </div>
                                                    </div>
                                                    </>
                                                )}
                                                
                                                {(user.role === 'client') && (    
                                                    <ProfileSupportEmploymentShort 
                                                        clientCurrentStep={user.clientCurrentStep}
                                                    />                                                    
                                                )}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default Dashboard;