import React from 'react';

const PhoneNumberConverter = ({ number }) => {
  const formatPhoneNumber = (input) => {
    // Handle undefined or null input
    if (input == null) return '';
    
    // Convert to string if it's a number
    const numberString = typeof input === 'number' ? input.toString() : input;
    
    // Remove all non-digit characters
    const cleaned = numberString.replace(/\D/g, '');
    
    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    
    // If it doesn't match, return the cleaned string
    return cleaned;
  };

  return <div>{formatPhoneNumber(number)}</div>;
};

export default PhoneNumberConverter;