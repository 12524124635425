import React from 'react';

const JobAddress = ({
    nameEmployer,
    addressPrimaryStreet,
    addressPrimarySuite,
    addressPrimaryCity,
    addressPrimaryZip,
    googleLink
}) => {

    return (
        <div className="p-4">
            <p className='is-size-7 has-text-weight-bold colorLightGreen'>Employer</p>
            <p className='is-size-4 has-text-weight-bold colorDarkGrey pt-2'>{nameEmployer}</p>
            <p className='is-size-6 colorDarkGrey py-2'><a target="_blank" href={googleLink}>{addressPrimaryStreet} {addressPrimarySuite} {addressPrimaryCity} {addressPrimaryZip}</a></p>
        </div>
    );
};

export default JobAddress;