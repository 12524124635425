import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Auth from '../utils.js/Auth';
import { signout } from './Auth-Api';
import WordDisplay from './WordDisplay';
import NavMobile from './NavMobile';

const Menu = () => {
    const auth = useContext(Auth);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        axios.get('/api/user/myaccount')
            .then(res => {
                if (res?.data.data[0]) {
                    setUserRole(res.data.data[0].role); // Assuming the role is available in the response
                }
            })
            .catch(err => {
                console.log('Error fetching user account:', err);
            });
    }, []);

    const handleLogout = async () => {
        const result = await signout();
        auth.setAuth(result.data.auth);
    }

    const allAppSections = {
        client: [
            { page: "Dashboard", url: "/#/dashboard" },
            { page: "Jobs", url: "/#/jobs" },
            { page: "Skills", url: "/#/skills" },
            { page: "Account", url: "/#/account" },
        ],
        caregiver: [
            { page: "Dashboard", url: "/#/dashboard" },
            { page: "Jobs", url: "/#/jobs" },
            { page: "Skills", url: "/#/skills" },
            { page: "Account", url: "/#/account" },
        ],
        admin: [
            { page: "Dashboard", url: "/#/dashboard" },
            { page: "Contacts", url: "/#/contacts" },
            { page: "Jobs", url: "/#/jobs" },
            { page: "Skills", url: "/#/skills" },
            { page: "Settings", url: "/#/settings" },
            { page: "Create", url: "/#/create" },
            { page: "Account", url: "/#/account" },
        ],
        jobCoach: [
            { page: "Dashboard", url: "/#/dashboard" },
            { page: "Contacts", url: "/#/contacts" },
            { page: "Jobs", url: "/#/jobs" },
            { page: "Skills", url: "/#/skills" },
            { page: "Settings", url: "/#/settings" },
            { page: "Create", url: "/#/create" },
            { page: "Account", url: "/#/account" },
        ],
        provider: [
            { page: "Dashboard", url: "/#/dashboard" },
            { page: "Contacts", url: "/#/contacts" },
            { page: "Jobs", url: "/#/jobs" },
            { page: "Skills", url: "/#/skills" },
            { page: "Create", url: "/#/create" },
            { page: "Account", url: "/#/account" },
        ],
        employer: [
            { page: "Dashboard", url: "/#/dashboard" },
            { page: "Contacts", url: "/#/contacts" },
            { page: "Jobs", url: "/#/jobs" },
            { page: "Skills", url: "/#/skills" },
            { page: "Account", url: "/#/account" },
        ],
    };

    const appSections = {
        words: userRole ? (allAppSections[userRole] || []) : []
    };
    
    return (
        <section className="backgroundLightBlueColor theme-light section m-0 p-0" data-theme="light">
            <div className="fullHeight columns is-flex is-flex-direction-column is-justify-content-start is-align-items-center m-0 p-0">
                <NavMobile />
                <WordDisplay {...appSections} />
            </div>
        </section>
    )
}

export default Menu;