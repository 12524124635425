import React, { useState, useEffect, useRef } from 'react';
import TextareaFormat from './TextareaFormat';

const JobTransportation = ({
  publicTransportation,
  googleLink
  }) => {
    
  return (
    <>
    <div className="p-4">
        <p className='is-size-7 has-text-weight-bold colorLightGreen'>Transportation</p>
        <p className='is-size-6 colorDarkGrey py-2'>This location is accessible via public transportation. </p>
        {publicTransportation ? (
          <p className='is-size-6 colorDarkGrey has-text-weight-bold py-2'><TextareaFormat text={publicTransportation} /> </p>
        ) : null }
        <p className='is-size-6 colorDarkGrey py-2'>Please refer to <a href={googleLink} target="_blank">Google Maps</a> and transit services for the exact schedules.</p>
    </div>
    </>
  );
};

export default JobTransportation;