import React, { useState, useRef, useEffect } from 'react';

const FilterSortModals = ({ 
  filters, 
  initialSelectedFilters, 
  sortOptions, 
  initialSort,
  hideShow,
  onFilterChange,
  onSortChange,
  resetTrigger,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters);
  const [selectedSort, setSelectedSort] = useState(initialSort);
  const boxRef = useRef(null);
  const [localSelectedFilters, setLocalSelectedFilters] = useState(selectedFilters);
  const [localSort, setLocalSort] = useState(initialSort);

  useEffect(() => {
      setLocalSelectedFilters(selectedFilters);
      setLocalSort(initialSort);
  }, [resetTrigger]);

  const getShowHideFilters = () => {
    switch (hideShow) {
      case 'filters':
        return 'hideShow';
      default:
        return '';
    }
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
    setIsSortOpen(false);
    if (isFilterOpen) {
      onFilterChange(selectedFilters);
    }
  };

  const toggleSort = () => {
    setIsSortOpen(!isSortOpen);
    setIsFilterOpen(false);
  };

  const handleFilterChange = (category, option) => {
    const updatedFilters = {
        ...localSelectedFilters,
        [category]: {
            ...localSelectedFilters[category],
            [option]: !localSelectedFilters[category][option]
        }
    };
    setLocalSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
};

  const handleCheckboxChange = (category, value) => {
    setSelectedFilters(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [category]: {
          ...prevFilters[category],
          [value]: prevFilters[category]?.[value] === undefined ? false : !prevFilters[category]?.[value]
        }
      };
      // onFilterChange(updatedFilters);
      return updatedFilters;
    });
  };

  const handleSortChange = (value) => {
    setSelectedSort(value);
    onSortChange(value);
    setIsSortOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsFilterOpen(false);
        setIsSortOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (initialSelectedFilters && Object.keys(initialSelectedFilters).length > 0) {
      onFilterChange(initialSelectedFilters);
    }
    onSortChange(initialSort);
  }, []);

  return (
  <>
    <div className="dropdown is-active">
      <div className="dropdown-trigger">
        <a className="is-size-6 has-text-left pl-2" onClick={toggleSort}>
          <span>Sort: {selectedSort}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </a>
      </div>
      {isSortOpen && (
        <div className="dropdown-menu p-0 m-0" role="menu">
          <div className="dropdown-content p-0 m-0">
            <div ref={boxRef} className="box my-0 py-2" style={{minWidth: '200px'}}>
              {sortOptions.map((option) => (
                <div key={option} className="p-0 m-0 field is-size-6">
                  <label className="radio custom-radio p-0 m-0">
                    <input
                      type="radio"
                      className="mr-2 is-size-6 p-0 m-0"
                      checked={selectedSort === option}
                      onChange={() => handleSortChange(option)}
                    />
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
    <div className={`filter-component ${getShowHideFilters()}`}>
      <div className="dropdown is-active">
        <div className="dropdown-trigger">
          <a className="is-size-6 has-text-left pl-2" aria-haspopup="true" aria-controls="dropdown-menu" onClick={toggleFilters}>
            <span>Filters</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </a>
        </div>
        {isFilterOpen && (
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              <div ref={boxRef} className="box" style={{minWidth: '200px'}}>
                {Object.entries(filters).map(([category, values]) => (
                  <div key={category} className="mb-5">
                    <h4 className="title is-size-6 p-0 m-0 mb-2">{category}</h4>
                    {values.map(value => (
                      <div key={value} className="field is-size-6">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="mr-2 is-size-6"
                            checked={selectedFilters[category]?.[value] === false ? false : true}
                            onChange={() => handleCheckboxChange(category, value)}
                          />
                          {value}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
                <div className="buttons is-right mt-4">
                  <button className="button is-size-6 buttonLight" onClick={toggleFilters}>Apply</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </>
  );
};

export default FilterSortModals;