import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import ContactHelpLink from './ContactHelpLink';
import Avatar from './Avatar';
import ProfileTabs from './ProfileTabs';
import ProfileLink from './ProfileLink';
import ProfileSocialLinks from './ProfileSocialLinks';
import ProfileContactInfo from './ProfileContactInfo';
import ProfileInfo from './ProfileInfo';
import LogoutTextLink from './LogoutTextLink';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CheckSession } from './Auth-Api';

const MyAccount = () => {
    const navigate = useNavigate();
    
    const [user, setUser] = useState({});

    CheckSession();

    const userProfile = {
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae purus dui. Suspendisse a facilisis est. Fusce pharetra, magna in tempor mattis, dolor ante maximus massa, non placerat nunc tortor porttitor odio. Quisque at tempus nulla, accumsan imperdiet nulla.",
        employer: "Clausen House",
        title: "Director of Product Development",
        education: [ 
            { school: "Saint Mary's College of California", degree: "Graduate Business School, Executive MBA", years: "2023-2025" },
        ],
        languages: ["English", "Spanish", "JavaScript"],
        birthday: "12/28/1972",
        username: "anthony@42inc.com",
        password: "securepassword123"
    };

    const userBio = {
        role: "Admin",
        firstName: "Tony",
        lastName: "Hall",
        bio: "Passionate about helping people and building an equitable future for everyone.",
        employer: "Works at Clausen House",
    };

    const avatarProps = {
        imageUrl: 'https://media.licdn.com/dms/image/v2/D5603AQF80-4C5GAPmw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1670941407777?e=1729728000&v=beta&t=TcDHqgQfpk8hMQTnnlYoBT47qKK3XqoWXoXQO_3_vZo',
        initials: 'AH',
        size: '224',
    };

    const socialMediaInfo = {
        socialLinks: [
            { service: "LinkedIn", link: "https://www.linkedin.com/in/anthonyhall/" },
            { service: "Facebook", link: "https://linkedin.com/in/reactdev123" },
            { service: "X", link: "https://linkedin.com/in/reactdev123" },
            { service: "Instagram", link: "https://linkedin.com/in/reactdev123" },
        ]
    };

    const contactInfo = {
        emailAddresses: [
            "anthony@42inc.com", 
            "anthony.w.hall@gmail.com"
        ],
        phoneNumbers: [
          { type: "Mobile", number: "(123) 456-7890" },
          { type: "Home", number: "(098) 765-4321" },
          { type: "Work", number: "(555) 555-5555" }
        ],
    };

    useEffect(() => {
        axios.get('/api/user/myaccount')
        .then(res => {
            if (res) {
                const u = res?.data?.data[0];
                if (u.avatar) {
                    axios.get(`/api/user/avatar/${u.id}?key=${u.avatar}`)
                    .then(res => {
                        u.avatar = res.data?.url;
                        setUser(u);
                    })
                    .catch(err => {
                        console.log('error avatar', err)
                        setUser(u);
                    });
                } else {
                    setUser(u);

                }
            } else {
                navigate('/contacts');
            }
        })
        .catch(err => {
            console.log('err', err);
        });
    }, []);

    return (
        <>
        <section className='theme-light fullHeight is-flex is-flex-direction-column is-justify-content-space-evenly' data-theme="light">
            <div className="backgroundLightGreyColor topShield section m-0 p-0">
                <div className="container">
                    <div className="is-flex is-flex-direction-column">
                        <NavPrivate />
                        <div className="is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 p-6">
                            <div className="columns m-0 px-6">
                                <div className='m-0 p-2 column is-three-quarters descriptionContent is-flex is-flex-direction-rows is-align-items-center'>
                                    <div className='avatar avatarWidth'>
                                        <Avatar imageUrl={user.avatar} initials={user?.firstName ? `${user.firstName[0]} ${user.lastName[0]}` : ''} size={user?.size || 244}  />
                                    </div>
                                    <div className='p-5 is-flex is-flex-wrap-wrap is-flex-direction-column is-align-content-center is-justify-content-center'>
                                       <ProfileInfo {...userBio} />
                                    </div>
                                </div>
                                <div className='p-0 m-0 column contactLink is-flex is-flex-direction-column is-justify-content-end is-align-items-center'>
                                    <div className='is-flex is-flex-direction-rows is-justify-content-center is-align-items-end'>
                                        <LogoutTextLink />
                                        <ContactHelpLink />
                                    </div>
                                    <p><a href={`#/create-contact?id=${user.id}`} role="button" className="noUnderline button buttonLight buttonMedium">Edit contact</a></p>
                                </div>
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="is-full is-flex is-flex-direction-column columns m-0 p-6 ">
                    {/* This is where I adjusted the padding x */}
                    <div className="columns m-0 p-0">
                        <div className='m-0 px-4 column is-two-thirds is-flex is-flex-direction-rows'>
                            <ProfileTabs {...user} /> 
                        </div>
                        <div className='column px-4 m-0'>
                            <div className='is-flex is-flex-direction-column'>
                                <ProfileLink url="829dsns9ns4" />
                                <ProfileContactInfo {...contactInfo} />
                                <ProfileSocialLinks {...socialMediaInfo} />
                            </div>
                        </div>
                    </div>                                    
                </div> 
            </div>
            <div className="section is-flex is-justify-content-center m-0 p-0">
                <div className='container p-0 m-0'>
                    <Footer /> 
                </div>
            </div>
        </section>
        </>
    );
};

export default MyAccount;