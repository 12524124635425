import React, { useState } from 'react';
import { dateHelper, timeHelper } from '../utils.js/utils';
import LimitText from './LimitText';

const SkillsCard = ({ 
    skillTitle, 
    selectSchool, 
    id, 
    skillDescription,
    skillNote, 
    skillCategory, 
    skillLocation,
    createdOn,
    providerDetails,
}) => {
  
    const skillDomain = "/#/course?id=";
    const skillUrl = `${skillDomain}${id}`;

  return (

    <div className="column cardDefault contentBox px-4 m-2 is-flex is-flex-direction-column is-justify-content-space-between">
        <div className='m-4 p-0 is-flex is-flex-direction-column'>
            <div className='m-0 p-0 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                <p className='is-size-7 colorLightGreen has-text-weight-bold'>{skillCategory}</p>
                <p className='is-size-7 colorDarkGrey has-text-weight-normal'>{dateHelper(createdOn)}</p>
            </div>
            <h2 className='mt-4 is-size-4 has-text-bold lh-1'><a href={skillUrl} className='noUnderline'>{skillTitle}</a></h2>
            <p className='is-size-6 my-2'><LimitText text={skillDescription} limit="184" /></p>
            <p className='is-size-7 mt-2 has-text-weight-bold'>Provided by {providerDetails.nameProviderLong} {skillLocation ? `(${skillLocation})` : ''}</p>
        </div>
        <div className='mx-4 mt-1 p-0 is-flex is-flex-direction-column'>
            <div className='cardDivider p-0 m-0'></div>
            <div className='mt-3 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                <p className='is-size-6 colorLightGreen has-text-weight-bold'>{skillNote}</p>
                <p><a href={skillUrl} className='button buttonLight buttonSmall'>Details</a></p>
            </div>
        </div>
    </div>
  );
};

export default SkillsCard;