import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const ChartComponent = ({ value }) => {
    // Data for the pie chart
    const pieData = {
        labels: ['Hired', 'Open'],
        datasets: [
        {
            data: [value, 100-value], // Replace with your actual percentages
            backgroundColor: ['#0C8048', '#931E23'],
        },
        ],
    };

    return (
        <div className="px-0 py-2 m-0" style={{ width: 'auto', height: '360px' }}>
          <Pie
              data={pieData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
        </div>
      );
    };
    
export default ChartComponent;