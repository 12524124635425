import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobPostingModal from './ConfirmationModal';
import { CheckSession } from './Auth-Api';

const CreateJobForm = () => {
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [job, setJob] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [selectedOption, setSelectedOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [employers, setEmployers] = useState([]);
    const [employerLocations, setEmployerLocations] = useState([]);

    const [formData, setFormData] = useState({
        jobLocation: '',
        jobTitle: '',
        jobDescription: '',
        jobTags: '',
        jobResponsibilities: '',
        jobType: '',
        jobWorkFrom: '',
        jobBenefits: '',
        jobDetails: '',
        jobHours: '',
        jobPay: '',
        jobDisclaimer: '',
        jobNote: '',
    });
    
    const requiredFields = ['jobLocation', 'jobTitle', 'jobDescription', 'jobType', 'jobWorkFrom'];

    const fieldLabels = {
        jobLocation: 'Job location *',
        jobTitle: 'Title  *',
        jobDescription: 'Description *',
        jobType: 'Type *',
        jobWorkFrom: 'Work From *',
        jobTags: 'Tags',
        jobResponsibilities: 'Responsibilities',
        jobBenefits: 'Benefits',
        jobDetails: 'Transportation',
        jobHours: 'Hours',
        jobPay: 'Pay',
        jobDisclaimer: 'Disclaimer',
        jobNote: 'Very short note',
    };

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }
    }, []);
    
    CheckSession();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`/api/employers?page=1&limit=1000&sort=nameEmployer&active=true`)
        .then(res => {
            setEmployers(res.data.data)
        })
        .catch(err => {
            
        });
    }, [])

    useEffect(() => {
        if (!selectedOption) return;
        axios.get(`/api/employers/locations?page=1&limit=1000&sort=addressPrimaryStreet&employerId=${selectedOption}`)
        .then(res => {
            setEmployerLocations(res.data.data);
        })
        .catch(err => {
            
        });
    }, [selectedOption]);

    useEffect(() => {
        const id = searchParams.get('id');
        if (id) {
            axios.get(`/api/jobs/${id}`)
            .then(res => {
                
                console.log('job res', res.data);
                if (res.data.data[0]) {
                    const j = res.data.data[0];
                    setIsEdit(true);
                    setJob(j);
                    setSelectedOption(j.employerName);
                    setFormData({
                        jobLocation: j.jobLocation || '',
                        jobTitle: j.jobTitle || '',
                        jobDescription: j.jobDescription || '',
                        jobTags: j.jobTags || '',
                        jobResponsibilities: j.jobResponsibilities || '',
                        jobType: j.jobType || '',
                        jobWorkFrom: j.jobWorkFrom || '',
                        jobBenefits: j.jobBenefits || '',
                        jobDetails: j.jobDetails || '',
                        jobHours: j.jobHours || '',
                        jobPay: j.jobPay || '',
                        jobDisclaimer: j.jobDisclaimer || '',
                        jobNote: j.jobNote || '',
                    })
                }
            })
            .catch(err => {
                
            });
        }
    }, [])

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));
    };
    
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isEdit) {
            setShowModal(true);
            setModalAction(() => submit);
            setAlertHeading('Confirm');
            setAlertAction('Edit Job');
            setAlertMessage('Are you sure you want to edit this job?');
        } else {
            submit();
        }
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        const payload = {
            jobObj: formData,
            company: selectedOption,
        };
        
        const empObj = employers.find(e => e.id === selectedOption)
        if (empObj?.nameEmployer) payload.nameEmployer = empObj?.nameEmployer;
        
        const locObj = employerLocations.find(l => l.id === formData.jobLocation);
        if (locObj) {
            let locSearch = '';
            locSearch += locObj.addressPrimaryStreet ? locObj.addressPrimaryStreet + ' ' : '';
            locSearch += locObj.addressPrimarySuite ? locObj.addressPrimarySuite + ' ' : '';
            locSearch += locObj.addressPrimaryCity ? locObj.addressPrimaryCity + ' ' : '';
            locSearch += locObj.addressPrimaryState ? locObj.addressPrimaryState + ' ' : '';
            locSearch += locObj.addressPrimaryZip ? locObj.addressPrimaryZip + ' ' : '';
            
            payload.locSearch = locSearch.trim();
        }
        
        setIsLoading(true);
        if (isEdit) {
            payload.jobObj.id = job.id;
            axios.put(`/api/jobs/${job.id}`, payload)
            .then(res => {
                setIsLoading(false);
                navigate(`/job?id=${job.id}&showToast=Job updated`);
            })
            .catch(err => {
                setIsLoading(false);
                handleSubmissionError(err);
                
            });
        } else {
            axios.post('/api/jobs', payload)
            .then(res => {
                setIsLoading(false);
                navigate('/jobs?showToast=Job created');
            })
            .catch(err => {
                setIsLoading(false);
                handleSubmissionError(err);
                
            });
        }
    };

    const handleSubmissionError = (err) => {
        if (err.response && err.response.data && err.response.data.error) {
            if (err.response.data.error.includes('validation failed')) {
                setError('Validation failed. Please check all required fields and try again.');
            } else {
                setError(`Error: ${err.response.data.error}`);
            }
        } else {
            setError('An unexpected error occurred. Please try again.');
        }
    };

    const validateForm = () => {
        let isValid = true;
        let errorMessages = [];
    
        requiredFields.forEach(field => {
            let fieldValue;
            if (field === 'jobLocation') {
                fieldValue = selectedOption && selectedOption.value ? selectedOption.value : null;
            } else {
                fieldValue = formData[field];
            }
            
            if (fieldValue === null || fieldValue === undefined || 
                (typeof fieldValue === 'string' && !fieldValue.trim()) ||
                (Array.isArray(fieldValue) && fieldValue.length === 0)) {
                isValid = false;
                let fieldLabel = fieldLabels[field] ? fieldLabels[field].replace(' *', '') : field;
                errorMessages.push(`${fieldLabel} is required.`);
            }
        });
    
        setError(errorMessages.join('<br />'));
        return isValid;
    };

    const getFieldOrder = () => {
        if (selectedOption) {
            return  [
                    { type: 'divider', text: 'Required' },
                    'jobLocation', 'jobTitle', 'jobDescription', 'jobType', 'jobWorkFrom',
                    { type: 'divider', text: 'Additional details' },
                    'jobNote', 'jobTags', 'jobResponsibilities', 'jobBenefits', 'jobDetails', 'jobHours', 'jobPay', 'jobNote', 'jobDisclaimer',
                    ];

        } else {
            return [];
        }
    };

    const renderField = (field) => {
        if (typeof field === 'object' && field.type === 'divider') {
            return (
            <div className='borderLine' key={field.text}>
                <h2 className="is-size-5 has-text-weight-bold has-text-black">{field.text}</h2>
            </div>
            );
        }
        
    const fieldName = field;

    const fieldPlaceholder = {
        jobLocation: 'What is the address?',
        jobTitle: 'What is the job title?',
        jobDescription: 'Provide a brief overview of the position.',
        jobType: 'Sample type: part-time, full-time, on-call.',
        jobWorkFrom: 'Sample work from: In person, Online, Hybrid, Flexible.',
        jobTags: 'Separate features by commas (e.g., weekends needed, ...).',
        jobResponsibilities: 'List essential job responsibilities. \nUse line breaks for bullets.',
        jobBenefits: 'Outline the job benefits. Any perks? \nUse line breaks for bullets.',
        jobDetails: 'Any transportation details to include? \nUse line breaks for bullets.',
        jobHours: 'What are the job hours? \nUse line breaks for bullets.',
        jobPay: 'Provide a pay range (e.g., $18.50 - $22.50 per hour).',
        jobDisclaimer: 'Sample disclaimer: We are an equal-opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees. All qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, age, or protected veteran status. We encourage individuals from all backgrounds to apply.',
        jobNote: 'Text used on job card...',
    };

    const commonProps = {
        id: fieldName,
        name: fieldName,
        value: formData[fieldName],
    };

    if (fieldName === 'jobLocation') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`} htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className={`formInput inputLight is-size-6 ${error ? 'errorInputLight' : ''}`}
                    {...commonProps}
                    onChange={handleInputChange}
                >
                    <option value="">Select...</option>
                    {employerLocations.map((loc, i) => {
                        let address = `${loc.addressPrimaryStreet}${loc.addressPrimarySuite ? ` ${loc.addressPrimarySuite} ` : ''}, ${loc.addressPrimaryCity} ${loc.addressPrimaryState} ${loc.addressPrimaryZip}`
                        return <option key={i} value={loc.id}>{address}</option>
                    })}
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'jobWorkFrom') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`} htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className={`formInput inputLight is-size-6 ${error ? 'errorInputLight' : ''}`}
                    {...commonProps}
                    onChange={handleInputChange}
                >
                    <option value="">Select job location...</option>
                    <option value="in-person">In-person</option>
                    <option value="online">Online</option>
                    <option value="hybrid">Hybrid</option>
                    <option value="hybrid">Flexible</option>
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'jobType') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`} htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className={`formInput inputLight is-size-6 ${error ? 'errorInputLight' : ''}`}
                    {...commonProps}
                    onChange={handleInputChange}
                >
                    <option value="">Select...</option>
                    <option value="full-time">Full-time</option>
                    <option value="part-time">Part-time</option>
                    <option value="on-call">On-call</option>
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'jobDescription') {
        return (
          <div className="pt-1" key={fieldName}>
            <label className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`} htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <textarea
                className={`formInput textarea-fixed-height colorLightBlue inputLight is-size-6 ${error ? 'errorInputLight' : ''}`}
                {...commonProps}
                onChange={handleInputChange}
                placeholder= {fieldPlaceholder[fieldName]}
                >
            </textarea>
          </div>
        );
    };

    if (fieldName === 'jobResponsibilities' || fieldName === 'jobBenefits' || fieldName === 'jobDetails' || fieldName === 'jobHours') {
        return (
          <div className="pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <textarea
                className='is-size-6 textarea-fixed-height colorLightBlue'
                {...commonProps}
                onChange={handleInputChange}
                placeholder= {fieldPlaceholder[fieldName]}
                >
            </textarea>
          </div>
        );
    };

    if (fieldName === 'jobDisclaimer') {
        return (
        <div className="pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <textarea
                className='is-size-6 textarea-fixed-height colorLightBlue'
                {...commonProps}
                onChange={handleInputChange}
                placeholder= {fieldPlaceholder[fieldName]}
            >
            </textarea>
        </div>
        );
    };

    if (fieldName === 'jobTitle') {
        return (
            <div className="field pt-1" key={fieldName}>
            <label className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`} htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="control">
                    <input
                        className={`input formInput inputLight is-size-6 ${error ? 'errorInputLight' : ''}`}
                        type="text"
                        {...commonProps}
                        placeholder= {fieldPlaceholder[fieldName]}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    />
                </div>
        </div>
        );
    };
    
    return (
        <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="control">
                    <input
                        className="input is-size-6 formInput inputLight"
                        type="text"
                        {...commonProps}
                        placeholder= {fieldPlaceholder[fieldName]}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    />
                </div>
        </div>
        );
    };

    return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-12-mobile is-10-tablet is-8-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="is-size-4 my-4 has-text-black is-flex is-flex-direction-column is-justify-content-start is-align-items-center">
                                            <h1 className="has-text-weight-bold">{isEdit ? 'Edit' : 'Create'} <span className='has-text-weight-normal'>Job Posting</span></h1>
                                            {error && <div className="is-size-6 has-text-weight-bold colorLightRed pt-4" dangerouslySetInnerHTML={{__html: error}}></div>}
                                        </div>
                                        <div className="field">
                                            <label htmlFor="options" className="label m-1 has-text-black">Employer name *</label>
                                            <div className="select">
                                                <select id="options" 
                                                    className="formInput inputLight is-size-6"
                                                    value={selectedOption}
                                                    onChange={handleSelectChange}
                                                    disabled={isEdit}
                                                >
                                                    <option value="">Select...</option>
                                                    {employers.map((e, i) => {
                                                        return <option key={i} value={e.id}>{e.nameEmployer}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        
                                        {getFieldOrder().map((field, index) => renderField(field))}

                                        {selectedOption && (
                                        <>
                                        <div className="field pt-4 mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                            <div>
                                                <button 
                                                    type="submit"
                                                    id="submit" 
                                                    className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                    </form>
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default CreateJobForm;
