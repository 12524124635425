import React from 'react';

const TagDisplay = ({ TagsContent }) => {
  // Split the input string into an array of tags
  let tags
  if (TagsContent) {
    tags = TagsContent?.split(',').map(tag => tag.trim());
  }

  return (
    <div className="is-flex is-flex-wrap-wrap p-0">
      {tags && tags.map((tag, index) => (
        <span 
          key={index} 
          className="tag is-size-7 mr-1 my-1"
        >
          {tag}
        </span>
      ))}
    </div>
  );
};

export default TagDisplay;