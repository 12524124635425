import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import NavPublic from './NavPublic';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import JobPostingToast from './ConfirmationToast';

const SendMessageForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const requiredFields = ['firstName', 'lastName', 'username', 'contactMessage'];

    const navigate = useNavigate();

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
            // Clear the toast message after 5 seconds
            setTimeout(() => {
                setToastMessage('');
            }, 5000);
        }
    }, [searchParams]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'contactMessage':
                setContactMessage(value);
                break;
            default:
                break;
        }
    };

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const validateForm = () => {
        let isValid = true;
        let errorMessage = '';

        requiredFields.forEach(field => {
            if (!eval(field)) {
                isValid = false;
                errorMessage += `${field.charAt(0).toUpperCase() + field.slice(1)} is required.<br />`;
            }
        });

        if (username && !/\S+@\S+\.\S+/.test(username)) {
            isValid = false;
            errorMessage += 'Invalid email format.<br />';
        }

        setError(errorMessage.trim());
        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        const payload = {
            firstName,
            lastName,
            username,
            message: contactMessage,
        };

        setIsLoading(true);

        axios.post('/api/publicMessage', payload)
            .then(res => {
                setIsLoading(false);
                setFirstName('');
                setLastName('');
                setUsername('');
                setContactMessage('');
                navigate('/message?showToast=Message has been sent.');
            })
            .catch(err => {
                setIsLoading(false);
                setMessage('Error sending message');
            });
    };

    return (
        <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPublic />
                        <div className="theme-light is-flex is-flex-direction-column is-justify-content-start columns is-vcentered m-0 p-0">
                            <div className="column is-6 m-0 p-0">
                                <div>
                                    <div className="m-4 p-4">
                                        <form onSubmit={handleSubmit}>
                                            <div className="is-size-4 my-4 has-text-black">
                                                <h1 className="has-text-weight-bold">We would love to hear from you.</h1>
                                                <p className='is-size-6 my-3 pb-2'>Whether you are looking for a login, have a support issue, or are a member of the press, we're ready to answer any and all questions.</p>
                                                {error && <div className="is-size-6 has-text-weight-bold colorLightRed pt-4" dangerouslySetInnerHTML={{__html: error}}></div>}
                                            </div>
                                            <div className="field" key="firstName">
                                                <label htmlFor="firstName" className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`}>First name</label>
                                                <div className="control has-icons-right">
                                                    <input 
                                                        id="firstName" 
                                                        name="firstName"
                                                        className={`input inputLight ${error ? 'errorInputLight' : ''}`}
                                                        autoComplete="given-name" 
                                                        type="text" 
                                                        placeholder="Enter first name" 
                                                        value={firstName} 
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="field pt-1" key="lastName">
                                                <label htmlFor="lastName" className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`}>Last name</label>
                                                <div className="control has-icons-right">
                                                    <input 
                                                        id="lastName" 
                                                        name="lastName"
                                                        className={`input inputLight ${error ? 'errorInputLight' : ''}`}
                                                        autoComplete="family-name" 
                                                        type="text" 
                                                        placeholder="Enter last name"
                                                        value={lastName} 
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="field pt-1" key="username">
                                                <label htmlFor="username" className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`}>Email address</label>
                                                <div className="control has-icons-right">
                                                    <input 
                                                        id="username" 
                                                        name="username"
                                                        className={`input inputLight ${error ? 'errorInputLight' : ''}`}
                                                        autoComplete="email" 
                                                        type="email" 
                                                        placeholder="Enter email address"
                                                        value={username} 
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="field pt-1">
                                                <label htmlFor="contactMessage" className={`label m-1 has-text-black ${error ? 'errorTextLight' : ''}`}>Message</label>
                                                <div className="control has-icons-right">
                                                    <textarea
                                                        id="contactMessage" 
                                                        name="contactMessage"
                                                        className={`textarea inputLight ${error ? 'errorInputLight' : ''}`} 
                                                        autoComplete="on" 
                                                        placeholder="Type your message..."
                                                        value={contactMessage} 
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="field mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                                <div>
                                                    <button 
                                                        type="submit"
                                                        id="submit" 
                                                        className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? 'Loading...' : 'Submit'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
                    
                    <Footer /> 
                </div>
            </div>
        </section>
    );    
}

export default SendMessageForm;