import React, { useState, useContext, useEffect } from 'react';
import Auth from '../utils.js/Auth';
import { signin } from './Auth-Api';
import Footer from './Footer';
import NavPublic from './NavPublic';
import JobPostingToast from './ConfirmationToast';
import { useSearchParams } from 'react-router-dom';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }
    }, []);

    const auth = useContext(Auth);

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
        
    //     try {
    //         const result = await signin({username, password})

    //         if (result.data.auth) {
    //         auth.setAuth(true);
    //         } else {
    //             setMessage(result.data.response);
    //         }
    //     } catch (err) {
    //         setMessage(err.response.data.error || 'Error');
    //     }
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!username || !password) {
          setError('All fields are required.');
          return;
        }
    
        setIsLoading(true);
        setError('');
    
        try {
          const result = await signin({username, password});
    
          if (result.data.auth) {
            auth.setAuth(true);
          } else {
            setError(result.data.response || 'Login was incorrect. Please try again.');
          }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }

  return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
            <NavPublic />
            <div className="theme-dark is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 p-0">
                <div className="column is-6 m-0 p-0">
                    <div className="box ADA-dark">
                        <div className="m-4 p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-2 my-4">
                                    <h1 className={`my-1 ml-1 mb-2 is-size-3 has-text-white has-text-weight-bold ${error ? 'errorTextDark' : ''}`}>{error ? 'Error' : 'Please log in'}</h1>
                                    
                                    {error && <p className="m-1 has-text-weight-bold colorDarkRed">{error}</p>}
                                </div>
                                <div className="field">
                                    <label htmlFor="name" className={`is-size-6 label m-1 has-text-white ${error ? 'errorTextDark' : ''}`}>Username</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="name" 
                                            className={`input inputDark is-lowercase ${error ? 'errorInputDark' : ''}`}
                                            autoComplete="email" 
                                            type="email" 
                                            placeholder="Enter email" 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="field py-1">
                                    <label htmlFor="pass" className={`is-size-6 label m-1 has-text-white ${error ? 'errorTextDark' : ''}`}>Password</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="pass" 
                                            className={`input inputDark ${error ? 'errorInputDark' : ''}`}
                                            autoComplete="current-password" 
                                            type="password" 
                                            placeholder="Enter password"
                                            value={password} 
                                            onChange={(e) => setPassword(e.target.value)}
                                            // onChange={handlePasswordChange}  
                                        />
                                    </div>
                                </div>
                                <div className="my-4 field is-grouped is-flex is-justify-content-flex-end is-full">
                                    <div>
                                        <button 
                                            id="submit" 
                                            className={`my-4 button is-size-6 buttonDark ${isLoading ? 'is-loading' : ''}`}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Loading...' : 'Log in'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p className="is-size-6 is-size-7-tablet is-size-7-mobile has-text-black has-text-centered mb-6 p-0"><a href="/#/forget-password" className="mx-2">Forgot your password?</a> <a href="/#/message" className="mx-2">Have a question?</a></p>
                </div>    
            </div>
            {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}      
            <Footer /> 
        </div>
        
    </section>
  );    
}

export default LoginForm;
