exports.dateHelper = (date) => {
    let dateObj;
    if (date) {
        dateObj = new Date(date);
        let res = `${dateObj.toLocaleString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })}`;
        return res;
    } else {
        return '';
    }
}

exports.timeHelper = (date) => {
    let dateObj;
    if (date) {
        dateObj = new Date(date);
        let res = `${dateObj.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        })}`;
        return res;
    } else {
        return '';
    }

}