import React from 'react';

const TagRole = ({ contactRole }) => {
  // Split the input string into an array of tags
  const tags = contactRole.split(',').map(tag => tag.trim());

  const getTagRoleColor = () => {
    switch (contactRole) {
    case 'coach':
        return 'backgroundLightOutsideGreenColor';
      case 'provider':
        return 'backgroundLightBlueColor';
    case 'employer':
        return 'backgroundLightGreenColor'
    case 'manager':
        return 'backgroundLightOutsidePurpleColor'
    case 'client':
      return 'backgroundLightRedColor';
    case 'caregiver':
        return 'backgroundLightOutsideBrownColor';
    default:
        return 'backgroundDarkGreyColor';
    }
  };

  return (
    <div className="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center m-0 p-0">
      {tags.map((tag, index) => (
        <span 
          key={index} 
          className={`is-capitalized tagRole has-text-weight-bold is-size-7 mr-1 my-1 ${getTagRoleColor()}`}
        >
          {tag}
        </span>
      ))}
    </div>
  );
};

export default TagRole;