import React, { useContext } from 'react';
import Auth from '../utils.js/Auth';
import { signout } from './Auth-Api';

const LogoutTextLink = () => {
    const auth = useContext(Auth);

    const handleLogout = async () => {
        const result = await signout();
        auth.setAuth(result.data.auth);
    }

    return (
        <p className='p-2 m-0'><a onClick={handleLogout} role="button" className="noUnderline button buttonLight buttonMedium">Log out</a></p>
    );
};

export default LogoutTextLink;



