import React, { useEffect, useState } from 'react';
import NavPrivate from './NavPrivate';
import Footer  from './Footer';
import ContactHelpLink from './ContactHelpLink';
import FilterSortModals from  './FilterSortModals';
import Pagination from './Pagination';
import SearchResultsRow from './SearchResultsRow';
import SearchResultsInfo from './SearchResultsInfo';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { CheckSession } from './Auth-Api';

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchResults, setSearchResults] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [sort, setSort] = useState('lastUpdatedOn');
    const [sortOrder, setSortOrder] = useState(-1);
    const [active, setActive] = useState(true);
    const [filterQry, setFilterQry] = useState('');

    const [clientOnly, setClientOnly] = useState(false);

    const [search, setSearch] = useState('');

    const [selectedFilters, setSelectedFilters] = useState();

    const [filters, setFilters] = useState();

    CheckSession();

    const sortOptions = ['Most recent', 'Alpha A-Z', 'Alpha Z-A'];
    
    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const handleFilterChange = (updatedFilters) => {

        if ((updatedFilters.Status.Active && updatedFilters.Status.Archived) || (!updatedFilters.Status.Active && !updatedFilters.Status.Archived)) {
            setActive(undefined);
        } else if (updatedFilters.Status.Active) {
            setActive(true);
        } else if (updatedFilters.Status.Archived) {
            setActive(false);
        }

        let filterStr = '';

        if (updatedFilters.Category.Contacts) {
            filterStr += `&category=contacts`;
        }
        if (updatedFilters.Category.Jobs) {
            filterStr += `&category=jobs`;
        }
        if (updatedFilters.Category && updatedFilters.Category['Skills training']) {
            filterStr += `&category=skills`;
        }

        if (clientOnly) {
            filterStr += '&clientOnly=true';
        }

        setFilterQry(filterStr);
        setSelectedFilters(updatedFilters);
    };

    const handleSortChange = (newSort) => {
        // Update your search results based on the new sort option
        switch(newSort) {
            case 'Most recent':
                setSort('lastUpdatedOn');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
            case 'Alpha A-Z':
                setSort('search');
                setSortOrder('1');
                setCurrentPage(1);
                break;
            case 'Alpha Z-A':
                setSort('search');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
        }
    };

    const handleSearchChange = (searchTerm) => {
        setSearch(searchTerm)
    }

    useEffect(() => {
        axios.get('/api/user/myaccount')
        .then(res => {
            if (res.data.data[0]) {
                const role = res.data.data[0].role;
                switch (role) {
                    case 'employer':
                        setClientOnly(true);
                    case 'admin':
                    case 'provider':
                    case 'coach':
                        setSelectedFilters({
                            Category: { 'Contacts': true, 'Jobs': true, 'Skills training': true },
                            Status: { 'Active': true, 'Archived': false },
                        });
                        setFilters({
                            Category: ['Contacts', 'Jobs', 'Skills training'],
                            Status: ['Active', 'Archived']
                        });
                        break;
                    case 'client':
                    case 'caregiver':
                        setSelectedFilters({
                            Category: { 'Jobs': true, 'Skills training': true },
                            Status: { 'Active': true, 'Archived': false },
                        });
                        setFilters({
                            Category: ['Jobs', 'Skills training'],
                            Status: ['Active', 'Archived']
                        });
                        break;
                    default:
                        break;
                }
            }
        })
        .catch(err => {
            console.log('err', err)
        });
    }, []);

    useEffect(() => {
        const searchStr = searchParams.get('search');

        if (search || searchStr) {
            setSearch(search || searchStr);
            axios.get(`/api/search?page=${currentPage}&limit=12&sort=${sort}&sortOrder=${sortOrder}&active=${active}${filterQry}&search=${search || searchStr}`)
            .then(res => {
                setPages(res.data.metaData.totalPages);
                setCurrentPage(parseInt(res.data.metaData.pageNumber || 1));
                setTotalResults(res.data.metaData.totalDocuments || 0);
                setSearchResults(res.data.data);
            })
            .catch(err => {
                console.log('err', err);
            });
        }
    }, [currentPage, sort, sortOrder, active, search, filterQry]);

    return (
        <section className="backgroundLightGreyColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate handleSearchChange={handleSearchChange} />
                        <div className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                            <div className="breadcrumbLeft" aria-label="breadcrumbs">
                                <p className='m-0 p-0 is-flex is-flex-direction-row is-justify-content-start is-align-items-center'>
                                    <span className='my-0 ml-0 mr-1 p-0 is-text-weight-normal is-size-7'><a className="m-0 p-0" href="#/">Home</a></span>
                                    <span className='my-0 mx-1 p-0 is-active is-text-weight-normal is-size-7'>/</span>
                                    <span className='my-0 mx-1  p-0 is-active is-text-weight-normal is-size-7'>Job Board</span>
                                </p>
                            </div>
                            <ContactHelpLink />
                        </div>
                        <div className="theme-light is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-12-mobile is-10-tablet is-10-desktop m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-align-content-flex-start my-4">
                                            <div className='is-flex is-flex-direction-row is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="pl-5 is-size-3 has-text-weight-bold has-text-black">Search results <span className="is-size-5 has-text-weight-normal"></span></h1>
                                                <div className='is-flex is-flex-direction-row is-align-items-center'>
                                                {selectedFilters && <FilterSortModals 
                                                    filters={filters} 
                                                    initialSelectedFilters={selectedFilters}
                                                    sortOptions={sortOptions}
                                                    initialSort="Most recent"
                                                    onFilterChange={handleFilterChange}
                                                    onSortChange={handleSortChange}
                                                />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='boxDivGrey boxDivMinHeight mb-6'>
                                            <div className='centerStuffJustWide m-0 p-0'>
                                                <div className='m-0 p-0'>
                                                    <SearchResultsInfo resultsTotal={totalResults} searchTerm={search}/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='centerStuffJustWide'>
                                                    <SearchResultsRow searchResultContent={searchResults} currentPage={currentPage}/>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            totalResults > 12 && (
                                                <Pagination pages={pages} currentPage={currentPage} updatePage={handlePageChange}/>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default Search;